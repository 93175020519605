/* Module: S */
.main .search-results--grid:last-of-type {
    border-bottom: pxToRem(1) solid $teal;

    &::after {
        content: none;
    }
}

.search-results--grid {
    overflow: hidden;
    padding: pxToRem(30) 0 pxToRem(30) 0;
    position: relative;
    width: 100%;
    z-index: 1;

    &::after {
        border-bottom: 2px solid $silver1;
        bottom: -1px;
        content: '';
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: calc(100% - 32px);
    }
}

.search-results--grid.searchable-grid-section--hide,
.searchable-grid__item.searchable-grid__item--hide,
.searchable-no-results.searchable-no-results--hide {
    @include hideContent;
}

.searchable-no-results {
    margin: pxToRem(25);
    text-align: center;
}

.searchable-grid {

    &__item {
        margin-bottom: pxToRem(8);
        overflow: hidden;
        width: 100%;

        &:last-of-type {
            // margin-bottom: 0;
        }

        .featured-image__content-item--large .featured-image__content-item {
            &--title {
                font-size: pxToRem(26);
                line-height: 1.38;
                font-weight: 500;
                max-height: pxToRem(112);
                overflow: hidden;
                width: 80%;
            }

            &--label {
                display: block;
                overflow: hidden;
                width: 80%;
                max-height: pxToRem(144);
                font-size: pxToRem(18);
                line-height: 1.56;
            }
        }

        .featured-image__content-item {
            &--large {
                background-image: none;
                height: pxToRem(350);
                margin-bottom: 0;
                max-height: pxToRem(350);

                .featured-image__content-item--link {
                    text-align: left;

                    &::before {
                        border-top: pxToRem(352) solid;
                        border-top-color: inherit;
                        border-right: pxToRem(80) solid transparent;
                        width: calc(100% - 30px);
                    }

                    &:after {
                        right: pxToRem(20);
                    }
                }
            }

            &--wrapper {
                max-height: pxToRem(300);
                display: block;
                overflow: hidden;
            }

        }

        // blue
        &:nth-child(3n+1) {
            .featured-image__content-item .featured-image__content-item--link {
                border-top-color: $corporate-services;

                &:hover,
                &:focus {
                    background-color: $haiti;
                    border-top-color: $haiti;
                    -webkit-transition: border-top-color 0.6s linear;
                    -moz-transition: border-top-color 0.6s linear;
                    transition: border-top-color 0.6s linear;
                    -webkit-transition: background-color 0.6s linear;
                    -moz-transition: background-color 0.6s linear;
                    transition: background-color 0.6s linear;
                }
            }

            .featured-image__content-item--teal {
                background: $corporate-services;
            }
        }

        // green
        &:nth-child(3n+2) {
            .featured-image__content-item .featured-image__content-item--link {

                &:hover,
                &:focus {
                    background-color: $teal-light;
                    border-top-color: $teal-light;
                    -webkit-transition: border-top-color 0.6s linear;
                    -moz-transition: border-top-color 0.6s linear;
                    transition: border-top-color 0.6s linear;
                    -webkit-transition: background-color 0.6s linear;
                    -moz-transition: background-color 0.6s linear;
                    transition: background-color 0.6s linear;
                }
            }

            .featured-image__content-item .featured-image__content-item--label {
                color: $corporate-services;
            }
        }

        // purple 
        &:nth-child(3n+3) {
            .featured-image__content-item .featured-image__content-item--link {
                border-top-color: $planning;

                &:hover,
                &:focus {
                    background-color: $planning-hover;
                    border-top-color: $planning-hover;
                    -webkit-transition: border-top-color 0.6s linear;
                    -moz-transition: border-top-color 0.6s linear;
                    transition: border-top-color 0.6s linear;
                    -webkit-transition: background-color 0.6s linear;
                    -moz-transition: background-color 0.6s linear;
                    transition: background-color 0.6s linear;
                }
            }

            .featured-image__content-item--teal {
                background: $planning;
            }
        }
    }

    .tags {
        display: none;
    }
}

.searchable-featured-grid.search__form-filbtn-wrap {
    padding: pxToRem(24) pxToRem(16) pxToRem(14) pxToRem(16);

    .search__form-wrap>form {
        margin-left: -.5rem;
    }

    .search__filter-btn-wrap {
        margin-right: -.5rem;
    }
}

.searchable-grid__section-title {
    font-size: pxToRem(24);
    font-weight: $font-weight-bold;
    margin: 0;
}

.searchable-grid__section-description {
    color: $grey;
    font-size: pxToRem(18);
    line-height: 28px;
    margin: pxToRem(5) 0 pxToRem(22) 0;
}

// New Grid Tiles 19.03.2019
.searchable__container-item {
    height: 100%;
}

.searchable__content-img {
    background-color: rgba($black, 0.75);
    max-height: pxToRem(165);
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: 60.71429%;
        width: 100%;
    }

    &::after {
        bottom: 0;
        content: '';
        height: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
    }
}

.searchable__img {
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}

.searchable__content-link {
    // @include sq-transition(background-color 0.35s linear);
    background-color: $wild-sand;
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-decoration: none;

    &::after {
        background-image: url('{{file_dest}}/arrow--right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 25px;
        content: '';
        height: pxToRem(18);
        position: absolute;
        right: 25px;
        width: pxToRem(28);
    }

    &:hover,
    &:focus,
    &:active {
        background-color: $corporate-services;

        &::after {
            background-image: url('{{file_dest}}/arrow--right-white.svg');
        }

        .searchable__content-title,
        .searchable__content-description {
            color: $white;
        }
    }
}

.searchable__content-item {
    padding: pxToRem(25) pxToRem(25) pxToRem(50) pxToRem(25);
}

.searchable__content-title {
    color: $planning;
    font-size: pxToRem(20);
    line-height: 28px;
    margin: 0;
    font-weight: $font-weight-bold;
}

.searchable__content-description {
    color: $searchable_des;
    font-size: pxToRem(15);
    line-height: 24px;
    margin-bottom: 0;
}

.searchable-feature-filters form .search-filters__filter-tab .search__fields-wrap {
    border-bottom: none;
}