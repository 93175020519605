/* Module: S */
// 1. QUICKLINKS
.quick-links {
    font-size: pxToRem(20);
    padding:   pxToRem(55) 0;

    .quick-links__link {
        padding: pxToRem(36) pxToRem(95) pxToRem(36) pxToRem(30);

        &::after {
            height: pxToRem(17);
            right:  pxToRem(30);
            width:  pxToRem(35);
        }
    }

    .main.col-2 & .quick-links__link {
        padding: pxToRem(20) pxToRem(95) pxToRem(20) pxToRem(30);
    }
}
