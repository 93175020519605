/* Module: S */
.search {
    // 0. SEARCH BANNER

    &__wrapper {
        .container-fluid {
            padding: 0;
        }
    }

    &__wrapper--news,
    &__wrapper--grants,
    &__wrapper--publications {

        .container-fluid {
            padding-left:  1rem;
            padding-right: 1rem;
        }

    }

    &__banner {
        padding-bottom: pxToRem(80);

    }

    &__form {
        width:   pxToRem(585);
        padding: pxToRem(78) 0 pxToRem(9);
    }

    &__input {
        font-size: pxToRem(40);
        width:     90%;
    }

    &__submit {
        top: pxToRem(22);
    }
    &__tabs {
        padding-top: pxToRem(45);

        &--link {
            font-size: pxToRem(16);
            padding:   0 pxToRem(18);

            &-active {
                padding-bottom: pxToRem(10);
            }

        }

    }
    // 1. SEARCH FILTER/SORT
    &__filter {
        border-bottom: 0 none;
        margin-left:   0;
        margin-right:  0;

        &--group {
            border-bottom:  1px solid rgba($navy, .3);
            padding-bottom: .75rem;
            padding-top:    .75rem;
        }

        &-label {
            border-bottom: 0 none;
            margin-left:   0;
            margin-right:  1rem;
            margin-top:    0;
            padding:       0;
        }

        &-container {
            display:         flex;
            justify-content: space-between;
        }

        &-group-start,
        &-group-end {
            display: flex;
        }

        &-group-start .search__filter-menu {
            min-width: 14rem;
        }

        &-link {
            font-size: pxToRem(14);
        }

        &-menu {
            min-width: 100%;
            width:     auto;

            li:last-child .search__filter-menu-link {
                padding-bottom: pxToRem(10);
            }

        }

        &-menu-link {
            font-size:      pxToRem(14);
            line-height:    1.5;
            padding-bottom: .25rem;
            padding-top:    .25rem;
        }

    }

    // 2. SEARCH RESULT LISTING
    &__result {
        &--number {
            font-size: pxToRem(14);
            padding:   pxToRem(30) 0 0;
        }

        &--item {
            padding: pxToRem(40) 0;
        }

        &--title a {
            font-size: pxToRem(16);
        }

        &--description,
        &--link {
            font-size: pxToRem(16);
            padding:   pxToRem(14) 0 pxToRem(6);
        }

        &--tiles {

            .container-fluid {
                padding-left:  0;
                padding-right: 0;
            }

        }

    }

    // 3. PAGINATION
    &__pagination {
        margin-top: pxToRem(29);
    }
}
