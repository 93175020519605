/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .media-tiles__element { ... }
// .media-tiles__element--modifier { ... }
// .media-tiles__sub-element { ... }
.media-tiles {
    // Your styles here
}

.media-tiles__item:nth-of-type(3),
.media-tiles__item:nth-of-type(4) {
    border-top: 1px solid $border-grey;
}

.media-tiles__item:not(:nth-of-type(1)) {
    border-left: none;
}

//Full size example
.col-sm-3.media-tiles__item:nth-of-type(3) {
    border-left: 1px solid $border-grey;
}

.col-md-2dot4 {
    flex-basis:20%;
    max-width:20%;
}

.media-tiles__item {
    min-height:122px;
    max-height:122px;
}

.media-link {
    padding: 18px;
}

.media-link__title {
    padding-top: 15px;
    line-height:18px;
}

.media-link__title_two {
    padding-top: 7px;
}

// .media-tiles__item {
//     border-top: 1px solid;
//     border-bottom: 1px solid;
//     border-right: 1px solid;
// }
// .media-tiles__item:first-of-type {
//     border-left: 1px solid;
// }