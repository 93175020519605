/* Module: S */
/*
 * Table of Contents
 *
 *  SEARCH TOOLS
 * 
 */



/*
--------------------
1. SEARCH TOOLS
--------------------
*/

.results--count {
    font-size: pxToRem(13);
    line-height: pxToRem(15);
    font-weight: 500;
}

.results--query {
    font-size: pxToRem(30);
    line-height: pxToRem(36);
    margin-bottom: pxToRem(10);
}

.results--types {
    margin-bottom: pxToRem(10);
}

#typesSelect {

    .button {
        display: inline-block!important;
    }
}

.types__select {
    position: relative;
    float: right;    
    width: pxToRem(115)!important;
    margin-bottom: 0;
}

.icon--tools {
    position: relative;
    margin-left: pxToRem(20);
    margin-top: pxToRem(10);

    .icon {

        &.selected {
            color: $dark-blue;
        }
    }
}

.filters__heading.first-child {
    margin-top: 20px;
}