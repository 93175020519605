// NOTE: Please try to change tint/opacity of these colours instead of creating new variables:
// Use darken/lighten scss functions instead, e.g. color: lighten($teal, 10%);

// Brandmark colour palette
$black: #000;
$dark-grey: #ebebeb;
$grey: #6f6f6f;
$light-grey: #b3b3b3;
$medium-grey: #f2f2f2;
$white: #fff;

$gov-blue: #004c97; // Pantone 2945
$gov-blue-1: #1b5faa; // Pantone 2935
$from-border-grey: #979797;

$grey-c: $from-border-grey;
$medium-blue: $gov-blue;

// ****************************************

// Ripple
$ripple-blue-dark: #023b89;
$ripple-blue-dawn: #0095ec;
$ripple-blue-light: #0052c2;
$ripple-blue-sky: #0c94ec;
$ripple-grey-light: #e8ebee;
$ripple-grey-lighter: #d7dbe0;
$ripple-grey-more-lighter: #f6f6f9;
$ripple-grey-concrete: #546a7c;
$ripple-grey-concrete-light: #cdd3d7;
$ripple-grey-concrete-dark: #53647a;
$ripple-grey-text: #011a3c;
$ripple-light-grey-text: #465870;
$ripple-red-blood: #af272f;
$ripple-red-error: #e35205;
$ripple-rainbow: $ripple-blue-light 0%, #825dff 12%, #ef4a81 23%, #e57200 37%,
  #ffc166 51%, #f9e062 81%;
$ripple-brand-rainbow: #011a3c 0%, #642667 49.88%, #009ca6 100%;
$ripple-brand-search-border: #009ca6 0%, #00656b 100%;

// Corporate brand colour palette
$dark-blue: #201647;
$dark-blue-green: #00573f;
$jungle-green: #007b4b;
$navy: $dark-blue; // Pantone 2765
$purple-light: #bcb8c7;
$purple: #797391;
$smoke-black: mix($black, $white, 86%);
$sun-yellow: #f8e81c;
$teal: #00b7bd; // Pantone 326
$teal-light: #48c8cc;

// Sub-brands colour palette
$water-and-catchments: #71c5e8; // Pantone 297
$climate-change: #fdda24; // Pantone 115
$corporate-services: #201547; // Pantone 2765
$corporate-services--hover: #615c7d;
$energy: #0072ce; // Pantone 285
$environment: #cedc00; // Pantone 381
$forest-fire: #e57200; // Pantone 152
$forest-fire-mgmt: $jungle-green;
$forest-fire-mgmt-dark: $dark-blue-green;
$forest-fire-mgmt-highlight: $sun-yellow;
$forest-fire-mgmt-neutral: $smoke-black;
$fishermans-bend: #47c5de;
$fishermans-bend-dark: #00838e;
$fishermans-bend-black: $smoke-black;
$fishermans-bend-neutral: #1f272a;
$local-infrastructure: #af272f; // Pantone 1805
$planning: #642667; // Pantone 260
$revitalising-central-geelong: #542581;
$revitalising-central-geelong-light: #782b91;
$revitalising-central-geelong-highlight: #d2d70b;
$revitalising-central-geelong-blue: #005da0;
$revitalising-central-geelong-blue-light: #00ade9;

// DELWP-2018 Colours
$alto: #e0e0e0;
$valhalla: #201547;
$wild-sand: #f6f6f6;
$wild-sand1: #f5f5f5;
$valhalla-dark: #221446;
$gray91: #919191;
$haiti: #150e2f;
$manatee: #908ba5;
$alto2: #dfdfdf;
$haiti2: #110a2a;
$silver1: #c8c8c8;
$cornflower-blue: #e5e5e5;
$gray92: #929292;
$mine-shaft: #323232;
$concrete: #f3f3f3;
$pink: #f8ecf8;
$searchable_des: #383838;
$planning-hover: #6c2470;
$carousel-dots: #bababa;

// Land Vic
$land-vic-aqua: #4eb7bd;
$land-vic-aqua-dark: #00656b;
$land-vic-aqua-gradient: 270deg, #009ca6 0%, #007179 100%;
$land-vic-aqua-light: #009ca6;
$land-vic-aqua-more-lighter: #02868f;
$land-vic-blue: $ripple-blue-dark;
$land-vic-blue-light: #1000d3;
$land-vic-grey-text: $ripple-grey-text;
$land-vic-purple: #201647;
$land-vic-purple-darker: #120b2c;
$land-vic-purple-light: #642667;
