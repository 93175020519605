/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .media-tiles__element { ... }
// .media-tiles__element--modifier { ... }
// .media-tiles__sub-element { ... }
.media {
    padding-bottom: 65px;
}

//Full size example
.col-sm-3.media-tiles__item:nth-of-type(3),
.col-sm-3.media-tiles__item:nth-of-type(4) {
    border-top: 1px solid $border-grey;
}

.col-sm-3.media-tiles__item:nth-of-type(2) {
    border-right: none;
}