/* Module: S */
.active-video-banner {
	min-height: 425px;
	&-inner {
        left:      50%;
        padding:   pxToRem(40);
        position:  absolute;
        top:       50%;
        transform: translate(-50%, -50%);
        width:     100%;
	}

	&__video-bg--fallback {
		display:    none;
		visibility: hidden;
	}
	&__video-bg--loop {
		display:    block;
		visibility: visible;
	}
     &__toggle-button {
        @include sq-transition(all .35s ease);
        background-color:    rgba($black,.33);
        background-image:    url('{{file_dest}}/play-button.svg');
        background-position: center center;
        background-repeat:   no-repeat;
        background-size:     40%;
        border:              none;
        border-radius:       100%;
        display:             block;
        height:              pxToRem(40);
        opacity:             .8;
        position:            absolute;
        right:               pxToRem(50);
        bottom:              pxToRem(25);
        width:               pxToRem(40);
        z-index:             1;

        &:focus,
        &:hover {
            background-color: $black;
            outline:          none;
            opacity:          1;
        }
    }
    .pause {
        background-image:    url('{{file_dest}}/pause-button.svg');
    }
	&__text-wrapper {
    	h1 {
    		font-size: pxToRem(42);
    	}
    	h2 {
			font-size: pxToRem(16);
    	}
    }
     &__button-wrapper {
     	.btn {
            &__small {
                width: auto;
            }
        }
        .btn:first-child {
            margin-right: pxToRem(20);
        }
    }
}