/* Module: S */
.block-quote {
    padding: pxToRem(78.5) 0 pxToRem(102);

    &.padding_top--half {
        padding-top: pxToRem(39);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(51);
    }
    .block-quote__image {
        margin-bottom: 0;
    }

    .block-quote__quote h5 {
        font-size: pxToRem(22);
    }
}
