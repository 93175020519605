/* Module: S */
.content-drawer {
    background-color: $white;
    height:           100%;
    position:         fixed;
    right:            pxToRem(-1024);
    top:              0;
    width:            100%;
    z-index:          100;

    .content-drawer__close-wrapper--scrolled {
        box-shadow: 0 pxToRem(6) pxToRem(4) 0 rgba(0, 0, 0, .1);
    }

    .content-drawer__close {
        color:           $corporate-services;
        display:         inline-block;
        font-size:       pxToRem(14);
        line-height:     pxToRem(50);
        padding:         0 pxToRem(16);
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &:focus {
            outline: 0;
        }

        &::before {
            background: url('{{file_dest}}/drawer-close.svg') center center no-repeat transparent;
            content:        '';
            display:        inline-block;
            height:         pxToRem(26);
            margin:         0 pxToRem(13) 0 0;
            vertical-align: middle;
            width:          pxToRem(26);
        }
    }

    .content-drawer__content-wrapper {
        height:     calc(100% - 50px);
        overflow-y: scroll;
    }

    .content-drawer__content {
        padding: pxToRem(16);
    }

    p,
    ul,
    ol {
        color:          $grey;
        font-size:      pxToRem(14);
        letter-spacing: pxToRem(-.3);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color:     $corporate-services;
        word-wrap: break-word;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.content-drawer__loading {
    @include sq-transform(translate(-50%, -50%));
    background-color: transparent;
    display:          none;
    height:           pxToRem(50);
    left:             50%;
    position:         fixed;
    top:              50%;
    width:            pxToRem(50);
    z-index:          200;

    div {
        -webkit-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
        animation:  square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite; 
        background: $white;
        border:     0;
        height:     pxToRem(50);
        width:      pxToRem(50);
    }
}

.content-drawer__link {
    position:     relative;

    &::after,
    &::before {
        @include sq-transition(opacity .25s ease);
        opacity:    0;
        visibility: hidden;
    }

    &::after {
        background:      url('{{file_dest}}/drawer_link_icon2.svg') center center no-repeat transparent;
        background-size: cover;
        content:         '';
        display:         inline-block;
        height:          18px;
        margin:          0 pxToRem(5);
        opacity:         1;
        position:        relative;
        visibility:      visible;
        top:             4px;
        width:           18px;

    }

    &::before {
        background-color: $corporate-services;
        bottom:           150%;
        color:            $white;
        content:          'Click once';
        font-size:        pxToRem(14);
        left:             50%;
        line-height:      1.2;
        margin-bottom:    pxToRem(5);
        margin-left:      -80px;
        padding:          pxToRem(6);
        position:         absolute;
        text-align:       center;
        width:            160px;
    }
}

.content-drawer__link--double-clicked {

    &::after,
    &::before {
        opacity:    1;
        visibility: visible;
    }
}

.external-link {
    hyphens:      auto;
    position:     relative;
    
     &::after {
        content:    '';
        display:    inline-block;
        height:     18px;
        margin:     0 0 0 pxToRem(5);
        opacity:    1;
        position:   relative;
        visibility: visible;
        top:        4px;
        width:      18px;

    }
}

.external-link {
     &::after {
        background:      url('{{file_dest}}/external-link.svg') center center no-repeat transparent;
    }
}
