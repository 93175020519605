/* Module: S */
// 1. HEADER
.header {
    padding: pxToRem(21) 0 pxToRem(22);

    .header__logo {
        height: pxToRem(57);
        width:  pxToRem(191);
    }

    .header__menu-text {
        display: inline-block;
    }

    .header__curves {

       &::before {
            right: pxToRem(-250);
            top:   pxToRem(-320);
        } 
    }
}

// 2. SEARCH
.header__search-mobile {
    top: pxToRem(100);
}

// 3. NAVIGATION LEFT
.navigation {
    padding-top: pxToRem(30);
    padding-bottom: pxToRem(30);

    &::before {
        display: none;
    }

    .navigation__link {
        padding: pxToRem(13) pxToRem(86) pxToRem(12) pxToRem(36);

        &::after {
            margin: 0 pxToRem(36);
            width:  calc(100% - 72px);
        }
    }

    .navigation__sub-l2-link {
        padding: pxToRem(13) pxToRem(86) pxToRem(13) pxToRem(36);
    }

    .navigation__sub-l3-link {
        padding: pxToRem(13) pxToRem(86) pxToRem(13) pxToRem(51);
    }

    .navigation__sub-l4-link {
        padding: pxToRem(13) pxToRem(30) pxToRem(13) pxToRem(66);
    }

    .navigation__cta {
        right: pxToRem(36);
    }
}

// 4. MEGAMENU
.megamenu {
    display: block;

    .megamenu__link {
        font-size: pxToRem(16);
        margin:    0 pxToRem(75) 0 0;
        padding:   pxToRem(16) 0;

        &::after {
            height: pxToRem(6);
            right:  pxToRem(-31);
            top:    pxToRem(25);
            width:  pxToRem(12);
        }

        &::before {
            @include sq-transform(translateY(pxToRem(28)));
        }

        &:hover,
        &:focus {

            &::before {
                @include sq-transform(translateY(pxToRem(-2)));
            }
        }
    }

    .megamenu__link--open {

        &::before {
            @include sq-transform(translateY(pxToRem(-2)));
        }
    }

    .megamenu__sub-nav {
        top: pxToRem(100);
    }
}

.megamenu__overlay {
    top:        pxToRem(100);
}
