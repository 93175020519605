/* Module: S */
// 1. FAQ
.faq__header {
    font-size: pxToRem(24);
}

.faq__container--content {
    padding-top: pxToRem(20);
}

.faq__content--header {
    font-size:   pxToRem(16);
    font-weight: 500;
    line-height: 1.38;
    margin:      0;
    width:       pxToRem(175);

    .col-2 & {
        width: pxToRem(175);
    }

    .col-1 & {
        width: pxToRem(220);
    }
}
