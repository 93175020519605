/* Module: S */
.full-width-carousel {
    background-color: $medium-grey;
    overflow: hidden;
    position: relative;
    z-index: 9;
    .container-fluid {
        max-width: 77rem;
    }
    .block-tile--header h2 {
        font-size: pxToRem(28)
    }
    .slick-arrow {
        background-color: $white;
        border-radius: 100%;
        border: 0 none;
        height: 70px;
        position: absolute;
        text-indent: -9999px;
        top: 52.8%;
        white-space: nowrap;
        width: 70px;
        z-index: 11;
        &::after {
            background-image: url('mysource_files/slider-chevron.svg');
            background-position: center center;
            background-repeat: no-repeat;
            content: '';
            display: block;
            height: 20px;
            left: 60%;
            position: absolute;
            top: 50%;
            width: 20px;
        }
    }
    .slick-prev {
        left: 20px;
        &::after {
            left: 50%;
            transform: translate(-50%, -50%)
        }
    }
    .slick-next {
        right: 20px;
        &::after {
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg)
        }
    }
    .slick-dots {
        align-items: center;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: pxToRem(21) 0 pxToRem(35) 0;
        padding: 0;
        width: 100%;
        li {
            background-color: $carousel-dots;
            border-radius: 100%;
            cursor: pointer;
            height: 11px;
            margin: 0 pxToRem(5);
            width: 11px;
            &.slick-active {
                background-color: $teal;
            }
        }
        button {
            border: 0 none;
            text-indent: -9999px;
            white-space: nowrap;
            display: none;
        }
    }
}

.full-width-carousel-desktop {
    display: none;
}

.full-width-carousel__content-item {
    height: pxToRem(338);
    max-width: 1200px;
    overflow: hidden;
    position: relative;
    width: calc(100vw - 32px);
}

.full-width-carousel__content-item--link {
    background-image: url('mysource_files/example-image.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    padding: pxToRem(25) pxToRem(60) pxToRem(25) pxToRem(20);
    position: relative;
    text-decoration: none;
    align-content: flex-end;
    flex-wrap: wrap;
    &::before {
        background: linear-gradient(to top, #303030 0%, transparent 50%);
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.9;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }
    &::after {
        background-color: transparent;
        background-image: url('mysource_files/arrow--white-sort.svg');
        background-position: center center;
        background-repeat: no-repeat;
        bottom: 25px;
        content: '';
        display: inline-block;
        height: 1.0375rem;
        right: 20px;
        width: 1.5625rem;
        position: absolute;
    }
    &:hover,
    &:focus {
        .full-width-carousel__title {
            text-decoration: underline;
        }
    }
}

.full-width-carousel__label {
    background-color: $teal;
    color: $corporate-services;
    display: inline-block;
    font-size: pxToRem(10);
    font-weight: 500;
    margin-bottom: 15px;
    padding: pxToRem(5) pxToRem(10);
    position: relative;
    text-transform: uppercase;
    z-index: 1;
}

.full-width-carousel__date {
    color: $white;
    display: block;
    flex-basis: 100%;
    margin: 0 0 pxToRem(5) 0;
    font-weight: normal;
    letter-spacing: -0.3px;
    margin-bottom: pxToRem(6); // margin-top: 20px;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
}

.full-width-carousel__title {
    color: $white;
    flex-basis: 100%;
    font-size: pxToRem(24);
    font-weight: 600;
    line-height: 1.13;
    margin: 0;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
}

.full-width-carousel__description {
    color: $white;
    letter-spacing: -0.3px;
    margin: pxToRem(12) 0;
    position: relative;
    z-index: 1;
}