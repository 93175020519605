/* Module: S */
.full-width-carousel {
    .container-fluid {
        position: initial;
    }
    .block-tile--header h2 {
        font-size: pxToRem(40)
    }
}

.full-width-carousel-desktop {
    display: block;
    position: initial;
}

.full-width-carousel-mobile {
    display: none;
}

.full-width-carousel__container {
    max-width: 1200px;
    width: calc(100vw - 32px);
}

.full-width-carousel__content .col-xs-12:first-of-type {
    margin-bottom: 8px;
}

.full-width-carousel__content-item {
    // height: 100%;
    height: pxToRem(488);
    max-width: none;
    width: auto;
}

.full-width-carousel__content-item--small {
    height: 240px;
    .full-width-carousel__description {
        display: none;
    }
}

.full-width-carousel__description {
    margin: pxToRem(11) 0 pxToRem(20) 0;
}

.full-width-carousel__content-item--small .full-width-carousel__title {
    font-size: pxToRem(20);
    margin-bottom: pxToRem(20);
}