/* Module: S */
.active-video-banner {
    overflow: hidden;
    position: relative;
    &-inner {
        margin:     0 auto;
        max-width:  920px;
        position:   relative;
        text-align: center;
        z-index:    1;
    }
    &__text-wrapper {
        margin-top: pxToRem(35);
        text-align: center;
        h1,
        h2 {
            color:          $white;
            word-break:     break-word;
        }
        h1 {
            font-size:   pxToRem(30);
            font-weight: bold;
            line-height: 1.16;
        }
        h2 {
            font-size: pxToRem(16);
        }
    }
    &__toggle-button {
        display: none;
    }
    &__button-wrapper {
        display:    inline-block;
        margin:     pxToRem(35) 0;
        text-align: center;
        .btn {
            @include sq-transition(all .35s ease);
            background:      $corporate-services;
            border:          none;
            color:           $white;
            display:         inline-block;
            position:        relative;
            text-align:      left;
            text-decoration: none;

            &:after {
                content:    '';
                display:    inline-block;
                position:   absolute;
            }

            &:hover,
            &:focus {
                background: rgba($corporate-services, 0.9);
                outline:    none;
            }
            &:first-child {
                margin-bottom: pxToRem(8);
            }

            &__small {
                font-size:  pxToRem(16);
                min-height: pxToRem(50);
                margin:     0;
                padding:    pxToRem(14) pxToRem(60) pxToRem(14) pxToRem(20);
                width:      100%;

                &:after {
                    background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
                    height:     pxToRem(16.6);
                    right:      pxToRem(20);
                    top:        pxToRem(16);
                    width:      pxToRem(27.4);
                }
            }
            &--jungle-green {
                background: $jungle-green;
                &:hover,
                &:focus {
                    background: darken($jungle-green, 5%);
                }
            }
            &--teal {
                background: $teal;

                &:hover,
                &:focus {
                    background: darken($teal, 5%);
                }
            }
        }
    }
    &__video-bg--fallback,
    &__video-bg--loop {
        height:   auto;
        left:     0;
        position: absolute;
        top:      0;
        width:    100%;
    }
    &__video-bg--fallback {
        background-position: center center;
        background-size: cover;
        display:         block;
        height:          100%;
        visibility:      visible;
    }
    &__video-bg--loop {
        display:    none;
        visibility: hidden;
    }
    &__overlay-black {
        opacity:          .5;
        background-color: $black;
        width:            100%;
        height:           100%;
        position:         absolute;
        background-size:  cover;
        left:             0;
        top:              0;
    }
}

//Themes: fishermans-bend

.fishermans-bend {
    .active-video-banner__button-wrapper .btn--jungle-green {
        background: $fishermans-bend;
    }
}