/* Module: S */
.categories {
    border-top: pxToRem(1) solid darken($medium-grey, 7%);
    color:      $corporate-services;
    margin:     pxToRem(24) 0;

    .categories__heading {
        font-size: pxToRem(18);
        margin:    pxToRem(32) 0 0;
    }

    .categories__wrapper {
        margin: 0 pxToRem(-8);
    }

    .categories__link {
        @include sq-transition(background-color .35s ease);
        background-color: darken($medium-grey, 7%);
        border-radius:    pxToRem(50);
        color:            #3d3d3d;
        display:          inline-block;
        font-size:        pxToRem(16);
        font-weight:      400;
        margin:           pxToRem(8);
        padding:          pxToRem(10) pxToRem(24);
        text-align:       center;
        text-decoration:  none;

        &:hover,
        &:focus {
            background-color: darken($medium-grey, 12%);
        }
    }
}
