/* Module: S */
.form-accordion {
    &__item-header {
        .form-accordion__btn {
            @include sq-transition(all .35s ease);
            background:      $corporate-services;
            bottom:          0;
            border:          none;
            color:           $white;
            display:         inline-block;
            font-size:       pxToRem(14);
            font-weight:     400;
            margin:          auto;
            max-width:       215px;
            max-height:      pxToRem(39);
            outline:         none;
            overflow:        hidden;
            padding:         pxToRem(10) pxToRem(37) pxToRem(10) pxToRem(16);
            position:        absolute;
            right:           pxToRem(25);
            text-align:      left;
            text-decoration: none;
            text-overflow:   ellipsis;
            top:             0;
            white-space:     nowrap;
            z-index:         1;

            &::after {
                background: url('{{file_dest}}/form-icon-white.svg') no-repeat center center transparent;
                content:    '';
                height:     pxToRem(16);
                position:   absolute;
                right:      pxToRem(15);
                top:        pxToRem(11);
                width:      pxToRem(12);
            }

            &:hover,
            &:focus {
                background: rgba($corporate-services, 0.9);
            }
        }
    }
    &__title {
        max-width: calc(100% - 250px);
    }
    &__subtitle {
        display:     inline-block;
        font-size:   pxToRem(12);
        font-weight: normal;
        line-height: 2.5;
        padding:     0;
        position:    relative;
        width:       auto;

        &::after {
            @include sq-transform(translateY(-50%));
            @include sq-transition(transform .35s ease);
            background: url('{{file_dest}}/chevron-teal.svg') no-repeat center center transparent;
            content:    '';
            height:     pxToRem(16);
            position:   absolute;
            right:      pxToRem(-25);
            top:        50%;
            width:      pxToRem(16);
        }
    }
    &__target-list {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        columns:              2;
    }
    .accordion__target {
        background-color: rgba(188, 184, 199, 0.16);
        color:            $corporate-services;
        font-size:        pxToRem(14);

        .form-accordion__btn {
            display: none;
        }

        p {
            margin: 0;
        }
    }
}
