/* Module: S */
.social-carousel.slick-slider {
    position: relative;
}

.social-carousel .slick-prev {
    left: -75px;
}

.social-carousel .slick-next {
    right: -75px;
}

.social-carousel .slick-arrow {
    top: 50%;
}