/* Module: S */
.media {
    background-color: $section-grey;
    padding: 20px 0;
}

.media .container-fluid {
    max-width: 77rem;
}

.media-tiles {
    background-color: $section-grey;
}

.media-tiles .container-fluid {
    padding: 0 .5rem;
}

.media-tiles__item {
    padding: 0;
    background-color: $white;
    border: 1px solid $border-grey;
}

.media-tiles__item:nth-of-type(2n) {
    border-left: none;
}

.media-tiles__item:nth-of-type(3),
.media-tiles__item:nth-of-type(4) {
    border-top: none;
}

.media-link {
    text-decoration: none;
    font-weight: 500;
    color: $corporate-services;
    display: block;
    padding: 24px;

    &:hover,
    &:focus {
        .media-link__title {
            text-decoration: underline;
        }
    }
}

.media-link__image {
    display: block;
    margin: 0 auto;
    height: 2.5rem;
    width: 2.5rem;
}

.media-link__title {
    display: block;
    text-align: center;
    padding-top: 23px;
}

.media-link__image {
    height: 2.8125rem;
    width: 2.8125rem;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
}

.media-link__image--news {
    background-image: url('mysource_files/news-icon.svg');
}

.media-link__image--video {
    background-image: url('mysource_files/video-icon.svg');
}

.media-link__image--podcast {
    background-image: url('mysource_files/podcast-icon.svg');
}

.media-link__image--pictures {
    background-image: url('mysource_files/pictures-icon.svg');
}

.media-link__image--mediacontacts {
    background-image: url('mysource_files/media-contacts-icon.svg');
}