/* Module: S */
.content-drawer {
    width: auto;

    .content-drawer__close {
        line-height: pxToRem(100);
        padding:     0 pxToRem(40);
    }

    .content-drawer__content-wrapper {
        height: calc(100% - 100px);
    }

    .content-drawer__content {
        margin:     0 pxToRem(90) pxToRem(80) pxToRem(80);
        padding:    0;
        width:      pxToRem(720);
    }
}
