/* Module: S */
// 1. TITLE BANNER
.title-banner {
    border-bottom: pxToRem(1) solid rgba($corporate-services, .31);
    color:         $corporate-services;
    z-index:       10;

    .container-fluid {
        max-width: pxToRem(1232);
    }

    .title-banner__backlink-wrapper {
        background-color: $white;
    }

    .title-banner__topic-wrapper {
        display: none;
    }

    .title-banner__navigation {
        display: none;
    }

    .title-banner__heading-wrapper {
        background-color: $white;
        padding:          pxToRem(30) 0 pxToRem(50);
    }

    .title-banner__backlink {
        @include sq-transition(all .35s ease);
        color:           inherit;
        display:         inline-block;
        font-size:       pxToRem(14);
        margin:          pxToRem(20) 0 0;
        text-decoration: none;

        &:hover,
        &:focus {
            outline:         none;
            text-decoration: underline;
        }

        &::before {
            @include sq-transform(rotate(90deg) translate(pxToRem(-2), pxToRem(2)));
            background:      url('{{file_dest}}/chevron--navy.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            display:         inline-block;
            height:          pxToRem(4);
            margin:          0 pxToRem(10) 0 0;
            width:           pxToRem(8);
        }
    }

    .title-banner__topic {
        color:       $white;
        font-size:   pxToRem(35);
        font-weight: 500;
        margin:      pxToRem(16) 0;
    }

    .title-banner__list {
        @include sq-list-reset();
        height:   pxToRem(72);
        //overflow: scroll;
        overflow: hidden;
        position: relative;
    }

    .slick-arrow {
        background:  -moz-linear-gradient(to right, rgba($white, .0) 0%, #e6e6e6 75%, #e6e6e6 100%);
        background:  -webkit-linear-gradient(left, rgba($white, .0) 0%, #e6e6e6 75%, #e6e6e6 100%);
        background:  -o-linear-gradient(left, rgba($white, .0) 0%, #e6e6e6 75%, #e6e6e6 100%);
        background:  -ms-linear-gradient(left, rgba($white, .0) 0%, #e6e6e6 75%, #e6e6e6 100%);
        background:  linear-gradient(to right, rgba($white, .0) 0%, #e6e6e6 75%, #e6e6e6 100%);
        border:      0;
        display:     block;
        height:      100%;
        margin:      0;
        overflow:    hidden;
        padding:     0;
        position:    absolute;
        text-indent: 100%;
        top:         0;
        white-space: nowrap;
        width:       pxToRem(72);
        z-index:     5;

        &:focus {
            outline: none;
        }

        &::after {
            background:      url('{{file_dest}}/chevron--navy.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            height:          pxToRem(6);
            position:        absolute;
            top:             50%;
            width:           pxToRem(12);
        }
    }

    .slick-next {
        right: 0;

        &::after {
            @include sq-transform(rotate(270deg) translateX(25%));
            right: 0;
        }
    }

    .slick-prev {
        @include sq-transform(rotate(180deg));
        left: 0;

        &::after {
            @include sq-transform(rotate(270deg) translateX(25%));
            right: 0;
        }
    }

    .slick-list {
        padding: 0 pxToRem(16);
    }

    .title-banner__item {
        display: inline-block;
        margin:  0 pxToRem(36) 0 0;
    }

    .title-banner__link {
        @include sq-transition(color .35s ease);
        color:           inherit;
        display:         block;
        font-size:       pxToRem(14);
        font-weight:     500;
        padding:         pxToRem(27) 0 pxToRem(26);
        position:        relative;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: none;
            color:   $corporate-services--hover;
        }
    }

    .title-banner__link--active {

        &::after {
            @include sq-transform(translateX(-50%));
            border-color: transparent transparent $teal;
            border-style: solid;
            border-width: 0 pxToRem(7) pxToRem(15);
            bottom:       0;
            content:      '';
            left:         50%;
            position:     absolute;
        }
    }

    .title-banner__heading {
        font-size:   pxToRem(32);
        font-weight: 400;
        line-height: pxToRem(40);
        margin:      0 0 pxToRem(13);
    }

    .title-banner__descr {
        font-size:   pxToRem(16);
        font-weight: 600;
        margin:      0;
    }
}

// 2. TITLE BANNER W/ TOPIC
.title-banner--topic {

    .title-banner__backlink-wrapper {
        background-color: $white;
        color:            $corporate-services;
        display:          block;
    }

    .title-banner__topic-wrapper {
        background-color: rgba($corporate-services, .7);
        color:            $white;
        display:          none;
    }

    .title-banner__navigation {
        background-color: lighten($black, 90%);
        display:          none;
    }

    .title-banner__heading-wrapper {
        background-color: $white;
    }

    .title-banner__backlink {

        &::before {
            background:      url('{{file_dest}}/chevron--navy.svg') no-repeat center center;
            background-size: cover;
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management .title-banner {
    color: $forest-fire-mgmt-neutral;   

    .title-banner__link--active::after {
        border-bottom-color: $forest-fire-mgmt;
    }

    &__social-sharing__item--facebook {
        background-image: url('{{file_dest}}/social-facebook--smoke-black.svg');
    }

    &__social-sharing__item--twitter {
        background-image: url('{{file_dest}}/social-twitter--smoke-black.svg');
    }

    &__social-sharing__item--linkedin {
        background-image: url('{{file_dest}}/social-linkedin--smoke-black.svg');
    }

    &__social-sharing__item--email {
        background-image: url('{{file_dest}}/social-email--smoke-black.svg');
    }

}

// Theme: Fishermans Bend
.fishermans-bend .title-banner {
    color: $fishermans-bend-black;

    &__topic {
        color: $corporate-services;
    }

    &__link {
        &:hover,
        &:focus {
            color: mix($fishermans-bend-black, $white, 70%);
        }

        &--active::after {
            border-bottom-color: $fishermans-bend;
        }
    }
}
