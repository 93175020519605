/* Module: S */
.header-banner.campaign {
    .banner {

        &__heading {
            font-size: pxToRem(60);
        }

        &__subheading {
            font-size: pxToRem(20);
        }

    }

}
