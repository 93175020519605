/* Module: S */
.analytics {

    &__toolbar {
        align-items:     flex-end;
        display:         flex;
        flex-direction:  column;
    }

}

.analytics-loader {
    border: 8px solid #B2B1BD; /* Light grey */
    border-top: 8px solid #1A1E44; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 10px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
