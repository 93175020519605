body {

    &::after {
        content: 'wide';
    }
}
/*
--------------------
Modules
--------------------
*/