/* Module: S */
.grants-info__wrapper,
.sidebar__wrapper {
    background-color: darken($medium-grey, 7%);
    color:            $corporate-services;
    font-size:        pxToRem(14);
    padding:          pxToRem(31) pxToRem(34) pxToRem(40);
    position:         relative;
    width:            100%;
    z-index:          10;

    .sidebar__heading {
        font-size: pxToRem(24);
        margin:    0 0 pxToRem(16);
    }

    .grants-info__btn,
    .sidebar__btn {
        @include sq-transition(background-color .35s ease);
        background-color: $corporate-services;
        color:            $white;
        display:          block;
        padding:          pxToRem(10) pxToRem(16) pxToRem(11);
        text-align:       center;
        text-decoration:  none;

        &:hover,
        &:focus {
            background-color: rgba($corporate-services, .9);
        }

        + .grants-info__btn,
        + .sidebar__btn {
            margin-top: pxToRem(15);
        }
    }

    .grants-info__btn--teal,
    .sidebar__btn--teal {
        background-color: $teal;

        &:hover,
        &:focus {
            background-color: $teal-light;
        }
    }

    .grants-info__terms,
    .sidebar__terms {
        margin: 0;

        .grants-info__terms-field,
        .sidebar__terms-field {
            display: flex;
            margin:  pxToRem(24) 0;

            &:last-child {
                margin-bottom: 0;
            }

            &--no-title {
                dd {
                    flex-basis:  100%;
                }
            }
        }

        dt,
        dd {
            display: inline-block;
        }

        dd {
            flex-basis:  75%;
            margin-left: 0;
        }

        dt {
            flex-basis:  25%;
            font-weight: 500;

            &::after {
                content: ':';
            }
        }

        p,
        ol,
        ul {
            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.grants-info__wrapper {
    margin-bottom: 1rem;
}
