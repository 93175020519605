/* Module: S */
.ripple-footer {
  padding-left: pxToRem(32);
  padding-right: pxToRem(32);
}

.ripple-footer__flag {
  margin-bottom: 0;
}

.ripple-footer__acknowledgement {
  align-items: flex-start;
  display: flex;
}

.ripple-footer__text {
  margin-left: pxToRem(12);
}

.ripple-footer__text--caption,
.ripple-footer__text--copyright {
  margin-left: 0;
}

.ripple-footer__bottom {
  padding-top: pxToRem(12);
}

.ripple-footer__bottom-logos a {
  margin-right: pxToRem(20);

  &:last-child {
    margin-right: 0;
  }
}

.ripple-footer__logo {
  max-width: pxToRem(112);
}
