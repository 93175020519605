/* Module: S */
.publications {

    &__heading {
        color:           $navy;
        text-decoration: none;

        &:hover,
        &:focus {
            outline:         none;
            text-decoration: underline;
        }

    }

    &__counter {
        color:       $grey;
        font-size:   pxToRem(14);
        line-height: 2;
    }

    &__items-list {
        @include sq-list-reset;
    }

    &__item {
        color:   $corporate-services;
        display: block;
        margin:  0 0 pxToRem(24);

        .publications__data-wrapper {
            border:        pxToRem(1) solid lighten($grey, 29%);
            padding:       pxToRem(24);
            

            .publications__heading {
                display:       inline-block;
                font-size:     pxToRem(24);
                font-weight:   600;
                margin-bottom: pxToRem(10);
                position:      relative;
            }

            .search__result--type {
                position:  relative;
                top:       50%;
                transform: translateY(-50%);
            }

            .publications__category-wrapper {
                display: block;

                span {
                    font-weight: 500;

                    &::after {
                        content: '|';
                        margin:  0 pxToRem(6);
                    }

                    &:last-child::after {
                        content:      '';
                        margin-right: 0;
                    }

                }
            }

            .publications__description {
                color: $grey;
            }

            .publications__accessible {
                span {
                    color:       $corporate-services;
                    font-weight: 500;
                    font-size:   pxToRem(14);
                }

                &--link {
                    color:     $corporate-services;
                    font-size: pxToRem(14);
                    word-wrap: break-word;

                    &:hover,
                    &:focus {
                        outline:         none;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
