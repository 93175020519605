/* Module: S */
// 1. CONTENT WRAPPER
.content-wrapper {
    border-bottom:  pxToRem(1) solid rgba($corporate-services, .31);
    padding-bottom: pxToRem(40);
    padding-top:    pxToRem(40);

    &:first-child {
        padding-top: 0;
    }

    .content-wrapper__col-header {
        color:  $corporate-services;
        margin: pxToRem(11) 0;

        .main.col-1 & {
            margin-top: 0;
        }
    }

    .content-wrapper__col-media {
        height:         0;
        padding-bottom: 56.25%;
        padding-top:    1px;
        position:       relative;

        img {
            display:  block;
            height:   100%;
            margin:   0 auto;
            position: absolute;
            width:    auto;
        }

        iframe {
            height:   100%;
            left:     0;
            position: absolute;
            top:      0;
            width:    100%;
        }

    }

}

 .no-border {
    border: none;
}

.feature-box {
    padding-top: 1rem;

    &:first-child {
        padding-top: 0;
    }

}

// Theme: Forest Fire Management
.forest-fire-management {

    .content-wrapper .content-wrapper__col-header {
        color: $forest-fire-mgmt-neutral;
    }

}
