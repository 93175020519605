/* Module: S */
.two-topic-tiles {

    .col-xs-12.col-sm-6 {

        &:last-child {
            padding-top: 0;
        }

    }

}
