/* Module: S */
.map-wrapper {

    .container-fluid {
        max-width: pxToRem(1232);
    }
}

.maps {
    border: none;
    height: pxToRem(615);
    width:  100%;
}
