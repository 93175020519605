/* Module: S */
//Breadcrumbs
.search-header {

    .breadcrumb {
        width: 50%;
    }

}

.search {

    &__form-filbtn-wrap {

        .search__form-wrap {
            width: 100%;

            & > form {
                width: 75%;
            }

        }

        .search__filter-btn-wrap {
            top: 0;

            .search__clear-btn {
                height: pxToRem(50);

                &:hover,
                &:focus {
                    background-color: rgba($corporate-services, 0.79);
                    color:            $white;
                    outline:          0;
                    user-select:      none;
                }

                .filter-clear-btn__text {
                    font-size: pxToRem(14);
                    line-height: pxToRem(50);
                }

            }

        }

    }

}

.search-filters {

    form {

        .search-filters__header-wrap {

            .search-filters__header {

                .search-filters__clear,
                .reset-search-facets--mobile,
                .planning-scheme-reset-search-facets--mobile {
                    left: pxToRem(20);
                }

                .search-filters__close {
                    right: pxToRem(20);
                }

            }

        }

    }

}
