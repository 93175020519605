/* Module: S */
.search-header {
    background-color: rgba($planning, 0.79);
    color:            $white;
    position:         relative;

    // Breadcrumbs
    .breadcrumb {
        line-height: pxToRem(15);
        margin: 0 0 pxToRem(15) 0;
        padding: pxToRem(15) pxToRem(10) 0 pxToRem(10);
        list-style: none;

        &__item {
            display: inline-block;

            &::after {
                content: '/';
                font-size: pxToRem(12);
                font-weight: 500;
                letter-spacing: pxToRem(-0.1);
                padding: 0 pxToRem(4) 0  pxToRem(6);
            }

            &:last-of-type::after {
                content: none;
            }

        }

        a {
            color: $white;
            font-size: pxToRem(12);
            font-weight: 500;
            letter-spacing: pxToRem(-0.1);
            position: relative;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }

        }

    }

    > .container-fluid {
        max-width:      pxToRem(1232);
    }

    .search-header__title-wrap {
        padding:   pxToRem(15) pxToRem(10) pxToRem(27) pxToRem(10);

        > h1 {
            color:         $white;
            font-size:     pxToRem(32);
            font-weight:   600;
            line-height:   pxToRem(40);
            margin-top:    0;
            margin-bottom: pxToRem(14);
        }

        .search-header__desp-wrap {
            display: flex;
            flex-wrap: wrap;

            a {
                color: $white;
                font-size: pxToRem(16);
                font-weight: bold;
                line-height: pxToRem(22.5);
                margin: 0;
            }

            > p,
            &-link {
                color: $white;
                display: table;
                font-size: pxToRem(16);
                font-weight: bold;
                line-height: pxToRem(22.5);
                margin: 0;
            }

            > p {
                margin-right: pxToRem(10);
            }

        }

    }

    &__sec-desp {
        margin: 5px 0;
        font-size: 14px;

        span {
            font-weight: bold;
        }

    }

    // amendments search-header template
    .amendments & .breadcrumb {
        padding-left: 0;
    }

    .amendments &__desp-wrap {
        font-size: 16px;
        font-weight: 600;
    }

    .amendments &__content-wrap {
        width: 100%;

        h1 {
            color:         $white;
            font-size:     pxToRem(32);
            font-weight:   600;
            line-height:   pxToRem(40);
            margin-top:    0;
            margin-bottom: pxToRem(14);
            text-transform: capitalize;
        }

    }

    .amendments &__change-plnning-btn {
        border: 1px solid $white;
        color: $white;
        display: block;
        font-size: pxToRem(12);
        margin: 20px 0;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
    }

    .amendments &__select-planning-btn {
        display: none;
    }

}

// Main
.search {

    .main {
        position: relative;
        z-index: 1;

        &.search-filter--active {
            z-index: inherit;
        }

    }

    &__form-filbtn-wrap,
    &__filter-btn-wrap {
        display: block;

        button {
            border:  none;
            padding: 0;
        }

    }

    &__group {

        .twitter-typeahead {
            width: 100%;
        }

    }

    &__modes {
        display:    flex;
        margin-top: pxToRem(10);
    }

    &__mode-group {
        flex-shrink: 0;
        margin-left: 1rem;

        &:first-child {
            margin-left: 0;
        }

    }

    &__mode-radio {
        border:   0;
        clip:     rect(0 0 0 0);
        height:   1px;
        margin:   -1px;
        overflow: hidden;
        padding:  0;
        position: absolute;
        width:    1px;

        &:focus + .search__mode-label::before {
            border-color: $planning;
            box-shadow:   0 0 2px 1px rgba($planning, .9);
        }

        &:checked + .search__mode-label::after {
            background-color: mix($planning, $white, 79%);
        }

    }

    &__mode-label {
        cursor:       pointer;
        padding-left: pxToRem(16 + 6);
        position:     relative;

        &::before,
        &::after {
            content:  '';
            position: absolute;
        }

        &::before {
            background-color: transparent;
            border:           1px solid $dark-blue;
            border-radius:    8px;
            left:             0;
            height:           pxToRem(16);
            top:              3px;
            width:            pxToRem(16);
        }

        &::after {
            background-color: transparent;
            border-radius:    5px;
            left:             3px;
            height:           pxToRem(10);
            top:              6px;
            width:            pxToRem(10);
        }

    }

}

.search__form-filbtn-wrap {
    background-color: $wild-sand;
    padding: pxToRem(24) pxToRem(12) pxToRem(14) pxToRem(2);
    position: relative;
    width: 100%;

    > .container-fluid {
        max-width:      pxToRem(1232);
    }

    .search__form-wrap {
        flex-basis: 0%;
        flex-grow: 1;
        flex-shrink: 1;
        height: pxToRem(36);
        margin-right: pxToRem(10);
        position: relative;

        > form {
            padding:  0;
            border:   none;
            position: relative;

            .search__group {
                display:  inline-block;
                position: static;
                width:    100%;
                height:   100%;
                .search__input {
                    width:            inherit;
                    padding:          pxToRem(0) pxToRem(25) pxToRem(10) pxToRem(1);
                    height:           inherit;
                    background-color: $wild-sand;
                    font-size:        pxToRem(18);
                    font-weight:      normal;
                    color:            $corporate-services;
                    border-bottom:    pxToRem(2) solid rgba($valhalla, 0.5);

                    &:focus {
                        border-bottom: pxToRem(2) solid $corporate-services;
                    }

                    // autosuggestion
                    &--menu {
                        width: 100%;
                        box-shadow: 0 0 3px 0 rgba($corporate-services, 0.5);
                        margin-top: pxToRem(5);
                    }
                    &--suggestion {
                        padding: pxToRem(19) pxToRem(18);
                        font-size: pxToRem(18);
                        background: $white;
                        color: $planning;
                        font-family: 'VIC';

                        &:hover {
                            color: $corporate-services;
                            background: $wild-sand;
                        }
                    }
                    &--selectable {
                        cursor: pointer;
                    }
                    &--cursor {
                        color: $corporate-services;
                        background: $wild-sand;

                        &.search__input--view-all {
                            background: $corporate-services;
                            color: $white;
                        }
                    }
                    &--view-all {
                        background: $planning;
                        color: $white;
                        font-size: pxToRem(16);
                        text-align: center;

                        .search__input--highlight {
                            font-weight: normal;
                        }

                        &:hover {
                            background: $planning;
                            color: $white;
                        }
                    }
                    &--dataset {
                        max-height: pxToRem(260);
                        overflow: hidden;
                        overflow-y: inherit;
                    }
                }

                input::placeholder {
                    color:       $corporate-services;
                    font-weight: normal;
                    letter-spacing: -0.2px;
                }

                input:focus {
                    border-bottom: pxToRem(2) solid $teal;
                }

                input:focus::placeholder {
                    color: rgba($corporate-services, 0.5);
                }

                input::-ms-clear {
                    display: none;
                }

                .search__submit {
                    position: absolute;
                    top:    pxToRem(0);
                    right:  pxToRem(-1);
                    width:  pxToRem(23);
                    height: pxToRem(23);

                    &.search__submit--icon {
                        display:    block;
                        background: url('{{file_dest}}/search-icon.svg') no-repeat top left transparent;
                        background-size: contain;
                    }

                    &.search__submit--cancel {
                        display:    none;
                        width:      pxToRem(17);
                        height:     pxToRem(17);
                        background: url('{{file_dest}}/cancel.svg') no-repeat top left transparent;
                        background-size: contain;
                        top: pxToRem(5);
                    }
                }
            }
        }

        .search__input-autocomplete {
            width: 100%;
            height: auto;
            position: absolute;
            top: pxToRem(40);
            background-color: $white;
            box-shadow: 0 0 pxToRem(3) 0 rgba(32,21,71,0.5);
            > ul {
                padding: 0;
                margin:  0;
                > li {
                    list-style: none;
                    > a {
                        display: block;
                        font-size: pxToRem(16);//desktop 18
                        line-height: pxToRem(24);
                        padding: pxToRem(7) pxToRem(19) pxToRem(8) pxToRem(19);
                        text-decoration: none;
                        color: $corporate-services;

                        &:hover,
                        &:focus {
                            background-color: $wild-sand;
                        }

                        &:active {
                            color: $corporate-services;
                        }

                    }

                    &:first-of-type {

                        > a {
                            padding-top: pxToRem(16);
                        }

                    }

                    &:last-of-type {
                        > a {
                            background-color: $teal;
                            font-size: pxToRem(16);
                            font-weight: 500;
                            line-height: pxToRem(22);
                            padding: pxToRem(13) pxToRem(19) pxToRem(15) pxToRem(19);
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .search__form-wrap--modes {
        height: pxToRem(70);
    }

    .search__filter-btn-wrap {
        align-items: center;
        display: flex;
        flex-shrink: 0;

        .search__clear-btn {
            background-color: $wild-sand;
            color: $haiti2;
            display: none;
            // height: 100%;
            height: pxToRem(24);
            // line-height: pxToRem(24);
            margin-right: pxToRem(10);
            padding: 0 pxToRem(10);
            position: relative;
            outline: 0;
            user-select: none;


            &.search__clear-btn--active {
                display: inline-block;
            }

            .filter-clear-btn__text {
                display: inline-block;
                font-size: pxToRem(10);
                text-transform: uppercase;
                font-weight: 600;
                // line-height: pxToRem(50);
            }
        }

        .search__filter-btn {
            width:      pxToRem(24);
            height:     pxToRem(24);
            background: none;
            position:   relative;

            &:hover,
            &:focus {
                outline: none;
            }

            > svg {
                fill: $corporate-services;
            }

            .filter-btn__text {
                display: none;
            }

            .filter-btn__number {
                display: none;
                position: absolute;
                top:   pxToRem(-9);
                right: pxToRem(-1);
                width: 15px;
                height: 15px;
                background-color: $teal;
                border-radius: 50%;
                font-size: pxToRem(10);
                font-weight: bold;

                &.has-data {
                    display: block;
                }
            }
        }
    }
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes slideup {
 0%   {
     transform: translateY(400px);
 }
 12.5% {
     transform: translateY(350px);
 }
 25% {
     transform: translateY(300px);
 }
 37.5% {
     transform: translateY(250px);
 }
 50% {
     transform: translateY(200px);
 }
 67.5% {
     transform: translateY(150px);
 }
 75% {
     transform: translateY(100px);
 }
 87.5%{
     transform: translateY(50px);
 }
 100% {
     transform: translateY(0);
 }
}

.search-filters {
    display:            none;

    &.active {
        display:           block;
        background-color:  $white;

        .filter-tabs__wrap {
            width:             100%;
            position:          fixed;
            z-index:           31;
            top:               pxToRem(60);
            bottom:            pxToRem(60);
            overflow:          auto;
            -webkit-animation: slideup 0.3s ease-out;
            animation:         slideup 0.3s ease-out;
            background-color:  $wild-sand;
        }


        .container-fluid {
            position: static;
        }

        form {
            position: absolute;
            left:     0;
            right:    0;
            padding-bottom: pxToRem(60);
        }
    }

    form {
        padding: 0;
        border:  none;

        label.picker__label--year {
            outline: 0;
            display: flex!important;
            align-content: center;
            color: $white!important;
            margin-left: 0!important;
            margin-bottom: 0!important;
            font-size: pxToRem(16) !important;
            order: 4!important;
            flex-grow: 1!important;
            flex-shrink: 1!important;
            flex-basis: 98px!important;
            border-left: 1px solid rgba(0,0,0,0.2);
            padding: 15px 0 15px 37px;
            line-height: pxToRem(19);

            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                content: '\025BC';
                color: $white;
                visibility: visible;
                font-size: pxToRem(12);
                vertical-align: middle;
                margin-left: pxToRem(-15);
                pointer-events: none;
            }
        }

        .search-filters__header-wrap,
        .search-filters__footer-wrap {
            position: fixed;
            z-index:  30;
            width:    100%;
            -webkit-animation: slideup 0.3s ease-out;
            animation:         slideup 0.3s ease-out;
        }

        .search-filters__header-wrap {
            top:      0;
            background-color: $white;
            padding:  pxToRem(17) pxToRem(6) pxToRem(19) pxToRem(12);

            .search-filters__header {
                position: relative;
            }
            .reset-search-facets--mobile,
            .planning-scheme-reset-search-facets--mobile {
                position:        absolute;
                top:             pxToRem(3);
                text-decoration: underline;
                font-size:       pxToRem(14);
                font-weight:     500;
                letter-spacing:  pxToRem(-0.3);
                left:            pxToRem(1);
            }
            button {
                padding: 0;
                border:  none;
                background: none;

                &.search-filters__clear,
                &.search-filters__close,
                &.reset-search-facets--mobile,
                &.planning-scheme-reset-search-facets--mobile {
                    position:        absolute;
                    top:             pxToRem(3);
                }

                &.search-filters__clear,
                &.reset-search-facets--mobile,
                &.planning-scheme-reset-search-facets--mobile {
                    text-decoration: underline;
                    font-size:       pxToRem(14);
                    font-weight:     500;
                    letter-spacing:  pxToRem(-0.3);
                    left:            pxToRem(1);
                }

                &.search-filters__close {
                    float:     right;
                    font-size: 0;
                    width:     pxToRem(19);
                    height:    pxToRem(19);
                    right:     pxToRem(0);
                    & > svg {
                        fill: $corporate-services;
                    }
                }
            }

            h2 {
                margin:         0;
                padding:        pxToRem(0) pxToRem(30) pxToRem(0) pxToRem(30);
                text-align:     center;
                font-size:      pxToRem(18);
                font-weight:    500;
                line-height:    pxToRem(24);
                letter-spacing: pxToRem(-0.4);

                .search-filters__number {
                    position: relative;
                    padding:  pxToRem(0) pxToRem(11) pxToRem(0) pxToRem(12);
                    &:before,
                    &:after {
                        position: absolute;
                        width:    pxToRem(3);
                        height:   100%;
                    }

                    &:before {
                        content: "(";
                        left:    pxToRem(6);
                    }

                    &:after {
                        content: ")";
                        right:   pxToRem(8);
                    }
                }
            }

            .filter-tabs__header-wrap {
                display: none;
            }
        }

        .search-filters__filter-tab {
            .filter-tab__header-wrap {
                background-color: $corporate-services;
                padding:          pxToRem(10) pxToRem(10) pxToRem(12) pxToRem(15);

                h3 {
                    margin:      0;
                    color:       $white;
                    font-size:   pxToRem(18);
                    font-weight: normal;
                    line-height: pxToRem(28);
                }
            }

            .search__fields-wrap {
                background-color: $wild-sand;
                padding:          pxToRem(0) pxToRem(9) pxToRem(18) pxToRem(10);
                border-bottom:    pxToRem(1) solid $from-border-grey;

                .filter-field__wrap {
                    display: flex;
                    padding: pxToRem(18) 0 pxToRem(6) pxToRem(1);

                    .filter-field {
                        width:          100%;
                        position:       relative;
                        padding-bottom: pxToRem(5);

                        button {
                            border:     none;
                            padding:    0;

                            &:hover,
                            &:focus {
                                outline: none !important;
                            }
                        }

                        .filter-field__clear {
                            display:    none;
                            position:   absolute;
                            right:      0;
                            bottom:     pxToRem(10);
                            width:      pxToRem(16);
                            height:     pxToRem(16);
                            background: url('{{file_dest}}/cancel.svg') no-repeat top left transparent;
                            background-size: contain;
                        }

                        .filter__clear {
                            position: absolute;
                            height: 20px;
                            width: 20px;
                            background-color: #6F6F6F;
                            border: 0 solid transparent;
                            border-radius: 50%;
                            top: 33px;
                            left: initial;
                            right: 3%;
                            display: flex;

                            &:before, &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                background-color: white;
                                height: 11px;
                                width: 2px;
                                top: 5px;
                                left: 9px;
                            }

                            &:before {
                                transform: rotate(-45deg);
                            }

                            &:after {
                                transform: rotate(45deg);
                            }
                        }

                        .picker__input {
                            cursor: pointer;

                            &:first-of-type {

                                &.hasValue + .filter__clear {
                                    left: 39%;
                                    right: initial;

                                    &.datepicker--clear {
                                        left: initial;
                                        right: 3%;
                                    }
                                }
                            }
                        }

                        .filter-field__icon-calendar {
                            position:   absolute;
                            top:        36px;
                            right:      10px;
                            width:      15px;
                            height:     14px;
                            background: url('{{file_dest}}/calendar-2018.svg') no-repeat top left transparent;
                            background-size: 15px 14px;
                        }

                        &.active {
                            .filter-field__clear {
                                // display: block;
                            }

                            .filter-field__icon-calendar {
                                display: none;
                            }
                        }

                        > label {
                            display:        block;
                            font-size:      pxToRem(10);
                            line-height:    pxToRem(15);
                            letter-spacing: pxToRem(0.2);
                            text-transform: uppercase;
                            margin-bottom:  pxToRem(3);
                            color:        #555457;
                            font-weight:    600;
                        }

                        input,
                        span {
                            text-transform:   capitalize;
                            background-color: $white;
                            font-size:        pxToRem(15);
                            font-weight:      400;
                            line-height:      pxToRem(20);
                            border:           1px solid #C8C8C8;
                            border-radius:    3px;
                            width:            100%;
                            padding-top:      pxToRem(14);
                            padding-right:    0;
                            padding-bottom:   pxToRem(16);
                            padding-left:     pxToRem(10);
                            transition: border-color .2s;

                            &:hover,
                            &:focus {
                                outline: none;
                                border-color: #9D64A0;
                            }

                            &::placeholder {
                                color:          #323232;
                            }
                        }

                        &.filter-field__text input::placeholder {
                            color: #6F6F6F;
                            font-style: italic;
                            font-size: pxToRem(13);
                        }

                        &.filter-field__dropdown, &.filter-field__dropdown--checkboxes {

                            &:focus {
                                outline: 0;
                            }

                            .filter-field__dropdown-value, .filter-field__dropdown-value--checkboxes {
                                -webkit-touch-callout: none;
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                &:hover, &:focus {
                                    cursor: pointer;
                                }
                            }

                            span.filter-field__dropdown-value--checkboxes {
                                color:          #323232;
                                letter-spacing: -0.2px;
                                display:        block;
                                position:       relative;
                            }

                            .filter-field__expand-select {
                                width:              0px;
                                height:             0px;
                                border-left:        pxToRem(5.59) solid transparent;
                                border-right:       pxToRem(5.59) solid transparent;
                                border-top:         pxToRem(5.59) solid #323232;
                                position:           absolute;
                                top:                50%;
                                right:              pxToRem(11.5);
                                transform:          translateY(-50%);
                                transition:         all .1s;
                                background-color:   transparent;
                            }

                            & > .filter-field__expand-select{
                                top:    pxToRem(44);
                            }

                            .filter-field__dropdown-list, .filter-field__dropdown-list--checkboxes {
                                max-height: 0;
                                margin: 0;

                                * {
                                    visibility: hidden;
                                }
                            }

                            &.active {

                                input,span.filter-field__dropdown-value--checkboxes {
                                    background-color: $white;
                                    position:         relative;
                                    text-transform:   capitalize;
                                    z-index:          6;
                                    width:            100%;
                                    border-color:     #9D64A0;
                                }

                                &.filter-field__dropdown--checkboxes {

                                    li {
                                        display:    flex;
                                        padding:    0;

                                        input:checked ~ .checkbox {
                                            background-color:   $planning;

                                            &:before {
                                                width:              6px;
                                                transition-delay:   0ms;
                                            }

                                            &:after {
                                                width:              11px;
                                                transition-delay:   .05s;
                                            }
                                        }
                                    }

                                    ul {

                                        input {
                                            display:    none;
                                        }

                                        label {
                                            cursor: pointer;

                                            &.wrapper {
                                                display:    flex;
                                                width:      100%;
                                                padding:    pxToRem(14) pxToRem(9) pxToRem(16) pxToRem(9);
                                            }

                                            &.checkbox {
                                                background-color:   #fff;
                                                border:             1px solid #b0b2c9;
                                                border-radius:      3px;
                                                display:            inline-block;
                                                height:             18px;
                                                margin-right:       14px;
                                                position:           relative;
                                                width:              18px;
                                                flex-shrink:        0;
                                                flex-grow:          0;
                                                flex-basis:         18px;

                                                &:after, &:before {
                                                    background: $white;
                                                    content:    "";
                                                    position:   absolute;
                                                    width:      0;
                                                    height:     1.5px;
                                                }

                                                &:before {
                                                    transform:          translate(3px, 7px) rotate(45deg);
                                                    transition:         all .15s;
                                                    transform-origin:   left;
                                                    transition-delay:   .05s;
                                                }

                                                &:after {
                                                    transform:          translate(6px, 11px) rotate(-45deg);
                                                    transition:         all .15s;
                                                    transform-origin:   left;
                                                    transition-delay:   0ms;
                                                }
                                            }
                                        }
                                    }
                                }

                                .filter-field__expand-select {
                                    z-index:        6;
                                    border-left:    pxToRem(5.59) solid transparent;
                                    border-right:   pxToRem(5.59) solid transparent;
                                    border-bottom:  pxToRem(5.59) solid #323232;
                                    border-top:     0;
                                }

                                .filter-field__dropdown-list, .filter-field__dropdown-list--checkboxes {
                                    position:         absolute;
                                    z-index:          6;
                                    width:            100%;
                                    margin-top:       -3px;
                                    padding:          0;
                                    background-color: $white;
                                    overflow-y:       auto;
                                    max-height:       pxToRem(250);
                                    border:           solid 1px #9D64A0;
                                    border-top-color: #DEDEDE;
                                    border-radius:    0 0 3px 3px;
                                    transition:       max-height .4s;

                                    * {
                                        visibility: visible;
                                    }

                                    & >  li {
                                        display:        flex;
                                        list-style:     none;
                                        line-height:    pxToRem(20);
                                        color:          #6F6F6F;
                                        padding:        pxToRem(14) pxToRem(9) pxToRem(16) pxToRem(9);
                                        transition:     background-color .1s;

                                        input[type="checkbox"] {
                                            position:   static;
                                            box-shadow: none;
                                        }

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }

                                        &.selected {
                                            text-decoration:    underline;
                                            color:              $planning;
                                            cursor:             none;
                                            pointer-events:     none;
                                        }

                                        &:hover,
                                        &:focus {
                                            background-color: #ECECEC;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .filter-field__dropdown-list--checkboxes {

                                    & > li:first-of-type {
                                        font-size: pxToRem(10);
                                        color: #6F6F6F;
                                        line-height: pxToRem(14);
                                        font-weight: 500;
                                        border-bottom: 1px solid #DEDEDE;
                                        padding: pxToRem(4) pxToRem(10);

                                        button {
                                            background-color: transparent;
                                            border: 0;
                                        }
                                    }
                                }
                            }
                        }

                        &.filter-field__date-range-pick {
                            .filter-fields__wrap {
                                display: flex;
                                flex-wrap: wrap;

                                label {
                                    display:        block;
                                    font-size:      pxToRem(10);
                                    line-height:    pxToRem(15);
                                    letter-spacing: pxToRem(0.2);
                                    text-transform: uppercase;
                                    margin-bottom:  pxToRem(3);
                                    color:        #555457;
                                    font-weight:    600;
                                    flex-basis:     calc(50% - 4.5px);
                                    order:          0;
                                    flex-grow:      0;
                                    flex-shrink:    0;

                                    &:last-of-type {
                                        order: 1;
                                        margin-left:   pxToRem(9);
                                    }
                                }

                                input {
                                    flex-basis:     calc(50% - 4.5px);
                                    flex-grow:      0;
                                    flex-shrink:    1;
                                    order:          2;
                                    padding:        pxToRem(14) pxToRem(9) pxToRem(16) pxToRem(29);
                                    max-width:      48%;

                                    background-image:       url(mysource_files/calendar-2018.svg);
                                    background-repeat:      no-repeat;
                                    background-position-x:  10px;
                                    background-position-y:  16px;
                                    background-size:        14px 15px;

                                    &.has-value {
                                        background-image:       url(mysource_files/calendar-2018.svg), url(mysource_files/cancel.svg);
                                        background-repeat:      no-repeat;
                                        background-position-x:  10px, calc(100% - 10px);
                                        background-position-y:  16px;
                                        background-size:        14px 15px;
                                    }

                                    &:first-of-type {
                                        margin-right:   pxToRem(9);
                                    }

                                    &.picker__input--active {
                                        border: 1px solid #9D64A0;
                                    }
                                }

                                span {
                                    margin-right: pxToRem(8);
                                    margin-left:  pxToRem(2);
                                }
                            }
                        }
                    }
                }
            }
        }

        .search-filters__footer-wrap {
            bottom:   0;
            .search__group {
                text-align: center;
                .reset-search-facets  {
                    text-decoration: none;
                }
                button,
                .reset-search-facets {
                    border:           none;
                    padding:          0;
                    color:            $white;
                    width:            100%;
                    background-color: $planning;
                    padding:          pxToRem(18) pxToRem(0) pxToRem(20) pxToRem(0);

                    &:hover,
                    &:focus {
                        outline: none;
                    }
                }

                button[type=submit] {
                    &:hover,
                    &:focus {
                        background-color: $corporate-services;
                    }
                }

                button[type=reset],
                .reset-search-facets--mobile,
                .planning-scheme-reset-search-facets--mobile {
                    display: none;
                }
            }
        }
    }
}

.search-results {
    border-bottom: pxToRem(1) solid $teal;
    padding: pxToRem(20) pxToRem(0) pxToRem(20) pxToRem(0);
    position: relative;
    width: 100%;
    z-index: 1;

    > .container-fluid {
        max-width:      pxToRem(1232);
    }

    .results-loading__overlay {
        display: none;
    }

    &.loading {
        width:  100%;
        height: 100%;
        background-color: $planning;
        min-height:       pxToRem(402);

        .container-fluid {
            display: none;
        }
    }

    .search-results-bar {
        width: 100%;
        color: $corporate-services;

        .search-results__summary {
            margin-top:   pxToRem(-7);
            padding-left: pxToRem(2);
            p {
                color: $corporate-services;
                margin-top: 0;
                font-size:  pxToRem(16);

                .summary--highlight {
                    font-weight: 600;
                }
            }
        }

        .search-results__func-wrap {
            position: relative;
            padding:  0 pxToRem(2);
            .search-results__sort {
                display: inline-block;
                cursor:  pointer;
            }

            .search-results__view-switch {
                display: none;
            }

            .search-results__sort {
                margin-top:  pxToRem(-2);
                width:       100%;
                text-align:  center;
                padding:     pxToRem(10) 0 pxToRem(9) pxToRem(6);
                line-height: pxToRem(19);
                border:      pxToRem(1) solid $corporate-services;
                &.expanded {
                    position: relative;

                    .sort-options__list {
                        display:          block;
                        position:         absolute;
                        z-index:          6;
                        top:              pxToRem(-1);
                        left:             pxToRem(-1);
                        background-color: $white;
                        box-shadow:       0 0 3px 0 rgba(32, 21, 71, 0.5);
                        width:            calc(100% +  2px);
                        margin:           0;
                        padding:          pxToRem(17) 0 pxToRem(25) 0;

                        li {
                            list-style: none;
                            padding:    pxToRem(10) pxToRem(23) pxToRem(4) pxToRem(31);


                            a {
                                text-decoration: none;
                                font-size:  pxToRem(16);
                                color:      $silver1;
                            }

                            &.option-selected {
                                a {
                                    color:  $corporate-services;
                                }
                            }

                            &:hover,
                            &:active {
                                cursor: pointer;
                                a {
                                    color:  $planning;
                                }
                            }
                        }
                    }
                }

                .sort-option--selected {
                    text-transform: uppercase;
                    padding-right:  20px;
                    position:       relative;
                    font-size:      pxToRem(14);
                    font-weight:    500;
                    line-height:    pxToRem(19);

                    &:after {
                        content:      "";
                        position:     absolute;
                        width:        0;
                        height:       0;
                        border-style: solid;
                        border-width: pxToRem(7) pxToRem(5) pxToRem(0) pxToRem(5);
                        border-color: $corporate-services transparent transparent transparent;
                        top:          50%;
                        right:        pxToRem(6);
                        transform:    translateY(-50%);
                    }
                }

                .sort-options__list {
                    display: none;
                }
            }

            .search-results__view-switch {
                position: absolute;
                right:    0;

                button {
                    font-size:        pxToRem(13);
                    text-align:       center;
                    line-height:      pxToRem(18);
                    border:           pxToRem(1) solid $silver1;
                    border-radius:    pxToRem(40);
                    background-color: rgba($silver1, 0.15);
                    padding:          pxToRem(6) pxToRem(19) pxToRem(6) pxToRem(22);
                    position:         relative;

                    & > svg {
                        margin-right: pxToRem(5);
                    }

                    &.view-switch__cards,
                    &.view-switch__table {
                        color: rgba($corporate-services, 0.6);
                        &:hover,
                        &:focus {
                            color: $corporate-services;
                            & > svg {
                                fill: $corporate-services;
                            }
                        }

                        &.selected {
                            background-color: $white;
                            color:            $corporate-services;
                            box-shadow:       0 0 5px 0 $gray92;
                            z-index:          5;

                            & > svg {
                                fill: $corporate-services;
                            }
                        }
                    }

                    &.view-switch__cards {
                        padding: pxToRem(6) pxToRem(25) pxToRem(4) pxToRem(17);
                        right:   pxToRem(-22);
                        & > svg {
                            fill: rgba($mine-shaft, 0.5);
                        }
                        &.selected {
                            padding-right:    pxToRem(19);
                            padding-bottom:   pxToRem(6);
                            margin-left:      pxToRem(3);
                            right:            pxToRem(-25);
                        }
                    }

                    &.view-switch__table {
                        padding: pxToRem(6) pxToRem(19) pxToRem(6) pxToRem(25);
                        & > svg {
                            fill: $grey;
                        }
                        &.selected {
                            padding-left:     pxToRem(17);
                        }
                    }

                    &:hover,
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

.table-view__header .result-row__cell.sortable {
    cursor: pointer;
    position: relative;

    &:after {
        border-bottom-color: transparent;
        border-left-color:   transparent;
        border-right-color: transparent;
        border-style:        solid;
        border-width:        0.4375rem 0.3125rem 0rem 0.3125rem;
        content:             '';
        display:             block;
        height:              0;
        position:            absolute;
        right:               0;
        top:                 50%;
        transform:           translateY(-50%);
        width:               0;
    }

    &.descending:after {
        transform:  translateY(-50%) rotate(180deg);
    }

    &.sort {
        background-color: mix($dark-blue, $white, 79%);
        color:            $white;
    }

}

//date picker
.picker {
    display: none;
}

.picker.picker--opened {
    display:    block;
    width:      auto;
    height:     auto;
    overflow:   hidden;
    position:   absolute;
    top:        75px;
    left:       pxToRem(0);
    // left:       pxToRem(-200);
    z-index:    32;
    box-shadow: 0 0 3px 1px rgba(32, 21, 71, 0.5);

    .picker__holder {

        .picker__box {

            .picker__header {
                margin:           0;
                background-color: #855487;
                font-size:        pxToRem(14);
                font-weight:      500;
                line-height:      pxToRem(19);
                text-align:       center;
                position:         relative;
                display:          flex;
                align-items: center;

                .picker__month,
                .picker__year {
                    display: inline-block;
                    color:   $white;
                }

                .picker__select--year {
                    -moz-appearance:    none;
                    -webkit-appearance: none;
                    appearance:         none;
                    background-color: transparent;
                    border: none;
                    color:   $white;
                    display: inline-block;
                    font-weight: 500;
                    font-size: pxToRem(14);
                    margin-right: pxToRem(0);
                    width: pxToRem(55);
                    max-width: pxToRem(55);

                    &::-ms-value {
                        background: transparent;
                        color: $white;
                    }

                    &::-ms-expand {
                        display: none;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus {
                        outline: none;
                    }

                    option {
                        color: $planning;
                        font-weight: normal!important;
                    }
                }

                .picker__month {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 100px;
                    order: 2;
                    align-self: center;
                    line-height: pxToRem(19);
                }

                .picker__nav--prev,
                .picker__nav--next {
                    width:           pxToRem(15);
                    height:          pxToRem(15);
                    top:             32%;
                    background:      url('{{file_dest}}/chevron.svg') no-repeat center center transparent;
                    background-size: contain;
                    flex-shrink: 1;
                    flex-grow: 0;
                    margin: 0 16px;

                    &:hover,
                    &:focus {
                        cursor: pointer;
                    }
                }

                .picker__nav--prev {
                    order: 1;
                    left: 17px;
                    transform:         rotate(90deg);
                }

                .picker__nav--next {
                    order: 3;
                    right: 30px;
                    transform:         rotate(270deg);
                }
            }

            .table-responsive {
                margin: 0;
            }

            table {
                margin: 0;
                background-color: $white;
                table-layout: auto;

                thead {
                    border-bottom: 1px solid #DEDEDE;
                }

                th,
                td {
                    border:           none;
                    background-color: $white;
                    color:            $planning;
                    font-size:        pxToRem(13);
                    font-weight:      600;
                    text-transform:   uppercase;
                    line-height:      pxToRem(13.9);
                }

                th {

                    &.picker__weekday {
                        height:  pxToRem(32);
                        padding: pxToRem(11) pxToRem(7.5) pxToRem(7.14) pxToRem(15);
                        color: #201547;
                    }
                }

                td {
                    font-weight: normal;
                    padding: 0;

                    .picker__day {
                        height:  pxToRem(32);
                        padding: pxToRem(9) pxToRem(8) pxToRem(9) pxToRem(7.6);
                        margin:  0;
                        color: #323232;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .picker__day--outfocus {
                        color:          #C8C8C8;
                        cursor:         none;
                        pointer-events: none;
                    }

                    .picker__day--infocus {
                        cursor: pointer;
                        &:hover,
                        &:focus {
                            background-color: $wild-sand;
                        }
                    }

                    .picker__day--selected,
                    .picker__day--highlighted {
                        background-color: $corporate-services;
                        color:            $white;

                        &:hover {
                            background-color: $corporate-services;
                            color:            $white;
                        }
                    }
                }
            }

            .picker__footer {
                display: none;
            }
        }
    }
}
// amendments search-tabs template
.amendments.search-tabs {
    background: #211547;
    color: white;
}
.amendments {
    .search-tabs {
        &__selected-tab {
            width: 100%;
            padding: 20px 0;
            position: relative;
            font-size: 18px;
            cursor: pointer;
            &:before {
                content: "";
                display: block;
                background: url(mysource_files/chevron.svg) no-repeat center center;
                width: 14px;
                height: 10px;
                position: absolute;
                top: calc(50% - 5px);
                right: 10px;
                background-size: cover;
            }
            &.active:before {
                transform: rotate(180deg);
            }
        }
        &-wrap {
            list-style: none;
            padding: 0;
            margin: 5px 0;
            width: 100%;
            &.mobile-hidden {
                display: none;
            }
        }
        &__item {
            a {
                display: block;
                padding: 10px 0;
                font-size: 16px;
                color: white;
                text-decoration: none;
            }
            &.active a {
                font-weight: 600;
            }
            &:hover,
            &:focus {
                a {
                    font-weight: 600;
                }
            }
        }
    }
}