/* Module: S */
.social {
    overflow: hidden;
    position: relative;
}

.social-title {
    margin-bottom: 0.625rem;
    padding-left: 1rem;
    text-align: left;
    font-size: 1.75rem;
}

.social-carousel {
    max-width: 75rem;
    margin: 0 auto;
}

.social-carousel__item {
    padding: 1rem;
    padding-top: 0;
}

.social-carousel__item:first-of-type {
    padding-left: 0;
}

.social-slide {
    border: 1px solid $slide-border;
    color: $black;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: #f2f2f2; 

        .social-slide__image::before {
            height: 100%;
            opacity: .5;
        }
    }

    
}

.social-slide__content {
    flex: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-wrap: break-word;
}

.social-slide__image {
    background-image: url('http://placehold.it/380x178');
    background-position: center center;
    background-size: cover;
    height: 178px;
    position: relative;
    width: 100%;

    &::before {
        @include sq-transition(all .35s ease);
        bottom:   0;
        content:  '';
        height:   0;
        left:     0;
        opacity:  0;
        position: absolute;
        right:    0;
        background-color: #fff;
    }
}

.social-slide__text {
    margin: 0;
}

.social-slide__profile {
    display: flex;
    padding-top: 23px;
}

.social-slide__profile-icon {
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    height: 2.25rem;
    display: inline-block;
    width: 2.25rem;
}

.social-slide__profile-icon--tw {
    background-image: url('mysource_files/twitter.svg');
}

.social-slide__profile-icon--fb {
    background-image: url('mysource_files/facebook.svg');
}

.social-slide__profile-icon--ig {
    background-image: url('mysource_files/instagram.svg');
}

.social-slide__profile-name {
    line-height: 2.25rem;
    padding-left: 16px;
}

//slider configuration
.social-carousel .slick-track {
    display: flex;
    width: auto !important;
}

.social-carousel .slick-slide {
    height: auto;
}

//custom slider dots
.social-carousel .slick-dots {
    list-style: none;
    text-align: center;
}

.social-carousel .slick-dots>li {
    cursor: pointer;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: $slide-border;
    border-radius: 5px;
}

.social-carousel .slick-dots>li.slick-active {
    background-color: $teal;
}

.social-carousel .slick-dots li button {
    display: none;
}