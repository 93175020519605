/* Module: S */
.searchable-grid__item {
    margin-bottom: pxToRem(16);
    min-height: pxToRem(300);

    &:last-child:not(:nth-child(2n+2)) {
        // -ms-flex-preferred-size: 100%;
        // flex-basis: 100%;
        // max-width: 100%;

        .featured-image__content-item--large .featured-image__content-item--link::before {
            max-height: pxToRem(390);
            width: 50%;
        }

        .featured-image__content-item--wrapper {
            max-width: 50%;
        }
    }

    .featured-image__content-item--title {
        font-size: pxToRem(26);
    }

    .featured-image__content-item--label {
        font-size: pxToRem(18);
    }
}

.searchable-featured-grid {
    &.search__form-filbtn-wrap .search__form-wrap {
        align-self: center;
        margin-right: 0;
        padding: 0;
    }

    .search__filter-btn-wrap {
        @include hideContent;
    }

    .search-filters {
        display: block;
        flex-basis: pxToRem(300);
        margin-right: -.5rem;
        max-width: pxToRem(300);

        form {
            margin: 0;
            width: 100%;
        }

        .search-filters__header-wrap,
        .search-filters__footer-wrap {
            @include hideContent;
        }

        .search-filters__filter-tab .search__fields-wrap {
            padding: 0;
        }

        .search-filters__filter-tab .search__fields-wrap .filter-field__wrap {
            padding: 0;
        }
    }

    .filter-tabs__wrap {
        margin: 0 -.5rem;
    }
}

@media screen and (max-width: 1332px) and (min-width: 64em) {
    .searchable-featured-grid.search__form-filbtn-wrap>.container-fluid {
        margin: 0 auto;
    }
}