/* Module: S */
.block-tile.trending {
    padding: 1.5rem 0;
    position: relative;
    z-index: 1;
}

.trending-content .block-tile__item--wrap {
    padding: 0.375rem;
}

.trending-content .block-tile__item {
    margin-bottom: 0;
}

.trending-content .block-tile__item-content--label {
    font-size: 1rem;
}

.trending-content__link {
    background-color: $white;
    &:hover {
        background-color: #f2f2f2;
    }
}

.trending-content__link .block-tile__item--img::after {
    background-color: #fff;
}

.trending-content__more {
    text-align: center;
    padding: 1.5rem 0;
}

.trending-tabs {
    display: flex;
    justify-content: center;
}

.trending-tabs__header {
    color: $dark-grey;
    font-size: 1.375rem;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0;
    margin-bottom: 1rem;
}

.trending-tabs__header--active {
    border-bottom: 3px solid $teal;
    color: $corporate-services;
}

.trending-content__button {
    display: inline-block;
    text-decoration: none;
    padding: 10px 50px;
    border: 1px solid $corporate-services;
    color: $corporate-services;
    &:hover {
        text-decoration: underline;
    }
}

.trending-content .block-tile__item-content--icon,
.trending-content .block-tile__item-content--description p,
.trending-content .block-tile__item-content--label {
    color: $corporate-services;
}

.trending-content .block-tile__item-content--icon {
    background-image: url('mysource_files/arrow--cor-sort.svg');
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    height: 1.0375rem;
    width: 1.5625rem;
}

.block-tile__item-content--tag {
    font-weight: 500;
    color: $corporate-services;
}