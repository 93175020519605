/* Module: S */
.inside {

    .main {
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::before {
            border-color: $teal transparent transparent;
            border-style: solid;
            border-width: pxToRem(420) pxToRem(195) 0;
            content:      '';
            display:      none;
            right:        pxToRem(-195);
            position:     absolute;
            top:          0;
            width:        0;
            z-index:      9;
        }

        &::after {
            background: url('{{file_dest}}/body-triangles.svg') no-repeat center center transparent;
            content:    '';
            display:    none;
            height:     pxToRem(277);
            position:   absolute;
            right:      0;
            top:        pxToRem(800);
            width:      pxToRem(168);
            z-index:    9;
        }

        > .container-fluid {
            max-width:      pxToRem(1232);
            padding-bottom: pxToRem(30);
            padding-top:    pxToRem(50);
        }

        .lhs-nav-wrapper {
            display: none;
        }

        .main-content-wrapper {
            position: relative;
            z-index:  11;
        }

        &.col-2 {

            &::before {
                left:  pxToRem(-195);
                right: 0;
            }

            &::after {
                @include sq-transform(scaleX(-1));
                left:  0;
                right: auto;
            }
        }
    }
    .main.minimal,
    .main.reduced {
        &::before,
        &::after {
            content: none;
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management.inside {

    .main {

        &::before {
            border: 0 none;
        }

        &::after {
            background-image:    url('{{file_dest}}/bg-main-checker.svg');
            background-position: right top;
            height:              1142px;
            top:                 0;
            width:               468px;
        }

    }

}

// Theme: Fishermans Bend
.fishermans-bend.inside {
    .main {
        &::before {
            border-color: $fishermans-bend transparent transparent;
        }
        &::after {
            background: url('{{file_dest}}/body-triangles--fishermans.svg') no-repeat center center transparent;
            
        }
    }
}
