/* Module: Accordion */
// 1. ACCORDION
.accordion {

    &__header {
        font-size:   pxToRem(24);
    }

    .accordion__link {
        font-size: pxToRem(16);
    }

    .accordion__target {
        font-size: pxToRem(16);
    }
}

// 2. TABS
.tabs {

    .slick-arrow {
        width: pxToRem(58);

        &::after {
            background:      url('{{file_dest}}/chevron--teal.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            height:          pxToRem(6);
            position:        absolute;
            top:             50%;
            width:           pxToRem(12);
        }
    }

    .slick-next {

        &::after {
            @include sq-transform(rotate(270deg) translateX(25%));
            right: 0;
        }
    }

    .slick-prev {

        &::after {
            @include sq-transform(rotate(270deg) translateX(25%));
            right: 0;
        }
    }

    .tabs__link {
        font-size: pxToRem(16);
        margin:    0 pxToRem(30) 0 0;
        padding:   pxToRem(18) 0;
    }

    .tabs__target {
        padding-top: pxToRem(40);

        p {
            font-size: pxToRem(16);
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management {

    .tabs {

        .slick-arrow::after {
            background-image: url('{{file_dest}}/chevron--jungle-green.svg');
        }

    }

}
