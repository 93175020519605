/* Module: S */
.landing .header-banner.campaign,
.inside .header-banner.campaign {
    height:     pxToRem(730);
    max-height: pxToRem(730);

    .banner {

        &__text-wrapper {
            margin-left: pxToRem(25);
        }

        &__heading {
            font-size: pxToRem(50);
        }

        &__subheading {
            font-size: pxToRem(18);
        }

    }

}
