@include sq-keyframes(square-spin) {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }
    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }
    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0);
    }
}
