/* Module: S */
//1. Filters
.grants__filter {

    &--group {

        .container-fluid {
            height:    100%;
            max-width: 77rem;
            position:  relative;
        }
    
        .search__filter {

            &--theme-list,
            &--category-list,
            &--status-list {

                &.active {
                    position:         absolute;
                    width:            100%;
                    background-color: #f2f2f2;
                }

            }
        }

    }

}

.grant {

    &__heading {
        color:           $navy;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

    }

    &__counter {
        color:       $grey;
        font-size:   pxToRem(14);
        line-height: 2;
    }

    &__items-list {
        @include sq-list-reset;
    }

    &__item {
        color:   $corporate-services;
        display: block;
        margin:  0 0 pxToRem(24);

        .grant__img-wrapper {
            background-position: center center;
            background-repeat:   no-repeat;
            background-size:     cover;

            a {
                height: 100%;
            }

            img {
                display: block;
            }
        }

        .grant__data-wrapper {
            border:        pxToRem(1) solid lighten($grey, 29%);
            border-bottom: 0;
            padding:       pxToRem(24) pxToRem(24) 0;
            

            .grant__heading {
                display:       block;
                font-size:     pxToRem(24);
                font-weight:   600;
                margin-bottom: pxToRem(10);
            }

            .grant__category-wrapper {
                display: block;

                span {
                    font-weight: 500;

                    &::after {
                        content: '|';
                        margin:  0 pxToRem(6);
                    }

                    &:last-child::after {
                        content:      '';
                        margin-right: 0;
                    }

                }
            }

            .grant__description {
                color: $grey;
            }
        }

        .grant__terms-wrapper {
            font-size: pxToRem(14);
            margin:    0;

            .grant__term {
                margin: pxToRem(8) 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            dt,
            dd {
                display:        inline-block;
                margin:         0;
                text-transform: capitalize;
            }

            dt {
                font-weight: 500;
                padding:     0 pxToRem(3) 0 0;

                &::after {
                    content: ':';
                }
            }
        }

        .grant__cta-wrapper {
            border:     pxToRem(1) solid lighten($grey, 29%);
            border-top: 0;
            font-size:  pxToRem(14);
            padding:    pxToRem(24);

            .grant__cta {
                @include sq-transition(background-color .35s ease);
                background-color: $corporate-services;
                color:            $white;
                display:          block;
                padding:          pxToRem(10) pxToRem(16) pxToRem(11);
                text-align:       center;
                text-decoration:  none;

                &:hover,
                &:focus {
                    background-color: rgba($corporate-services, .9);
                }

                + .grant__cta {
                    margin-top: pxToRem(15);
                }
            }

            .grant__cta--teal {
                background-color: $teal;

                &:hover,
                &:focus {
                    background-color: $teal-light;
                }
            }
        }
    }

}
