/* Module: S */
// Footer styling
.ripple-footer {
  background-color: $land-vic-purple;
  overflow-x: hidden;
  padding: pxToRem(15) pxToRem(20);
  position: relative;
  z-index: 1;

  &,
  a {
    color: $white;
  }

  &::before {
    background-image: url("{{file_dest}}/ripple-footer-graphic--dark.png");
    background-position-x: 100%;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: pxToRem(400);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  a {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  & > .container-fluid {
    max-width: pxToRem(1232);
    position: relative;
    z-index: 1;
  }

  &__menu {
    list-style: none;
    margin-top: pxToRem(16);
    padding: 0;

    &-item--link {
      .ripple-footer__heading {
        border-bottom: 1px solid mix($white, $land-vic-purple, 20%);
        cursor: default;
        padding-bottom: pxToRem(15);

        &:focus {
          outline: none;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  &__heading,
  &__nav {
    border-bottom-color: mix($white, $land-vic-purple, 20%);
  }

  &__heading {
    color: $white;
    cursor: pointer;
    font-size: pxToRem(16);
    line-height: 1;
    margin: 0 0 1rem;
    position: relative;

    &::after {
      background-image: url("{{file_dest}}/chevron-up--white.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 15px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: rotate(180deg) translateY(calc(-50% + 15px));
      width: 8px;
    }

    &--active {
      &::after {
        transform: rotate(0deg) translateY(-50%);
      }
    }

    .ripple-footer__link {
      font-size: pxToRem(16);
      font-weight: $font-weight-bold;
      vertical-align: middle;
    }
  }

  &__link {
    font-size: pxToRem(14);
    font-weight: 400;

    &--bottom {
      font-size: pxToRem(12);
    }

    &--icon {
      align-items: center;
      display: -ms-grid;
      display: grid;
      grid-column-gap: pxToRem(12);
      -ms-grid-columns: pxToRem(20) pxToRem(12) 1fr;
      grid-template-columns: pxToRem(20) 1fr;
    }

    &-icon,
    &-text {
      display: block;
      -ms-grid-row-align: center;
    }

    &-icon-image {
      display: block;
    }

    &-text {
      -ms-grid-column: 3;
    }
  }

  &__nav {
    @include sq-list-reset;
    margin-bottom: pxToRem(15);
    width: 100%;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);

    &--active {
      .ripple-footer__nav-item {
        animation: fadeInMobileFooterLink 0.5s forwards;
        display: block;
      }
    }

    &--bottom {
      border-bottom: none;
      margin-bottom: 0;
    }

    &-item {
      display: none;
      font-size: pxToRem(14);
      line-height: 1;
      margin-bottom: 1rem;
      position: relative;

      &--bottom {
        border-left: 1px solid $white;
        display: inline-block;
        font-size: pxToRem(12);
        line-height: 1;
        margin-bottom: pxToRem(0);
        padding: 0 pxToRem(10);

        &:last-child {
          padding-right: 0;

          &::after {
            content: none;
          }
        }

        &:first-child {
          border-left: none;
          padding-left: 0;
        }
      }
    }
  }

  &__acknowledgement {
    margin-bottom: pxToRem(35);
  }

  &__flag {
    border: 1px solid $white;
    height: auto;
    flex-shrink: 0;
    margin: 0 pxToRem(10) pxToRem(15) 0;
    width: pxToRem(53);
  }

  &__text {
    color: $white;
    font-weight: $font-weight-semi-bold;
    line-height: 1.25em;
    margin: 0;

    & > *:first-child {
      margin-top: 0;
    }

    &--caption,
    &--copyright {
      font-size: pxToRem(12);
      font-weight: $font-weight-normal;
      margin-top: pxToRem(5);
      margin-bottom: pxToRem(15);
    }

    &--caption {
      font-weight: $font-weight-semi-bold;
      margin: pxToRem(35) 0 pxToRem(15);
    }
  }

  &__bottom {
    padding-top: pxToRem(15);
    position: relative;

    &::before {
      background-color: mix($white, $land-vic-purple, 20%);
      content: "";
      display: block;
      height: 1px;
      left: calc(100vw * -1);
      position: absolute;
      top: 0;
      width: 200vw;
    }

    &-logos {
      display: flex;
      justify-content: center;

      a {
        align-items: center;
        display: inline-flex;
        margin-right: pxToRem(5);
      }
    }
  }

  &__logo {
    display: block;
    max-height: pxToRem(52);
  }
}

// Footer animation
@keyframes fadeInMobileFooterLink {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
