/* Module: S */
.social-showcase {
    padding: pxToRem(80) 0;
     .col-xs-12.col-sm-6.col-md-4 {
        display: block;
    }

    .social-showcase__heading {
        font-size: pxToRem(40);
        margin:    0 0 pxToRem(40);
    }

    .social-showcase__item {
        font-size: pxToRem(16);
    }
}
