/* Module: S */
.active-video-banner {
	min-height: 550px;
	
	&__text-wrapper {
    	h1 {
    		font-size: pxToRem(60);
    	}
    	h2 {
			font-size: pxToRem(20);
    	}
    }
}