/* Module: S */
.news-articles {
	&__content {

		p,
        ul,
        ol {
			font-size: pxToRem(16);
		}
	}
}
.block-title--news_articles {
	.block-tile__item {
		&--img {
			flex: none;

			&::before {
			content: '';
			}
			img {
			    height:     auto;
			    margin:     0 auto;
			    object-fit: cover;
			    top:        50%;
			    transform:  translate(0, -50%);
			}
		}
		&-content {
		    // flex: 1 0;
			flex-grow: 1;
			flex-shrink: 0;

			&--description {
				display: block;
			}
			&--link {
				display: block;
			}
		}
	}
}