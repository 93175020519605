/* Module: S */
.block-quote {
    border-bottom: pxToRem(1) solid rgba($corporate-services, .31);
    color:         $corporate-services;
    padding:       pxToRem(40) 0;

    &.padding_top--half {
        padding-top: pxToRem(20);
    }
    &.padding_bottom--half {
        padding-bottom: pxToRem(20);
    }
    &.padding_top--no {
        padding-top: 0;
    }
    &.padding_bottom--no {
        padding-bottom: 0;
    }

    .block-quote__image {
        margin-bottom: pxToRem(20);
    }

    .block-quote__quote {
        margin:   0;
        padding:  0;

        &::before,
        &::after {
            display: none;
        }

        h5 {
            margin:     0 0 pxToRem(20);
            text-align: left;
        }
    }

    .block-quote__author {
        text-align: left;

        &::before {
            background: url('{{file_dest}}/triangle.svg') no-repeat center center transparent ;
            content:      '';
            display:      inline-block;
            height:       pxToRem(14.8);
            margin-right: pxToRem(16);
            width:        pxToRem(14);
        }

        &-name, &-title {
            font-size:   pxToRem(14);
            font-weight: 500;
        }
    }

}
