/* Module: S */
.featured-image {

    &--large {
        padding: pxToRem(75) 0 pxToRem(100);

		&.padding_top--half {
			padding-top: pxToRem(37.5);
		}

		&.padding_bottom--half {
			padding-bottom: pxToRem(50);
		}
    }

    &--small {
        padding: pxToRem(46) 0 pxToRem(80);

		&.padding_top--half {
			padding-top: pxToRem(23);
		}

		&.padding_bottom--half {
			padding-bottom: pxToRem(40);
		}

        .featured-image__content-item--purple-dark {
        	.featured-image__content-item--link::after {
				background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
			}
        }
    }

    &--header {
        font-size: pxToRem(40);
    }
	&__content.col-xs-12.col-sm-6 {
		padding: 0;
	}
	&__content {

		.row {
			margin: 0;

			.col-xs-12 {
		    	padding: pxToRem(6);
		    }
		}

		&-item {
			height: 234px;
			
			&--purple-light,
			&--teal {
				.featured-image__content-item--link {
					&::after {
						background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						height:     pxToRem(17);
						width:      pxToRem(36);
					}
				}
			}
			&--purple-dark,
			&--colour,
			&--planning, 
			&--corporate-services {
				.featured-image__content-item--link {
					&::after {
						background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						height:     pxToRem(17);
						width:      pxToRem(36);
					}
				}
			}
			&--link {
				padding: pxToRem(30);
			}
			&--title {
				font-size:   pxToRem(20);
			}

			&--label {
				font-size:    pxToRem(16);
			}
			&--large {
				height:       480px;

				.featured-image__content-item {
					&--link {
						&::before {
							border-top:       31rem solid;
							border-top-color: inherit;
							border-right:     17rem solid transparent;
							width: calc(100% - 60px);
						}

						&:hover {
							&::before {
								width: 1000px;
							}
						}

						&::after {
							height:     pxToRem(17);
							right:      340px;
							width:      pxToRem(36);
						}
					}
					
					&--title {
						font-size:   pxToRem(20);
						width:       282px;
					}
				}
				&.featured-image__content-item--teal {
					.featured-image__content-item--link::after {
						background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
					}
				}
				&.featured-image__content-item--corporate-services {
					.featured-image__content-item--link::after {
						background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
					}
				}
			}
		}
	}
}
.environment,
.climate-change,
.forest-fire,
.water-and-catchments {
	.featured-image {
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						}
					}
				}
				&--teal {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						}
		            }
				}
				&--purple-dark {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
		            }
				}
			}
		}
		&--small {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						}
					}
				}
				&--purple-light {

					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						}
		            }
				}
				&--purple-dark {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
		            }
				}
				&--colour {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						}
		            }
				}
			}
		}
	}
}
.water-and-catchments {
	.featured-image--small {
		.featured-image__content-item--purple-light {
			.featured-image__content-item--link {
				&::after {
					background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
				}
            }
		}
	}
}
.planning,
.energy,
.local-infrastructure {
	.featured-image {
		&--large {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
					}
				}
				&--teal {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
		            }
				}
				&--purple-dark {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
		            }
				}
			}
		}
		&--small {
			.featured-image__content-item {
				&--large.featured-image__content-item--teal {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
					}
				}
				&--purple-light {

					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
						}
		            }
				}
				&--purple-dark {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
		            }
				}
				&--colour {
					.featured-image__content-item--link {
						&::after {
							background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
						}
		            }
				}
			}
		}
	}
}
// Theme: Fishermans Bend
.fishermans-bend {
	.featured-image {
		&--large {
			.featured-image__content-item--large.featured-image__content-item--teal {
				.featured-image__content-item--link::after {
					background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
				}
			}

			.featured-image__content-item--purple-light {
				.featured-image__content-item--link::after {
					background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
				}
			}
		}

		&--small {
			.featured-image__content-item--large.featured-image__content-item--teal {
				.featured-image__content-item--link::after {
					background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
				}	
			}

			.featured-image__content-item--purple-dark {
				.featured-image__content-item--link::after {
					background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
				}
			}
		}
	}
}