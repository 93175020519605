/* Module: S */
.featured-image__content {
	.col-xs-12 {
    	order: 0;
    	padding: pxToRem(4);
    }

    .col-xs-12.col-sm-6 {
    	order: 0;
    }

	&-item {
		height:        pxToRem(148);
		margin-bottom: 0;

		&--large {
			height: pxToRem(304);

			.featured-image__content-item {
				&--link {
					padding: pxToRem(25);

					&::before {
						width: calc(100% - 70px);
					}

					&::after {
						right: 250px;
					}

					&:hover {
						&::before {
							width: 700px;
						}
					}
				}
				&--title {
					font-size:   pxToRem(18);
					line-height:  1.5;
				}
			}
		}
		&--title {
			line-height:  1.5;
		}
	}
}