/* Module: S */
.two-topic-tiles {
    background-color: $medium-grey;
    padding:          pxToRem(58) 0;

    .container-fluid {
        max-width: 77rem;
    }
    .col-xs-12.col-sm-6 {
        display: flex;
        &:last-child {
            padding-top: pxToRem(20);
        }
    }

    &__item {
        display:        flex;
        flex-direction: column;
        padding:        pxToRem(35) pxToRem(45) pxToRem(35) pxToRem(35);
        position:       relative;
        width:          100%;

        &--corporate{
            background-color: $corporate-services;

            .two-topic-tiles__item {

                &--header h3,
                &--description,
                &-list,
                &-list-element--link {
                    color: $white;
                    &:focus,
                    &:hover {
                        text-decoration: none;
                    }
                    &::after {
                        background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
                    }
                }
                &-list-element::after {
                    background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
                }
            }
        }
        &--teal {
            background-color: $teal;

            .two-topic-tiles__item {
                &-list-element::after {
                    background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
                }

                &-list-element--link {
                    color: $corporate-services;
                    &:focus,
                    &:hover {
                        text-decoration: none;
                    }
                    &::after {
                        background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
                    }
                }
            }
        }
        &--header h3 {
            line-height:    1.13;
            letter-spacing: normal;
            margin:         pxToRem(10) 0;
        }
        &--description {
            flex:    1 1 auto;
            padding: pxToRem(20) 0 pxToRem(30) 0;
            p {
                font-size:      pxToRem(16);
                font-weight:    normal;
                font-style:     normal;
                font-stretch:   normal;
                line-height:    normal;
                letter-spacing: -0.3px;
                margin:         0;
            }
        }
        &-list {
            ul {
                display:         flex;
                flex-flow:       column wrap;
                justify-content: space-between;
                list-style:      none;
                margin:          0;
                padding:         0;
            }
            &-element {
                margin:        pxToRem(15) pxToRem(30) 0 0;
                position:      relative;

                &--link {
                    font-weight:    500;
                    font-style:     normal;
                    font-stretch:   normal;
                    line-height:    normal;
                    letter-spacing: -0.3px;
                    padding-right:  pxToRem(30);
                    position:       relative;

                    &::after {
                        content:   '';
                        height:    pxToRem(16);
                        position:  absolute;
                        right:     -20px;
                        top:       50%;
                        transform: translateY(-50%);
                        width:     pxToRem(25);
                    }
                    
                }
            }
        }
    }
}
