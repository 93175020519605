/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .media-search__element { ... }
// .media-search__element--modifier { ... }
// .media-search__sub-element { ... }

.article-row {
    border-top: pxToRem(1) solid #e0e0e0;
    margin: pxToRem(24) 0 0 0;

    & .container-fluid {
        padding:0 0.5rem 0 0.5rem;
    }

    & section {
        margin: pxToRem(20) 0 0.5rem 0!important;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.releated-links__container {
    color: $corporate-services;
}

.related-links__title {
    font-size: 1.25rem;
    margin: .5rem 0;
    color: $corporate-services;
}

.releated-links__document__container {
    width:100%;
}

.releated-links__document__container__row {
    display:block;
    margin: pxToRem(12) 0;

    & img {
        margin-right: pxToRem(20);
        min-width:16px;
        min-height:16px;
        vertical-align: middle;
    }

    & a {
        color: $corporate-services;
        text-decoration:none;
        margin:0;
        vertical-align:middle;

        & span {
            color: $from-border-grey;
            font-size: pxToRem(12);
        }
    }

    &:last-child {
        margin-bottom:0;
    }
}


// Search bar styles

.article-row {

    & .media-search {
    align-self: unset;
    padding:0 0.5rem 0 0.5rem;
    background-color:transparent;
    }
    
}