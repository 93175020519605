/* Module: S */
.ripple-footer {
  padding: pxToRem(35) pxToRem(68) pxToRem(20);

  &:before {
    content: '';
  }

  &__menu {
    display:               -ms-grid;
    display:               grid;
    grid-column-gap:       1rem;
    -ms-grid-columns:      1fr 1rem 1fr 1rem 1fr;
    grid-template-columns: repeat(3, 1fr);
    margin:                pxToRem(16) 0 pxToRem(45) 0;

    &:not(.ripple-footer__menu--static) .ripple-footer__menu-item {
      display: inline-block;
      width:   100%;
    }

    &-item {

      &:nth-child(2) {
        -ms-grid-column: 3;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
      }

      &--link .ripple-footer__heading {
        border:  0;
        padding: 0;
      }

    }

  }

  &__menu-item--link .ripple-footer__heading,
  &__heading {
    cursor:        default;
    display:       inline-block;
    margin-bottom: pxToRem(16);

    &::after {
      content: none;
    }

  }

  &__nav {
    border-bottom: none;
    margin-bottom: pxToRem(16);

    &--bottom {
      margin-bottom: 0;
    }

    &-item {
      display: block;

      &:last-child {
        margin-bottom: 0;
      }

      &--bottom {
        display:       inline-block;
        margin-bottom: 0;
      }

    }

    + .ripple-footer__heading {
      margin-top: pxToRem(32);
    }

  }

  &__bottom-links {
    float: left;
  }

  &__bottom-logos {
    float: right;
  }

}
