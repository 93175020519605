/* Module: S */
/*
 * Table of Contents
 *
 *  SEARCH TOOLS
 *  FILTERS
 *
 */




/*
--------------------
1. SEARCH TOOLS
--------------------
*/

.results--count {
    font-size: pxToRem(11);
    line-height: pxToRem(13);
    font-weight: 700;
}

.results--query {
    font-size: pxToRem(32);
    line-height: pxToRem(38);
    margin-bottom: pxToRem(10);
}

.results--types {
    font-size: pxToRem(17);
    line-height: pxToRem(20);
    margin-bottom: pxToRem(10)
}

.icon--tools {
    position: relative;
    margin-left: pxToRem(20);
    margin-top: pxToRem(7);

    .icon {

        font-size: pxToRem(20);
        margin-right: 13px;
        
        &:last-child {
            margin-right: 0;
        }
        
        &.selected {
            color: $grey;
        }
    }
}

/*
--------------------
2. FILTERS
--------------------
*/

.filters__group-h {
    padding-bottom: pxToRem(7);
    margin-bottom: pxToRem(6);
}

.filters__heading {
    font-size: pxToRem(19);
    line-height: pxToRem(23);
}

.facetLabel {
    margin-top: pxToRem(18);
    font-size: pxToRem(15);
    line-height: pxToRem(18);
}

.filters__more-button {
    font-size: pxToRem(13);
    line-height: pxToRem(14);
}

.filters__link {
    font-size: pxToRem(15);
}

.filters__count {
    font-size: pxToRem(15);
    line-height: pxToRem(18);  
}

