/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: .5em 0;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    background: $base-font-colour;
    color: invert($base-font-colour);
}

td {
    border: 1px solid $base-font-colour;
}

/* Buttons */
.button {
    @include sq-transition(background-color .3s ease);
    background-color: $base-font-colour;
    color: invert($base-font-colour);
    display: inline-block;
    border: 0;
    padding: .5em 1em;
    &,
    &:visited {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        background-color: darken($base-font-colour, 20%);
    }
}

.button--large {
    @include sq-font-size(1.5);
}

.button--small {
    @include sq-font-size(.8);
}

/* Quotes */
blockquote {
    position: relative;
    padding-left: 3em;
    margin: 2em 0;
    &:before {
        @include sq-font-size(5);
        color: $base-font-colour;
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
    }
}

.pull-quote {
    border-top: 1px solid $base-font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}
/* Typography */

h1 {
    font-size:    pxToRem(50);
    font-style:   normal;
    font-stretch: normal;
    font-weight:  500;
}

h2 {
    font-size:    pxToRem(35);
    font-style:   normal;
    font-stretch: normal;
    font-weight:  600;
}

h3 {
    font-size:    pxToRem(28);
    font-style:   normal;
    font-stretch: normal;
    font-weight:  600;
}

h4 {
    font-size:    pxToRem(20);
    font-style:   normal;
    font-stretch: normal;
    font-weight:  600;
}

h5 {
    font-size:    pxToRem(20);
    font-style:   normal;
    font-stretch: normal;
    font-weight:  normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $corporate-services;
}

// Theme: Forest Fire Management
.forest-fire-management {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $forest-fire-mgmt-neutral;
    }

}

// Theme: Revitalising Central Geelong
.revitalising-central-geelong {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $revitalising-central-geelong;
    } 
}

/* Others */

hr {
    border:        0;
    border-bottom: pxToRem(1) solid rgba($corporate-services, .31);
    margin:        pxToRem(10) 0;
}
