/* Module: S */
.publications__items-list {
    position: relative;
    z-index:  10;
}

.publications__item {
    border-bottom: pxToRem(1) solid lighten($grey, 29%);
    display:       flex;
    margin:        0;
    padding:       pxToRem(40) 0;

    .publications__img-wrapper {
        flex: 1;
        min-width: pxToRem(290);

        + .publications__data-wrapper {
            padding-left: pxToRem(32);
        }
    }

    .publications__data-wrapper {
        border:    0;
        flex-grow: 1;
        padding:   0 pxToRem(32) 0 0;
    }
}
