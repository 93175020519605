/* Module: S */
.block-nav {
    color:   $corporate-services;
    padding: pxToRem(42) 0 pxToRem(30);

    &.padding_top--half {
        padding-top: pxToRem(21);
    }
    &.padding_bottom--half {
        padding-bottom: pxToRem(15);
    }
     &.padding_top--no {
        padding-top: 0;
    }
    &.padding_bottom--no {
        padding-bottom: 0;
    }

    .container-fluid {
        max-width: pxToRem(1232);
    }

    .block-nav__list-wrapper {
        margin:   0 pxToRem(-16);
        position: relative;

        &::before {
            background: $corporate-services;
            bottom:     0;
            content:    '';
            height:     pxToRem(2);
            left:       0;
            opacity:    .1;
            position:   absolute;
            right:      0;
        }
    }

    .slick-arrow {
        @include sq-transition(all .35s ease);
        background:  linear-gradient(to right, rgba($white, .0), $white);
        border:      0;
        display:     block;
        height:      100%;
        outline:     transparent dotted thin;
        overflow:    hidden;
        padding:     0;
        position:    absolute;
        text-indent: 100%;
        top:         0;
        white-space: nowrap;
        width:       pxToRem(47);
        z-index:     3;

        &:focus {
            outline: none;
        }

    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        @include sq-transform(rotate(180deg));
        left: 0;
    }

    .block-nav__list {
        @include sq-list-reset();
        padding: 0 pxToRem(16);
    }

    .block-nav__link {
        color:           $corporate-services;
        display:         block;
        font-size:       pxToRem(14);
        margin:          0 pxToRem(30) 0 0;
        padding:         pxToRem(9) 0;
        position:        relative;
        text-decoration: none;

        &::after {
            @include sq-transition(all .35s ease);
            background: $corporate-services;
            bottom:     0;
            content:    '';
            display:    block;
            height:     pxToRem(2);
            left:       0;
            opacity:    0;
            position:   absolute;
            width:      100%;
        }

        &:hover,
        &:focus {
            
            outline: none;

            &::after {
                opacity: .3;
            }
        }

        &.uber-accordion__button-active {

            &::after {
                background: $teal;
                opacity:    1;
            }
        }
    }

    .block-nav__content-target {
        display: none;
    }

    .block-nav__content--left {
        padding: pxToRem(14) 0 pxToRem(12);
    }

    .uber-accordion__target-active {
        display: block;
    }

    .block-nav__content-header h3 {
        font-size:   pxToRem(20);
        font-weight: 600;
    }

    .block-nav__content-description p {
        color:         $grey;
        font-size:     pxToRem(14);
    }

    .block-nav__list-children {
        @include sq-list-reset();
    }

    .block-nav__item-sub {
        position: relative;

        &::after {
            @include sq-transition(all .35s ease);
            background: $corporate-services;
            bottom:     0;
            content:    '';
            display:    block;
            height:     pxToRem(2);
            left:       0;
            opacity:    .1;
            position:   absolute;
            right:      0;
        }

        &:hover,
        &:focus {
            
            outline: none;

            &::after {
                opacity: .3;
            }
        }
    }

    .block-nav__item-sub--link {
        color:           $corporate-services;
        display:         block;
        font-size:       pxToRem(16);
        font-weight:     500;
        line-height:     2.19;
        padding:         pxToRem(7) 0;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: none;
            opacity: .3;
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management .block-nav {

    .block-nav__link,
    .block-nav__item-sub--link {
        color: $forest-fire-mgmt-neutral;

        &:hover,
        &:focus {
            color:   mix($black, $white, 56%);
        }

    }

    .block-nav__link {

        &:hover,
        &:focus {

            &::after {
                background-color: rgba($forest-fire-mgmt-neutral, .3);
                opacity:          1;
            }
        }

    }

    .block-nav__link.uber-accordion__button-active::after {
        background-color: $forest-fire-mgmt;
    }

    .block-nav__item-sub--link {

        &:hover,
        &:focus {
            opacity: 1;
        }

    }

}
