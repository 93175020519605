/* Module: S */
.full-width-carousel .container-fluid {
    position: relative;
}

.full-width-carousel .slick-prev {
    left: -75px;
}

.full-width-carousel .slick-next {
    right: -75px;
}

.full-width-carousel .slick-arrow {
    top: 50%;
}