// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Font weights for "VIC" font
$font-weight-light:     300;
$font-weight-normal:    400;
$font-weight-medium:    500;
$font-weight-semi-bold: 600;
$font-weight-bold:      700;

// Master list of z-index values
$z-index-search-filter-menu: 20;
