/* Module: S */
.news-articles {
	margin-top: pxToRem(-25);
	&__header {
		border-bottom: pxToRem(1) solid darken($medium-grey, 7%);
	}
	&__date {
		color:       $corporate-services;
		font-size:   pxToRem(18);
		font-weight: bold;
		line-height: 1.67;
		margin-bottom: pxToRem(18);
	}

	&__author {
		margin: 0 0 pxToRem(18) 0;
		&-image {
			display:inline-block;
			border-radius: 50%;
			width: pxToRem(55);
			height: pxToRem(55);
			margin-right: pxToRem(12);
			vertical-align:middle;
		}
		&-name {
			display:inline-block;
			font-weight:600;
			font-size: pxToRem(15);
			position:relative;
			color: $corporate-services!important;
		}
	}

	&__date + &__author {
		margin: pxToRem(18) 0;
		&-image {
			display:inline-block;
			border-radius: 50%;
			width: pxToRem(55);
			margin-right: pxToRem(12);
			vertical-align:middle;
		}
		&-name {
			display:inline-block;
			font-weight:600;
			font-size: pxToRem(15);
			position:relative;
			color: $corporate-services!important;
		}
	}
	
	&__summary {
		color:          $grey;
		font-size:      pxToRem(16);
		font-weight:    500;
		letter-spacing: -0.3px;
		margin:         pxToRem(10) 0 pxToRem(28) 0;
	}
	&__image {
		max-height:530px;
		max-width:995px;
		margin: 10px 0 30px 0;
	}
	&__content {
		padding: pxToRem(8) 0 pxToRem(18) 0;

		h3 {
			font-size: pxToRem(20);
		}

		p,
        ul,
        ol {
			color:          $grey;
			font-size:      pxToRem(14);
			font-weight:    normal;
			letter-spacing: -0.3px;
		}

		a {
			color: #201547;
			word-wrap: break-word;
		}
	}
}

.block-title--news_articles {
	.block-tile__item {
		display: flex;
		&--img {
			flex-grow: 1;
			flex-shrink: 1;

			&::before {
				content: none;
			}
			img {
			    height:     100%;
			    margin:     0 auto;
			    object-fit: cover;
			    top:        0;
			    transform:  translate(0, 0);
			}

		}
		&-content {
			flex-grow: 1;
			flex-shrink: 1;

			&--description {
				display: none;
			}
			&--link {
				display: none;
			}
		}
	}
}