/* Module: S */
.title-banner__social-sharing {
	bottom:    pxToRem(25);
	font-size: pxToRem(14);
	position:  relative;
	
	&__text {
		float:       left;
		font-weight: 500;
	}
	&__item {
		color:           $corporate-services;
		display:         block;
		float:           left;
		margin:          0 pxToRem(8);
		position:        relative;
		text-decoration: none;

		&:focus,
		&:hover {
			outline: none;
		}
		&--ico {
			background-position: center center;
			background-repeat:   no-repeat;
			background-size:     contain;
			display:             block;
			height:              19px;
			width:               19px;
		}

		&--facebook {
			background-image: url(mysource_files/facebook-social.svg);
		}
		&--twitter {
			background-image: url(mysource_files/twitter-social.svg);
		}
		&--linkedin {
			background-image: url(mysource_files/linkedin-social.svg);
		}
		&--email {
			background-image: url(mysource_files/email-social.svg);
		}
	}
	&__hidden {
		position:   absolute;
		visibility: hidden;
	}
}