/* Module: S */
.social-showcase {
    background: $medium-grey;
    padding:    pxToRem(40) 0 pxToRem(70);

    .col-xs-12.col-sm-6.col-md-4 {
        display: none;
        width:   100%;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            display: block;
        }
    }

    .container-fluid {
        max-width: pxToRem(1232);
    }

    .social-showcase__heading {
        color:       $corporate-services;
        font-size:   pxToRem(24);
        font-weight: 600;
        margin:      0 0 pxToRem(10);
        text-align:  center;
    }
    .social-showcase_no-img {
        h3 {
            margin-top: pxToRem(60);
        }
    }
    .social-showcase__item {
        @include sq-transition(background .35s ease);
        background:      $white;
        color:           $grey;
        display:         block;
        font-size:       pxToRem(14);
        margin-top:      pxToRem(20);
        text-decoration: none;

        &:hover,
        &:focus {

            .social-showcase__img-wrapper::after {
                height: 100%;
            }
        }
    }

    .social-showcase__item--teal {
        background: $teal;
        color:      $corporate-services;

        &:hover,
        &:focus {
            background: darken($teal, 3%);
        }
    }

    .social-showcase__item--corporate {
        background: $corporate-services;
        color:      $white;

        &:hover,
        &:focus {
            background: darken($corporate-services, 5%);
        }
    }

    .social-showcase__item--purple {
        background: $purple;
        color:      $white;

        &:hover,
        &:focus {
            background: darken($purple, 5%);
        }
    }

    .social-showcase__item--corporate,
    .social-showcase__item--purple {

        .social-showcase__feed-name {
            color: $white;
        }

        .social-showcase__feed-icon--fb {
            background-image: url('{{file_dest}}/fb-logo-white.svg');
        }

        .social-showcase__feed-icon--li {
            background-image: url('{{file_dest}}/li-logo-white.svg');
        }

        .social-showcase__feed-icon--tw {
            background-image: url('{{file_dest}}/tw-logo-white.svg');
        }

        .social-showcase__feed-icon--ig {
            background-image: url('{{file_dest}}/ig-logo-white.svg');
        }
    }

    .social-showcase__img-wrapper {
        background-size:       cover;
        background-repeat:     no-repeat;
        background-position-x: center;
        height:                246px;
        position:              relative;

        &::after {
            @include sq-transition(height .35s ease);
            background: rgba($white, .5);
            bottom:     0;
            content:    '';
            height:     0;
            left:       0;
            position:   absolute;
            right:      0;
            width:      100%;
        }
    }

    .social-showcase__img {
        display:    block;
        margin:     0 auto;
        max-height: 380px;
    }

    .social-showcase__link {
        color:   inherit;
        display: block;
        text-decoration: none;
    }

    .social-showcase__blurb-wrapper {
        padding: pxToRem(40) pxToRem(24) pxToRem(24);
    }

    .social-showcase__blurb {
        margin: 0;
    }

    .social-showcase__feed-name {
        color:       $corporate-services;
        display:     flex;
        font-size:   pxToRem(16);
        font-weight: 500;
        margin:      pxToRem(18) 0 0;
    }

    .social-showcase__feed-icon {
        background-position: center center;
        background-repeat:   no-repeat;
        display:             inline-block;
        height:              pxToRem(22);
        margin-right:        pxToRem(12);
        width:               pxToRem(22);
    }

    .social-showcase__feed-icon--fb {
        background-image: url('{{file_dest}}/fb-logo-corporate.svg');
    }

    .social-showcase__feed-icon--li {
        background-image: url('{{file_dest}}/li-logo-corporate.svg');
    }

    .social-showcase__feed-icon--tw {
        background-image: url('{{file_dest}}/tw-logo-corporate.svg');
    }

    .social-showcase__feed-icon--ig {
        background-image: url('{{file_dest}}/ig-logo-corporate.svg');
    }
}
