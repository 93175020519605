/* Module: S */
// 1. HEADER
.header {

    .container-fluid {
        padding-left:  pxToRem(32);
        padding-right: pxToRem(32);
    }

    .header__curves {

       &::before {
            right: pxToRem(-220);
            top:   pxToRem(-320);
        } 
    }
}

// 2. SEARCH
.header__search-form {
    background: $white;
    display:    inline-block;
    margin:     0 pxToRem(80) 0 0;
    width:      pxToRem(230);
}

.header__search-input,
.header__search-submit {
    background:     transparent;
    border:         0;
    display:        inline-block;
    margin:         0;
    padding:        0;
    vertical-align: middle;

    &:hover,
    &:focus {
        outline: none;
    }
}

.header__search-input {
    padding: pxToRem(10) pxToRem(19);
    width:   pxToRem(145);
}

.header__search-submit {
    background:  transparent;
    border:      0;
    line-height: 0;
    margin:      0;
    padding:     0;
}

.header {

    .header__search-icon {
        display: none;
    }

    .header__search-icon-box {
        background: url('{{file_dest}}/search-icon.svg') no-repeat center center transparent;
        background-size: cover;
        margin: pxToRem(11);
    }

    .header__search-mobile {
        display: none;
    }
}
