/* Module: S */
.main-content-wrapper {
	.btn {

	    &__large {
	        width:        pxToRem(370);
	        margin-right: pxToRem(50);

	        &:nth-child(even) {
	            margin-right: 0;
	        }
	    }
	}
}
