/* Module: S */
.block-tile {
    background-color: $medium-grey;
    padding:    pxToRem(30) 0 pxToRem(10);

    &.padding_top--half {
        padding-top: pxToRem(15);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(5);
    }
     &.padding_top--no {
        padding-top: 0;
    }
    &.padding_bottom--no {
        padding-bottom: 0;
    }
    .container-fluid {
        max-width: pxToRem(1232);
    }

    &--header {
        h2 {
            color:          $corporate-services;
            font-size:      pxToRem(24);
            font-weight:    600;
            padding-bottom: pxToRem(10);
        }
    }

    &__item {
        display:         block;
        flex-grow:       1;
        margin-bottom:   pxToRem(20);
        text-decoration: none;

        &:hover,
        &:focus {
            
            outline: none;
            .block-tile__item--img {

                &::after {
                    height:   100%;
                    opacity:  .5;
                }
            }
        }

        &--img {
            background-color: rgba($black, .75);
            overflow:         hidden;
            position:         relative;

            &::before {
                content:     '';
                display:     block;
                padding-top: (17 / 28) * 100%; // Maintain 28:17 aspect ratio
                width:       100%;
            }

            &::after {
                @include sq-transition(all .35s ease);
                bottom:   0;
                content:  '';
                height:   0;
                left:     0;
                opacity:  0;
                position: absolute;
                right:    0;
            }

            img {
                display:   block;
                left:      0;
                margin:    0 auto;
                position:  absolute;
                top:       50%;
                transform: translate(0, -50%);
                width:     100%;
            }
        }
        
        &--white-bordered {
            background-color: $white;

            .block-tile__item-content {
                @include sq-transition(background-color .35s ease-in-out);
            }

            .block-tile__item--img::after,
            &:hover .block-tile__item-content,
            &:focus .block-tile__item-content {
                background-color: $medium-grey;
            }

            .block-tile__item-content {
                border: 1px solid rgba($navy, .1);
            }

            .block-tile__item-content--description p {
                color: $grey;
            }

        }

        // Common styles
        &--white-bordered,
        &.corporate-services,
        &.purple-dark,
        &.purple-light,
        &.teal {

            .block-tile__item-content--icon {
                background-color:    transparent;
                background-position: center center;
                background-repeat:   no-repeat;
                display:             inline-block;
                height:              pxToRem(16.6);
                width:               pxToRem(25);
            }

        }

        // Common styles: dark BG with light text
        &.corporate-services,
        &.purple-dark {

            &,
            h3 {
                color: $white;
            }

            .block-tile__item-content--icon {
                background-image: url('{{file_dest}}/arrow--white-sort.svg');
            }

        }

        // Common styles: light BG with dark text
        &--white-bordered,
        &.purple-light,
        &.teal {

            &,
            .block-tile__item-content--description p {
                color: $navy;
            }

            .block-tile__item-content--icon {
                background-image: url('{{file_dest}}/arrow--cor-sort.svg');
            }

            .block-tile__item-content--description p {
                font-size: pxToRem(16);
            }

        }

        &.corporate-services {

            &,
            .block-tile__item--img::after {
                background-color: $corporate-services;
            }

        }

        &.purple-dark {

            &,
            .block-tile__item--img::after {
                background-color: $purple;
            }

        }

        &.purple-light {

            &,
            .block-tile__item--img::after {
                background-color: $purple-light;
            }

        }

        &.teal {

            &,
            .block-tile__item--img::after {
                background-color: $teal;
            }

        }

        &-content {
            padding: pxToRem(20);

            &--label {
                font-size: pxToRem(12);
                opacity: .8;
            }

            &--header h3,
            &--header strong {
                font-size:   pxToRem(20);
                font-weight: 600;
                line-height: pxToRem(27);
                margin:      pxToRem(7) 0 pxToRem(9);
            }

            &--description {
                font-size: pxToRem(14);
                letter-spacing: pxToRem(-0.3);

                p {
                    color:     $white;
                    font-size: pxToRem(16);
                }
            }

            &--link {
                margin-top: pxToRem(10);
                text-align: right;
            }

        }
    }

     &__category-list {
        padding:     0;
        font-size:   0; // http://stackoverflow.com/questions/5256533/a-space-between-inline-block-list-items
        font-weight: $font-weight-medium;
    }

    &__category-item {
        display:         inline;
        font-size:       pxToRem(14);
        list-style-type: none;

        &::after {
            content: ', ';
        }

        &:last-child::after {
            content: '';
        }

    }

    &__load-wrapper {
        display:         flex;
        justify-content: center;
    }

    .main.col-1 &,
    .main.col-2 & {
        background: transparent;
    }

    // background color fallback for news items
    .news_item .main & {
        background: $medium-grey;
    }
}
// Small News Tile
.block-tile--small {
    background: $corporate-services;

    .block-tile--header {
        h2 {
            color: $white;
        }
    }
    .block-tile__item--wrap {
        overflow: hidden;
        padding:  0 pxToRem(5);
    }
    .block-tile__item {
        background: $white;
        &:focus,
        &:hover {
            background-color: darken($white, 5%);
        }
        .block-tile__item--img::after {
            background: $white;
        }
        .block-tile__item-content--icon {
            background: url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent ;
            bottom:     20px;  
            display:    inline-block;
            height:     pxToRem(16.6);
            opacity:    1;
            position:   absolute;
            right:      20px;
            width:      pxToRem(25);  
        }
    }
    .block-tile__item-content {
        position: relative;

        &-wrapper {
            padding-right: pxToRem(30);
        }

        &--label {
            font-size: pxToRem(16);
            opacity:   1;
            color:     $corporate-services;
        }

        &--header h3 {
            margin: pxToRem(4) 0 pxToRem(10) 0;
        }

        &--category {
            color:          $corporate-services;
            display:        inline-block;
            font-weight:    500;
            padding-bottom: pxToRem(10);
        }
       
        &--description p {
            color: $corporate-services;
            margin: 0;
        }
    }
    .block-tile_more {
        margin:     pxToRem(30) pxToRem(35) pxToRem(30) 0;
        text-align: right;
        &-link {
            color:           $white;
            font-size:       pxToRem(16);
            position:        relative;
            text-decoration: none;
            
            &:hover,
            &:focus {
              text-decoration: underline;;
            }
            &::after {
                background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent ;
                content:    '';
                height:     pxToRem(16.6);
                opacity:    1;
                position:   absolute;
                right:      -35px;
                top:        50%;
                transform:  translateY(-50%);
                width:      pxToRem(25); 

            }
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management .block-tile {

    // Teal = jungle green
    // Navy (Corporate Services) = dark blue-green
    // Dark purple = sun yellow
    // Light purple = smoke black
    &__item {

        &.teal,
        &.purple-light {

            &,
            h3,
            .block-tile__item-content--description p {
                color: $white;
            }

            .block-tile__item-content--icon {
                background-image: url('{{file_dest}}/arrow--white-sort.svg');
            }

        }

        &.teal {

            &,
            .block-tile__item--img::after {
                background-color: $forest-fire-mgmt;
            }

        }

        &.corporate-services {

            &,
            .block-tile__item--img::after {
                background-color: $forest-fire-mgmt-dark;
            }

        }

        &.purple-dark {

            &,
            h3,
            .block-tile__item-content--description p {
                color: $forest-fire-mgmt-neutral;
            }

            &,
            .block-tile__item--img::after {
                background-color: $forest-fire-mgmt-highlight;
            }

            .block-tile__item-content--icon {
                background-image: url('{{file_dest}}/arrow--smoke-black-sort.svg');
            }

            .block-tile__item-content--description p {
                font-size: pxToRem(16);
            }

        }

        &.purple-light {

            &,
            .block-tile__item--img::after {
                background-color: $forest-fire-mgmt-neutral;
            }

        }

    }

    &--header h2 {
        color: $forest-fire-mgmt-neutral;
    }

}

// Theme: Fishermans Bend
.fishermans-bend .block-tile {

    &__item {

        &.teal {
            background-color: $fishermans-bend;

            .block-tile__item--img::after {
                background-color: $fishermans-bend;
            }
        }

        &.corporate-services {
            background-color: $fishermans-bend-dark;

            .block-tile__item--img::after {
                background-color: $fishermans-bend-dark;
            }
        }

        &.purple-dark {
            background-color: $fishermans-bend;

            .block-tile__item--img::after {
                background-color: $fishermans-bend;
            }

            .block-tile__item-content--icon {
                background-image: url('{{file_dest}}/arrow--cor-sort.svg');
            }

            &,
            h3,
            .block-tile__item-content--description p {
                color: $corporate-services;
            }
        }

        &.purple-light {
            background-color: $fishermans-bend-dark;

            .block-tile__item--img::after {
                background-color: $fishermans-bend-dark;
            }

            .block-tile__item-content--icon {
                background-image: url('{{file_dest}}/arrow--white-sort.svg');
            }

            &,
            h3,
            .block-tile__item-content--description p {
                color: $white;
            }
        }
    }
}

.revitalising-central-geelong.news_item {
        // hide related news
        section.block-tile.block-title--news_articles {
            display: none;
        }
    }
    

.revitalising-central-geelong .block-tile {
    // Small News Tile
    &--small {
        background: $revitalising-central-geelong;
    }
}    
