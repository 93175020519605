/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .media-search__element { ... }
// .media-search__element--modifier { ... }
// .media-search__sub-element { ... }
.media {
    padding-bottom: 65px;
}

.media .row {
    align-items: center;
}

.media-search {
    background-color: $section-grey;
    padding: 1.6875rem 0;
    align-self: center;
}

.media-search .search__input {
    background-color: transparent;
    font-size: 2.5rem;
    font-weight: 300;
}

.media-search .search__form {
    padding: 0;
    text-align: left;
    width: 100%;
}

.search__title {
    font-size: 1.25rem;
    margin: .5rem 0;
    color: $search-dark;
}