/* Module: S */
// 1. FOOTER
.footer-menu {
    padding: pxToRem(79) 0 pxToRem(88);

    .footer__group {
        height:   100%;
        position: relative;
    }

    .footer__list {
        display: block;
    }

    .footer__list--col {
        padding: 0 pxToRem(40);

        &::after,
        &::before {
            background: $white;
            content:    '';
            display:    block;
            height:     100%;
            position:   absolute;
            top:        0;
            width:      pxToRem(1);
        }

        &::after {
            right: pxToRem(16);
        }

        &::before {
            left: pxToRem(16);
        }
    }

    .footer__list--social {
        padding:    0;
        text-align: left;

        &::after {
            background: $white;
            content:    '';
            display:    block;
            height:     100%;
            position:   absolute;
            right:      0;
            top:        0;
            width:      pxToRem(1);
        }
    }

    .footer__item--social {
        display:     block;
        line-height: 1;
        margin:      0;
    }

    .footer__link--social {
        background:  transparent;
        height:      auto;
        overflow:    visible;
        text-indent: 0;
        white-space: normal;
        width:       auto;

        &:hover,
        &:focus {
            background:      transparent;
            color:           $teal;
            text-decoration: none;
        }

        &::before {
            @include sq-transition(all .35s ease);
            background-position: center center;
            background-repeat:   no-repeat;
            background-size:     cover;
            content:             '';
            display:             inline-block;
            height:              pxToRem(20);
            margin-right:        pxToRem(20);
            vertical-align:      middle;
            width:               pxToRem(20);
        }
    }

    .footer__link--fb {

        &::before {
            background-image: url('{{file_dest}}/fb-logo.svg');
            background-size:  contain;
        }

        &:hover::before,
        &:focus::before {
            background-image: url('{{file_dest}}/fb-logo-teal.svg');
        }
    }

    .footer__link--tw {

        &::before {
            background-image: url('{{file_dest}}/tw-logo.svg');
        }

        &:hover::before,
        &:focus::before {
            background-image: url('{{file_dest}}/tw-logo-teal.svg');
        }
    }

    .footer__link--li {

        &::before {
            background-image: url('{{file_dest}}/li-logo.svg');
        }

        &:hover::before,
        &:focus::before {
            background-image: url('{{file_dest}}/li-logo-teal.svg');
        }
    }

    .footer__link--yt {

        &::before {
            background-image: url('{{file_dest}}/yt-logo.svg');
            background-size:  contain;
        }

        &:hover::before,
        &:focus::before {
            background-image: url('{{file_dest}}/yt-logo-teal.svg');
        }
    }

    .footer__link--ig {

        &::before {
            background-image: url('{{file_dest}}/ig-logo.svg');
        }

        &:hover::before,
        &:focus::before {
            background-image: url('{{file_dest}}/ig-logo-teal.svg');
        }
    }

    .footer__heading {
        display: block;
    }
}

// 2. QUICKCONTACT
.quick-contact {
    font-size: pxToRem(14);

    .quick-contact__item {
        display: inline-block;

        + .quick-contact__item {
            margin:  0;
            padding: 0;

            &::before {
                background: $corporate-services;
                content:    '';
                display:    inline-block;
                height:     pxToRem(10);
                margin:     0 pxToRem(16);
                width:      pxToRem(2);
            }
        }
    }

    .quick-contact__item--icon {

        &::before {
            height: pxToRem(20);
            margin: 0 pxToRem(15) 0 0;
            width:  pxToRem(20);
        }
    }

    br {
        display: none;
    }
}

// 3. FOOTERGOV
.footer-gov {
    bottom:     0;
    position:   absolute;
    right:      0;
    text-align: right;

    .footer-gov__logo {
        display: inline-block;
        margin:  0;
    }

    .footer-gov__copy {
        font-size:  pxToRem(14);
        margin:     pxToRem(22) 0 0;
        text-align: right;
    }
}


.revitalising-central-geelong {

    .footer-menu { 
        
        .footer__link--fb {

            &:hover,
            &:focus {
                background: transparent;
                color: $white;
            }
        
            &::before,
            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/fb-logo.svg');
                background-size:  contain;
            }
        }
        
        .footer__link--tw {
        
            &:hover,
            &:focus {
                background: transparent;
                color: $white;
            }
        
            &::before,
            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/tw-logo.svg');
            }
        }
        
        .footer__link--li {
        
            &:hover,
            &:focus {
                background: transparent;
                color: $white;
            }
        
            &::before,
            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/li-logo.svg');
            }
        }
        
        .footer__link--yt {
        
            &:hover,
            &:focus {
                background: transparent;
                color: $white;
            }
        
            &::before,
            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/yt-logo.svg');
                background-size:  contain;
            }
        }
        
        .footer__link--ig {
        
            &:hover,
            &:focus {
                background: transparent;
                color: $white;
            }
        
            &::before,
            &:hover::before,
            &:focus::before {
                background-image: url('{{file_dest}}/ig-logo.svg');
            }
        }
    }
}