/* Module: S */
// 1. LHS NAV
.lhs-nav {
    background:  lighten($black, 95%);
    color:       $corporate-services;
    display:     none;
    font-size:   pxToRem(17);
    font-weight: 500;
    max-width:   307px; //dev
    padding:     pxToRem(23) pxToRem(38);
    position:    relative;
    z-index:     10;

    .lhs-nav__list,
    .lhs-nav__sublist {
        @include sq-list-reset();
        color:   $corporate-services;
    }

    .lhs-nav__item {
        position: relative;
    }

    .lhs-nav__link,
    .lhs-nav__sublink {
        @include sq-transition(color .35s ease);
        color:           inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color:   $corporate-services--hover;
            outline: none;
        }
    }

    .lhs-nav__cta {
        @include sq-transition(all .35s ease);
        background: url('{{file_dest}}/chevron--teal.svg') no-repeat center center;
        border:     0;
        height:     pxToRem(50);
        margin:     0;
        outline:    transparent dotted thin;
        padding:    0;
        position:   absolute;
        right:      pxToRem(-19);
        top:        0;
        width:      pxToRem(50);

        &:hover,
        &:focus {
            outline: none;
        }
    }

    .lhs-nav__cta--active {
        @include sq-transform(rotate(180deg));
    }

    .lhs-nav__item--open {

        .lhs-nav__sublist {
            display: block;
        }
    }

    .lhs-nav__link {
        display: block;
        padding: pxToRem(13) pxToRem(22) pxToRem(14) 0;
    }

    .lhs-nav__link--current {
        
        > .lhs-nav__link {
            position: relative;

            &::before {
                border-color: transparent transparent transparent $teal;
                border-style: solid;
                border-width: pxToRem(7) 0 pxToRem(7) pxToRem(15);
                content:      '';
                display:      block;
                height:       0;
                left:         pxToRem(-38);
                position:     absolute;
                top:          pxToRem(17);
                width:        0;
            }
        }

        > .lhs-nav__sublist {
            display: block;
        }
    }

    .lhs-nav__sublist {
        display: none;
    }

    .lhs-nav__subitem {
        margin: 0 0 0 pxToRem(16);

        + .lhs-nav__subitem {
            border-top: pxToRem(1) solid lighten($black, 85%);
        }
    }

    .lhs-nav__sublink {
        display: block;
        padding: pxToRem(13) 0 pxToRem(14);
    }

    .lhs-nav__sublink--current > .lhs-nav__sublink {
        position: relative;

        &::before {
            border-color: transparent transparent transparent $teal;
            border-style: solid;
            border-width: pxToRem(7) 0 pxToRem(7) pxToRem(15);
            content:      '';
            display:      block;
            height:       0;
            left:         pxToRem(-54);
            position:     absolute;
            top:          pxToRem(17);
            width:        0;
        }
    }

    .no-js & {

        .lhs-nav__sublist {
            display: block;
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management {

    .lhs-nav__list,
    .lhs-nav__sublist {
        color: $forest-fire-mgmt-neutral;
    }

    .lhs-nav__link,
    .lhs-nav__sublink {

        &:hover,
        &:focus {
            color: mix($black, $white, 51%);
        }

    }

    .lhs-nav__sublink--current > .lhs-nav__sublink,
    .lhs-nav__link--current > .lhs-nav__link { 

        &::before {
            border-color: transparent transparent transparent $jungle-green;
        }
    }

    .lhs-nav .lhs-nav__cta {
            background: url('{{file_dest}}/chevron--jungle-green.svg') no-repeat center center;
    }
}

