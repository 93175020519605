/* Module: S */
.container-fluid {

    &--column-wrapper {
        padding-bottom: 4.5rem;
        padding-top:    5rem;
        max-width:      75rem;
    }

}
