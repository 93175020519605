/* Module: S */
// 1. QUICKLINKS
.quick-links {
    background: $teal;
    color:      $corporate-services;
    padding:    pxToRem(25) 0;
    position: relative;
    z-index: 8;

    .container-fluid {
        max-width: pxToRem(1232);
    }

    .quick-links__link {
        @include sq-transition(background .35s ease);
        -webkit-box-align: center;
        -ms-flex-align:    center;
        align-items:       center;
        background:        $white;
        color:             inherit;
        display:           -webkit-box;
        display:           -ms-flexbox;
        display:           flex;
        font-weight:       600;
        margin:            pxToRem(5) 0;
        padding:           pxToRem(14) pxToRem(67) pxToRem(14) pxToRem(20);
        position:          relative;
        text-decoration:   none;

        &:hover,
        &:focus {
            background: lighten($black, 90%);
        }

        &::after {
            @include sq-transform(translateY(-50%));
            background:      url('{{file_dest}}/arrow.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            display:         inline-block;
            float:           right;
            height:          pxToRem(13);
            position:        absolute;
            right:           pxToRem(20);
            top:             50%;
            width:           pxToRem(27);
        }
    }

    .main.col-1 & {

        .quick-links__link {
            background: $dark-grey;

            &:hover,
            &:focus {
                background: lighten($black, 80%);
            }
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management {

    .quick-links .quick-links__link,
    .main.col-1 .quick-links .quick-links__link {
        background-color: $forest-fire-mgmt-dark;

        &:hover,
        &:focus {
            background-color: $forest-fire-mgmt;
        }

    }

    .quick-links {
        background-color: $medium-grey;

        .quick-links__link {
            color: $white;

            &::after {
                background-image: url('{{file_dest}}/arrow--white.svg');
            }
        }
    }
}

// Theme: Fishermans Bend
.fishermans-bend {

    .quick-links .quick-links__link,
    .main.col-1 .quick-links .quick-links__link {
        background-color: $fishermans-bend;
        color: $corporate-services;

        &::after {
            background-image: url('{{file_dest}}/arrow.svg');
        }

        &:hover,
        &:focus {
            background-color: $fishermans-bend-dark;
            color:            $white;

             &::after {
                background-image: url('{{file_dest}}/arrow--white.svg');
            }
        }

    }

    .quick-links {
        background-color: $white;

    }

}
