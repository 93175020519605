/* Module: S */
.search {

    &__wrapper {
        max-width: pxToRem(790);
        margin:    0 auto;
    }

    &__wrapper--news,
    &__wrapper--grants,
    &__wrapper--publications {
        max-width: pxToRem(1232);
    }

    // 0. SEARCH BANNER
    &__banner {
        padding-bottom: pxToRem(59);
        border-bottom:  pxToRem(1) solid rgba($corporate-services, .3);
    }

    &__group {
        position: relative;

    }

    &__form {
        border-bottom: pxToRem(2) solid $corporate-services;
        margin:        0 auto;
        padding:       pxToRem(40) 0 pxToRem(12);
    }

    &__input {
        border:    none;
        color:     $corporate-services;
        font-size: pxToRem(30);
        width:     90%;

        &:focus,
        &:hover {
            outline: none;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $light-grey;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $light-grey;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $light-grey;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $light-grey;
        }
    }

    &__submit {
        background: url('{{file_dest}}/search-icon--teal.svg') no-repeat center center transparent;
        border:     none;
        height:     pxToRem(20);
        position:   absolute;
        right:      pxToRem(10);
        top:        pxToRem(15);
        width:      pxToRem(20);

        &:focus,
        &:hover {
            outline: none;
        }

        &--text {
            display: none;
        }

        &--cancel {
            background: url('{{file_dest}}/cancel.svg') no-repeat center center transparent;
        }
    }

    &__tabs {
        display:         flex;
        justify-content: center;
        padding:         pxToRem(48) 0 pxToRem(9);
        margin:          0 auto;

        &--list {
            border-bottom:   pxToRem(1) solid rgba($corporate-services, .1);
            display:         block;
            list-style-type: none;
            margin:          0;
            padding:         0 0 pxToRem(10);
        }

        &--item {
            display: inline-block;
        }

        &--link {
            color:           $corporate-services;
            font-size:       pxToRem(14);
            padding:         pxToRem(10);
            text-decoration: none;

            &-active {
                border-bottom:  pxToRem(2) solid $teal;
            }

            &:hover,
            &:active,
            &:focus {
                color: $teal;
                outline: none;
            }
        }
    }

    // 1. SEARCH FILTER/SORT
    &__chevron {
        @include sq-transition(transform 0.35s ease);
        background: url('{{file_dest}}/chevron--teal.svg') no-repeat center center transparent;
        display:    inline-block;
        height:     pxToRem(6);
        position:   absolute;
        right:      1rem;
        top:        50%;
        transform:  translate(0, -50%);
        width:      pxToRem(12);

        &--up {
            -ms-transform: rotate(-180deg); /* IE 9 */
            -webkit-transform: rotate(-180deg); /* Chrome, Safari, Opera */
            transform: rotate(-180deg);
        }
    }

    &__filter {
        border-bottom:   1px solid rgba($navy, .3);
        list-style-type: none;
        margin:          0 -1rem;
        padding:         0;
        position:        relative;

        &--group {

            .search__wrapper > .container-fluid {
                position: relative;
            }

        }

        &-label {
            align-self:    center;
            border-bottom: 1px solid rgba($navy, .3);
            font-size:     pxToRem(14);
            font-weight:   $font-weight-semi-bold;
            margin:        pxToRem(10) -1rem 0;
            padding:       pxToRem(10) 1rem;
        }

        &-link {
            color:           $navy;
            display:         block;
            font-size:       pxToRem(12);
            font-weight:     500;
            padding:         pxToRem(10) pxToRem(36) pxToRem(10) pxToRem(16);
            position:        relative;
            text-decoration: none;

            &:focus,
            &:hover {
                outline: none;
            }

            .disabled & {
                color: mix($navy, $white, 30%);
            }

        }

        &-menu {
            background-color: $medium-grey;
            display:          none;
            list-style-type:  none;
            padding:          0;
            position:         absolute;
            width:            100%;
            z-index:          $z-index-search-filter-menu;

            &.active {
                display: block;
            }

        }

        &-menu-link {
            color:           $navy;
            display:         block;
            font-size:       pxToRem(12);
            font-weight:     normal;
            line-height:     pxToRem(26);
            padding:         .5rem 1rem; // Interactive link target area needs to be big enough to tap with finger on mobile
            text-decoration: none;

            &:hover, 
            &:active,
            &:focus, 
            &.active {
                outline:         none;
                text-decoration: underline;
            }

        }

        &.active {
            background: $medium-grey;
        }

        &--status {
            right: 14rem;
        }

        &--category {
            left: 10rem;
        }

        &--theme {
            margin-left: pxToRem(-10);
        }

        &--department {
            margin-left: pxToRem(-15);
        }

        &--no-filter {
            .search__filter {
                &--category-link, &--status-link, &--theme {
                    color: #cbcbcb;

                    .search__chevron {
                        background:  url('{{file_dest}}/chevron--grey.svg') no-repeat center center transparent;
                    }
                }
            }
        }
    }

    // 2. SEARCH RESULT LISTING
    &__result {
        &--number {
            color:       $grey;
            font-size:   pxToRem(12);
            line-height: pxToRem(30);
            padding:     pxToRem(25) 0;

            .btn__link {
                float: right;
            }
        }

        &--listing {
            list-style-type: none;
            margin:  0;
            padding: 0;
        }

        &--item {
            border-bottom: pxToRem(1) solid rgba($corporate-services, .31);
            padding:       pxToRem(30) 0;
        }

        &--title a {
            color:           $corporate-services;
            font-size:       pxToRem(14);
            font-weight:     600;
            line-height:     pxToRem(20);
            text-decoration: none;

            &:hover,
            &:active {
                text-decoration: underline;
            }

        }

        &--type {
            background:  $corporate-services;
            color:       $white;
            display:     inline-block;
            font-size:   pxToRem(10);
            font-weight: 500;
            height:      pxToRem(16);
            margin-left: pxToRem(10);
            padding:     0 pxToRem(6);
            text-align:  center;
            width:       auto;
        }

        &--tag {
            color:       $corporate-services;
            font-size:   pxToRem(12);
            font-weight: 500;
            line-height: pxToRem(30);
        }

        &--description,
        &--link {
            color:       $grey;
            font-size:   pxToRem(14);
            line-height: pxToRem(20);
            padding:     pxToRem(6) 0;
        }

        &--link a {
            color:           $corporate-services;
            text-decoration: none;
            word-wrap:       break-word;

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }

        &--tiles {
            background-color: transparent;
            padding-top:      0;

            .container-fluid {
                padding-left:  0;
                padding-right: 0;
            }

        }

    }

    // 3. PAGINATION
    &__pagination {
        text-align: center;

        &--list {
            list-style-type: none;
            margin:          0;
            padding:         pxToRem(20) 0;
        }

        &--item {
            display: inline-block;

            &-current {
                color:     $teal;
                font-size: pxToRem(14);
                padding:   0 pxToRem(18);
            }

            &.prev a, &.next a {
                text-decoration: none;

                &:hover,
                &:active,
                &:focus {
                    outline: none;

                    .left-arrow,
                    .right-arrow {
                        background: url('{{file_dest}}/next.svg') no-repeat center center transparent;
                        transform:  rotate(0deg);
                    }

                    .right-arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            &.prev a {
                padding: pxToRem(16) pxToRem(16) pxToRem(16) 0;
            }

            &.next a {
                padding: pxToRem(16) 0 pxToRem(16) pxToRem(16);
            }

            .left-arrow,
            .right-arrow {
                background: url('{{file_dest}}/back.svg') no-repeat center center transparent;
                display:    inline-block;
                height:     pxToRem(20);
                width:      pxToRem(7);
           }

            .left-arrow {
                position:  relative;
                top:       1px;
                transform: rotate(180deg);
            }
        }

        &--link {
            color:           $grey;
            font-size:       pxToRem(14);
            font-weight:     500;
            padding:         pxToRem(16);
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                color:   $teal;
                outline: none;
            }
        }
    }
}

// Theme: Forest Fire Management
.forest-fire-management .search {

    &__tabs--link:hover,
    &__tabs--link:focus,
    &__tabs--link:active,
    &__pagination--link:hover,
    &__pagination--link:focus,
    &__pagination--link:active,
    &__pagination--item-current {
        color: $forest-fire-mgmt;
    }

    &__tabs--link-active {
        border-bottom-color: $forest-fire-mgmt;
    }

    &__result--type {
        background-color: $forest-fire-mgmt-neutral;
    }

    &__chevron {
        background-image: url('{{file_dest}}/chevron--jungle-green.svg');
    }

    &__submit {
        background-image: url('{{file_dest}}/search-icon--jungle-green.svg');
    }

    &__submit--cancel {
        background-image: url('{{file_dest}}/cancel--jungle-green.svg');
    }

}

// Theme: Fishermans Bend
.fishermans-bend {
    .autosuggest-results--header,
    .autosuggest-results {
        background:    $medium-grey;
        border-bottom: none;
        border-left:   none;
        border-right:  none;

        .autosuggest-item {
            color: $fishermans-bend-black;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
    .search__form {
        border-bottom: 0.125rem solid $fishermans-bend-dark;
    }

    .search__input,
    .search__tabs--link,
    .search__filter-link {
        color: $fishermans-bend-black;
    }

    .search__tabs--link-active {
        border-bottom: 0.125rem solid $fishermans-bend;
    }
}
