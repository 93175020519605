/* Module: Matrix form */

form {

    // Reset defaults
    .sq-form-section, 
    fieldset {
        border:  0;
        margin:  0;
        padding: 0;
    }

    // Input + label wrapper
    .sq-form-question {
        margin:   pxToRem(40) 0;
        position: relative;
    }

    // Show label on focus
    .sq-form-question--focus {

        .sq-form-question-title {
            opacity: 1;
            padding: 0;
        }
    }

    // Error handle
    .sq-form-question-error {

        .sq-form-field {
            border-color: $matrix-form__error-text;
            color:        $matrix-form__error-text;

            &::-webkit-input-placeholder {
                // Chrome/Opera/Safari
                color: $matrix-form__error-text;
            }

            &::-moz-placeholder {
                // Firefox 19+
                color: $matrix-form__error-text;
            }

            &:-ms-input-placeholder {
                // IE 10+
                color: $matrix-form__error-text;
            }

            &:-moz-placeholder {
                // Firefox 18-
                color: $matrix-form__error-text;
            }

            &:hover,
            &:focus {
                border-color: $matrix-form__error-text;
            }
        }
    }

    // Note
    .sq-form-question-note {
        color:      $grey;
        display:    block;
        font-size:  pxToRem(10);
        font-style: italic;
    }

    // Label
    .sq-form-question-title {
        @include sq-transition(padding .35s ease, opacity .45s ease);
        color:       $grey;
        font-size:   pxToRem(10);
        font-weight: 500;
        opacity:     0;
        padding:     pxToRem(30) 0 0;
        position:    absolute;
        top:         pxToRem(-14);

        .sq-form-required-field {
            color: $matrix-form__error-text;
        }

        .no-js & {
            opacity: 1;
            padding: 0;
        }
    }

    // Default style for all inputs
    .sq-form-field {
        @include sq-transition(all .35s ease);
        background:     transparent;
        border:         0;
        border-bottom:  pxToRem(1) solid $matrix-form__border-colour;
        color:          $corporate-services;
        font-size:      pxToRem(14);
        padding:        pxToRem(5) 0;
        width:          100%;

        &:hover,
        &:focus {
            border-color:  $corporate-services;
            outline:       0;
        }

        &::-webkit-input-placeholder {
            // Chrome/Opera/Safari
            color: $corporate-services;
        }

        &::-moz-placeholder {
            // Firefox 19+
            color: $corporate-services;
        }

        &:-ms-input-placeholder {
            // IE 10+
            color: $corporate-services;
        }

        &:-moz-placeholder {
            // Firefox 18-
            color: $corporate-services;
        }
    }

    // Overwrite for textarea
    textarea.sq-form-field {
        border:  pxToRem(1) solid $matrix-form__border-colour;
        padding: pxToRem(8);
    }

    // Section heading
    .sq-form-section-title {
        display:     block;
        font-size:   pxToRem(20);
        font-weight: 700;
    }

    // Error message
    .sq-form-error {
        bottom:      pxToRem(-20);
        color:       $matrix-form__error-text;
        font-size:   pxToRem(10);
        font-weight: 400;
        margin:      pxToRem(6) 0 0;
        position:    absolute;
    }

    // Radio and checkbox inputs
    .sq-form-question-tickbox-list,
    .sq-form-question-option-list {

        ul {
            @include sq-list-reset();
            padding: 0 0 0 pxToRem(28);
        }

        li {
            display: block;
            padding: pxToRem(8) 0;
        }

        input {
            @extend %screen-hide;

            &:checked + label:after {
                opacity: 1;
            }

            &:focus + label {
                outline: dotted thin;
            }
        }

        label {
            font-size: pxToRem(14);
            position:  relative;

            &::after,
            &::before {
                content:  '';
                position: absolute;
            }

            &::before {
                border: pxToRem(1) solid $from-border-grey;
            }

            &:hover::before {
                background: lighten($from-border-grey, 35%);
            }
        }
    }

    // Checkbox only
    .sq-form-question-tickbox-list {

        label {

            &::after,
            &::before {
                display: inline-block;
                height:  pxToRem(15);
                left:    pxToRem(-28);
                top:     pxToRem(3);
                width:   pxToRem(15);
            }

            &::after {
                background: url('{{file_dest}}/tick.svg') no-repeat center center transparent;
                height:     pxToRem(12);
                left:       pxToRem(-25);
                opacity:    0;
                top:        pxToRem(3);
                width:      pxToRem(14);
            }
        }
    }

    // Radio buttons only
    .sq-form-question-option-list {

        label {

            &::after,
            &::before {
                border-radius: 50%;
            }

            &::after {
                background-color: $teal;
                height:           pxToRem(9);
                left:             pxToRem(-25);
                opacity:          0;
                top:              pxToRem(6);
                width:            pxToRem(9);
            }

            &::before {
                display: inline-block;
                height:  pxToRem(15);
                left:    pxToRem(-28);
                top:     pxToRem(3);
                width:   pxToRem(15);
            }
        }
    }
}

// Selectric overwrites
.selectric {
    @include sq-transition(all .35s ease);
    background:    none;
    border:        0;
    border-bottom: pxToRem(1) solid $from-border-grey;

    .selectric-hover &,
    .selectric-focus & {
        border-color: $corporate-services;
    }

    .selectric-open & {
        border-color: transparent;
    }

    &-wrapper {
        margin-left:  pxToRem(-14);
        margin-right: pxToRem(-14);
        padding:      pxToRem(10) pxToRem(14);
        width:        calc(100% + 28px);
    }

    .label {
        color:       $corporate-services;
        font-size:   pxToRem(14);
        font-weight: 400;
        line-height: pxToRem(30);
        margin:      0;
    }

    .button {
        background: none;

        &::after {
            background:   url('{{file_dest}}/chevron-teal.svg') no-repeat center center;
            border:       0;
            height:       pxToRem(6);
            margin-right: 0;
            width:        pxToRem(12);
        }
    }
}

.selectric-open {
    background: $medium-grey;
}

.selectric-items {
    background: $medium-grey;
    border:     0;
    box-shadow: none;
    width:      100%!important;

    li {
        font-size: pxToRem(14);
        padding:   pxToRem(8) pxToRem(14);

        &.highlighted {
            background:      none;
            text-decoration: underline;
        }

        &:hover,
        &:focus {
            background:      none;
            text-decoration: underline;
        }
    }
}


// upload field
.sq-form-question-file-upload {
    @include sq-transition(all .35s ease);

    .js-enabled & {
        border-bottom: pxToRem(1) solid $from-border-grey;
        padding:       0 0 pxToRem(8);

        &:hover,
        &:focus {
            border-color: $corporate-services;
        }

        .sq-form-question-answer,
        .sq-form-question-facade {
            // (Text * line height) + top padding + bottom padding
            height: pxToRem(40); //to fix issue on Samsung mob & tab VMR-543
            // height: (1rem * 1.4) + (2 * pxToRem(9));
        }

        .sq-form-question-answer {
            cursor:   pointer;
            display:  inline-block;
            position: relative;
            width:    100%;
        }

        .sq-form-field[type="file"] {
            @include sq-opacity(0);
        }

        .sq-form-field[type="file"]:focus + .sq-form-question-facade {
            // Apply "focus" style to file input façade
            outline: thin dotted;
        }

        .sq-form-question-facade {
            color:         $corporate-services;
            font-size:     pxToRem(14);
            left:          0;
            overflow:      hidden;
            padding:       pxToRem(10) 0;
            position:      absolute;
            right:         0;
            text-overflow: ellipsis;
            top:           0;

            &::after {
                @include sq-transition(all .35s ease);
                background-color: $corporate-services;
                bottom:           0;
                color:            $white;
                content:          'Choose files';
                padding:          pxToRem(9) pxToRem(40) pxToRem(9) pxToRem(17);
                position:         absolute;
                right:            0;
                text-align:       center;
                top:              0;
                width:            auto;
            }

            &::before {
                background: url('{{file_dest}}/upload-icon.svg') no-repeat center center;
                content:    '';
                display:    inline-block;
                height:     pxToRem(13);
                position:   absolute;
                right:      pxToRem(17);
                top:        pxToRem(10);
                width:      pxToRem(13);
                z-index:    1;
            }

            &:hover::after,
            &:focus::after {
                background-color: rgba($corporate-services, .9);
            }
        }
    }
}

// submit btn
input[type="submit"] {
    @include sq-transition(all .35s ease);
    background:      $corporate-services;
    border:          0;
    color:           $white;
    font-size:       pxToRem(16);
    height:          pxToRem(50);
    min-width:       pxToRem(155);
    margin:          pxToRem(17.5) pxToRem(27) pxToRem(17.5) 0;
    padding:         pxToRem(14) pxToRem(20);
    text-align:      center;
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: rgba($corporate-services, .9);
    }
}
