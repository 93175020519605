/* Module: S */
.search-results--grid {
    margin: 0 auto;
    max-width: pxToRem(1232);
    padding: pxToRem(41) 0;
    width: auto;
}

.searchable-grid {

    .featured-image__content-item--large {
        height: pxToRem(380);
    }

    .featured-image__content-item--link {
        &:before {
            border-top: pxToRem(384) solid;
            border-top-color: inherit;
            border-right: pxToRem(112) solid transparent;
        }
    }

    .featured-image__content-item--title {
        font-size: pxToRem(26);
    }

    .featured-image__content-item--label {
        font-size: pxToRem(18);
        display: block;
    }

}

.searchable-grid__item {

    // Reset tablet
    &:last-child:not(:nth-child(2n+2)) {
        // -ms-flex-preferred-size: 33.33333333%;
        // flex-basis: 33.33333333%;
        // max-width: 33.33333333%;
    }

    // If one Tile
    &:only-child {
        // -ms-flex-preferred-size: 100% !important;
        // flex-basis: 100% !important;
        // max-width: 100% !important;

        .featured-image__content-item--large .featured-image__content-item--link::before {
            max-height: pxToRem(390);
            width: 50%;
        }

        .featured-image__content-item--wrapper {
            max-width: 50%;
        }
    }

    // First Two Tiles 50% 
    &:last-child:nth-child(2),
    &:nth-last-child(2):nth-child(1) {
        // -ms-flex-preferred-size: 50%;
        // flex-basis: 50%;
        // max-width: 50%;
    }

    // Last n Two Tiles 50% 
    &:last-child:not(:nth-child(3n)) {
        // -ms-flex-preferred-size: 50% !important;
        // flex-basis: 50% !important;
        // max-width: 50% !important;
    }

    &:nth-last-child(2):not(:nth-child(3n)):not(:nth-child(3n+2)) {
        // -ms-flex-preferred-size: 50%;
        // flex-basis: 50%;
        // max-width: 50%;
    }

    // Last 100% of one
    &:last-child:nth-child(3n+1) {
        // -ms-flex-preferred-size: 100% !important;
        // flex-basis: 100% !important;
        // max-width: 100% !important;

        .featured-image__content-item--large .featured-image__content-item--link::before {
            max-height: pxToRem(390);
            width: 50%;
        }

        .featured-image__content-item--wrapper {
            max-width: 50%;
        }
    }
}

.searchable-featured-grid.search__form-filbtn-wrap {
    padding: pxToRem(20) pxToRem(0);

    .search__form-wrap {
        padding: 0 1rem;
    }

    .search-filters {
        flex-basis: pxToRem(330);
        max-width: pxToRem(330);
        padding: 0 1rem;

        .search-filters__filter-tab {
            display: block;
            padding: 0;
        }
    }
}