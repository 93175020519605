/* Module: S */
body.drawer-opened {
    .main,
    .overlay--active-drawer,
    .title-banner,
    .footer,
    .content-drawer__close-wrapper {
        display: none;
    }

    .content-drawer {
        position: static;
    }

    
}