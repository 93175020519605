/* Module: S */
// 1. FEATURE PANEL
.feature-panel {
    padding: pxToRem(60) 0;
    &.padding_top--half {
        padding-top: pxToRem(30);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(30);
    }

    &::after {
        border-width: 0 pxToRem(93) pxToRem(190);
        left:         pxToRem(-93);
    }

    &::before {
        border-width: pxToRem(190) pxToRem(93) 0;
        right:        pxToRem(-93);
    }

    .feature-panel__heading {
        font-size: pxToRem(40);
    }

    .feature-panel__descr {
        font-size: pxToRem(16);
        margin:    pxToRem(10) auto pxToRem(32);
        max-width: pxToRem(600);
    }

    .feature-panel__block {
        padding: pxToRem(24) 0;
    }

    .feature-block__heading {
        font-size: pxToRem(20);
    }

    .feature-block__descr {
        font-size: pxToRem(16);
    }
}
