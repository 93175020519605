/* Module: S */
.social-carousel.slick-slider {
    position: static;
}

//custom slider arrows
.social-carousel .slick-arrow {
    background-color: $arrow-bg;
    border-radius: 100%;
    border: 0 none;
    height: 70px;
    position: absolute;
    text-indent: -9999px;
    top: 53.8%;
    transform: translateY(-50%);
    white-space: nowrap;
    width: 70px;
    z-index: 11;
    &::after {
        background-image: url('mysource_files/slider-chevron.svg');
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        
    }
}

.social-carousel .slick-prev {
    left: 20px;

    &::after {
        left: 50%;
        transform: translate(-50%, -50%)
    }
}

.social-carousel .slick-next {
    right: 20px;

    &::after {
        left: 50%;
        transform: translate(-50%, -50%) rotate(-180deg);
    }
}