/* Module: S */
// 1. FOOTER
.footer-menu {

    .footer__list--col {
        padding: 0 pxToRem(40) 0 pxToRem(64);

        &::after {
            right: pxToRem(42);
        }
    }
}
