/* Module: S */
.main-content-wrapper {
    .form-accordion__btn {
        color:   $white;
    }
}

.form-accordion {
    &__item-header {
        background-color: rgba(188, 184, 199, 0.16);
        position:         relative;

        .form-accordion__btn {
            display: none;
        }
    }
    &__title {
        display:     block;
        font-weight: 600;
        line-height: 1.88;
    }
    &__subtitle {
        display:     inline-block;
        font-size:   pxToRem(12);
        font-weight: normal;
        line-height: 2.5;
        padding: 0 pxToRem(20) 0 0;
        position:    relative;
        width:       100%;

        &::after {
            @include sq-transform(translateY(-50%));
            @include sq-transition(transform .35s ease);
            background: url('{{file_dest}}/chevron-teal.svg') no-repeat center center transparent;
            content:    '';
            height:     pxToRem(16);
            position:   absolute;
            right:      0;
            top:        50%;
            width:      pxToRem(16);
        }
    }

    &__target-list--one {
        -moz-column-count:    1;
        -webkit-column-count: 1;
        columns:              1;
        list-style:           none;
        margin:               pxToRem(12) 0 0 0;
        padding-left:         0;
    }

    &__target-list {
        -moz-column-count:    1;
        -webkit-column-count: 1;
        columns:              1;
        list-style:           none;
        margin:               pxToRem(12) 0 0 0;
        padding:              0;
    }
    &__target-item {
        padding:  pxToRem(8) 0 0 pxToRem(22);
        position: relative;

        &::before {
            background: url('{{file_dest}}/form-icon.svg') no-repeat center center transparent;
            content:    '';
            height:     pxToRem(16);
            left:       0;
            position:   absolute;
            top:        pxToRem(11);
            width:      pxToRem(12);
        }

        a {
            color:           $corporate-services;
            font-weight:     500;
            line-height:     1.5;
            text-decoration: none;

            &:hover,
            &:focus {
                color:           rgba($corporate-services, 0.9);
                outline:         none;
                text-decoration: underline;
            }
        }
    }
    &__target-label {
        color:       #6f6f6f;
        font-size:   pxToRem(12);
        font-size:   pxToRem(12);
        font-weight: normal;
        line-height: 2;
        margin-left: pxToRem(5);
    }
    .accordion__link {
        font-weight: normal;
        padding:     pxToRem(12) pxToRem(20);

        &:focus,
        &:hover {
            .form-accordion__subtitle {
                text-decoration: underline;
            }
        }
        &::after {
            content: none;
        }
    }
    .uber-accordion__button-active {
        .form-accordion__subtitle {
            &::after {
                @include sq-transform(translateY(-50%) rotate(-180deg));
                background: url('{{file_dest}}/chevron.svg') no-repeat center center transparent;
            }
        }
    }
    .accordion__target {
        background-color: rgba(188, 184, 199, 0.16);
        color:            $corporate-services;
        font-size:        pxToRem(14);

        .form-accordion__btn {
            @include sq-transition(all .35s ease);
            background:      $corporate-services;
            border:          none;
            color:           $white;
            display:         inline-block;
            font-size:       pxToRem(14);
            font-weight:     400;
            margin:          auto;
            min-height:      pxToRem(39);
            outline:         none;
            padding:         pxToRem(10) pxToRem(37) pxToRem(10) pxToRem(16);
            position:        relative;
            text-decoration: none;
            width:           100%;

            &::after {
                background: url('{{file_dest}}/form-icon-white.svg') no-repeat center center transparent;
                content:    '';
                height:     pxToRem(16);
                position:   absolute;
                right:      pxToRem(15);
                top:        pxToRem(11);
                width:      pxToRem(12);
            }

            &:hover,
            &:focus {
                background: rgba($corporate-services, 0.9);
            }
        }

        p {
            color:          $corporate-services;
            font-size:      pxToRem(16);
            font-weight:    normal;
            font-style:     normal;
            font-stretch:   normal;
            letter-spacing: -0.3px;
            margin:         pxToRem(12) 0 0 0;
        }
    }
}


//Themes: fishermans-bend

.fishermans-bend {
    .main-content-wrapper {
        .form-accordion {
            .uber-accordion__button-active {
                .form-accordion__subtitle {
                    &::after {
                        background: url('{{file_dest}}/chevron--navy.svg') no-repeat center center transparent;
                    }
                }
            }
        }
    }
}
