/* Module: S */
// 1. FAQ
.faq {
    padding: pxToRem(40) 0;
}

.faq__header {
    color:     $corporate-services;
    font-size: pxToRem(20);
    margin:    0;
}

.faq__container--content {
    border-bottom:  pxToRem(.5) solid rgba(32, 21, 71, .21);
    padding-bottom: pxToRem(20);
    padding-top: pxToRem(10);

    &:last-child {

        .faq__content {
            border-bottom: 0;
        }
    }
}

.faq__content--header {
    color:       $corporate-services;
    font-size:   pxToRem(14);
    font-weight: 500;
    line-height: 1.36;
    margin: 	 pxToRem(10) 0;
}

.faq__content {
    color:          $grey;
    font-size:      pxToRem(14);
    height:         100%;
    letter-spacing: pxToRem(-.3);
    line-height:    1.36;

    p,
    ul,
    ol {

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}
