/* Module: Accordion */
// 1. ACCORDION
.accordion {
    padding:  pxToRem(20) 0;

    &__header {
        color:       $corporate-services;
        font-size:   pxToRem(20);
        font-weight: 600;
    }

    .accordion__item {
        margin-bottom: pxToRem(2);
    }

    .accordion__item-header {
        background-color: rgba(188, 184, 199, 0.16);
    }

    .accordion__link {
        color:           $corporate-services;
        display:         block;
        font-size:       pxToRem(14);
        font-weight:     500;
        padding:         pxToRem(17) pxToRem(50) pxToRem(17) pxToRem(20);
        position:        relative;
        text-decoration: none;

        &:hover,
        &:focus {
            outline:          none;
            background-color: rgba(92, 92, 92, 0.15);
        }

        &::after {
            @include sq-transform(translateY(-50%));
            @include sq-transition(transform .35s ease);
            background: url('{{file_dest}}/chevron-teal.svg') no-repeat center center transparent;
            content:    '';
            height:     pxToRem(16);
            position:   absolute;
            right:      pxToRem(25);
            top:        50%;
            width:      pxToRem(16);
        }

        &.uber-accordion__button-active {
            background-color: $teal;
            color:            $white;

            &:hover,
            &:focus {
                opacity: 0.8;
            }

            &::after {
                @include sq-transform(translateY(-50%) rotate(-180deg));
                background: url('{{file_dest}}/chevron.svg') no-repeat center center transparent;
            }
        }
    }

    .accordion__target {
        @include sq-transition(all .15s ease);
        color:          $grey;
        font-size:      pxToRem(14);
        letter-spacing: pxToRem(-.3);
        max-height:     0;
        opacity:        0;
        padding:        0 pxToRem(20);
        visibility:     hidden;

        p,
        ul,
        ol {

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.uber-accordion__target-active {
            max-height: inherit;
            opacity:    1;
            padding:    pxToRem(20);
            visibility: visible;

            img {
                display: block;
            }
        }

        img {
            display: none;
        }
    }

    .btn {
        @include sq-transition(all .35s ease);
        background:      $corporate-services;
        border:          none;
        color:           $white;
        display:         inline-block;
        text-align:      left;
        text-decoration: none;

        &:hover,
        &:focus {
            background: rgba($corporate-services, 0.9);
        }
    }
}

.no-js {
    .accordion__target {
        display:    block;
        max-height: pxToRem(999);
        opacity:    1;
        padding:    pxToRem(20);
        visibility: visible;
    }
}

// 2. TABS
.tabs {
    padding:  pxToRem(40) 0;

    .tabs__tab-list-wrapper {
        position: relative;

        &::before {
            background: $corporate-services;
            bottom:     0;
            content:    '';
            height:     pxToRem(2);
            left:       0;
            opacity:    .1;
            position:   absolute;
            right:      0;
        }
    }

    .tabs__tab-list {
        @include sq-list-reset();
    }

    .tabs__link {
        color:           $corporate-services;
        display:         block;
        font-size:       pxToRem(14);
        margin:          0 pxToRem(20) 0 0;
        padding:         pxToRem(10) 0;
        position:        relative;
        text-decoration: none;

        &::after {
            @include sq-transition(all .35s ease);
            background: $corporate-services;
            bottom:     0;
            content:    '';
            display:    block;
            height:     pxToRem(2);
            left:       0;
            opacity:    0;
            position:   absolute;
            width:      100%;
        }

        &:hover,
        &:focus {

            outline: none;
            &::after {
                opacity: .3;
            }
        }

        &.uber-accordion__button-active {

            &::after {
                background: $teal;
                opacity:    1;
            }
        }
    }

    .tabs__target {
        padding-top: pxToRem(20);

        h2 {
            color:       $corporate-services;
            font-size:   pxToRem(20);
            font-weight: 600;
            margin:      pxToRem(10) 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            color:     $grey;
            font-size: pxToRem(14);

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .slick-arrow {
        @include sq-transition(all .35s ease);
        background:  linear-gradient(to right, rgba($white, .0), $white);
        border:      0;
        display:     block;
        height:      100%;
        margin:      0;
        outline:     transparent dotted thin;
        overflow:    hidden;
        padding:     0;
        position:    absolute;
        text-indent: 100%;
        top:         0;
        white-space: nowrap;
        width:       pxToRem(39);
        z-index:     5;

        &:focus {
            outline: none;

            &:active {
                outline: transparent dotted thin;
            }
        }

        &:active {
            outline: transparent dotted thin;
        }
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        @include sq-transform(rotate(180deg));
        left: 0;
    }

    .slick-list {
        padding: 0 pxToRem(16);
    }
}

.js-enabled .tabs__target {
    display: none;

    &.uber-accordion__target-active {
        display: block;
    }
}

// Theme: Forest Fire Management
.forest-fire-management {

    .accordion .accordion__link,
    .tabs .tabs__link {
        color: $forest-fire-mgmt-neutral;
    }

    .accordion .accordion__link.uber-accordion__button-active,
    .tabs .tabs__link.uber-accordion__button-active::after {
        background-color: $forest-fire-mgmt;
    }

    .accordion {

        .accordion__link.uber-accordion__button-active {
            color: $white;
        }

        .accordion__link::after {
            background: url('{{file_dest}}/chevron--jungle-green.svg') no-repeat center center transparent;
        }

        .uber-accordion__button-active {

            &::after {
                background: url('{{file_dest}}/chevron.svg') no-repeat center center transparent;
            }
        }

    }

    .tabs {

        .tabs__link:hover,
        .tabs__link:focus {
            color: mix($black, $white, 56%);
        }

        .tabs__target h2 {
            color: $forest-fire-mgmt-neutral;
        }

    }

}

// Theme: Fishermans Bend
.fishermans-bend {

    .accordion .accordion__link,
    .tabs .tabs__link {
        color: $corporate-services;
    }

    .accordion .accordion__link.uber-accordion__button-active,
    .tabs .tabs__link.uber-accordion__button-active::after {
        background-color: $fishermans-bend;
    }

    .accordion {
        .uber-accordion__button-active::after {
            background: url('{{file_dest}}/chevron--navy.svg') no-repeat center center transparent;
        }
    }

    .tabs {

        .tabs__link {
            
            &:hover,
            &:focus {
                color: mix($black, $white, 56%);
            }
        }
    }
}
