/* Module: Matrix form */
form {

    .sq-form-field {
        width: 40%;
    }
}

.selectric-wrapper {
    margin-left:  pxToRem(-14);
    margin-right: pxToRem(-14);
    padding:      pxToRem(10) pxToRem(14);
    width:        calc(40% + 28px);
}
