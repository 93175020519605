/* Module: S */
.main-content-wrapper,
.search__wrapper {
    .bg--grey {
        background: #fafafa;
        position:   relative;

        &::before {
            background: #fafafa;
            content:    '';
            height:     100%;
            left:       -100vw;
            position:   absolute;
            width:      200vw;
        }
    }

    button.btn__large, button.btn__small {
        border: none;
    }

    p.btn {
        &__large {
            padding: 0;

            a {
                display: block;
                padding: pxToRem(37) pxToRem(30) pxToRem(36);
            }
        }

        &__small {
             padding: 0;

            a {
                display: block;
                padding: pxToRem(14) pxToRem(20);
            }
        }
    }

    .btn {
        a {
            color:           $white;
            text-decoration: none;

            &:hover,
            &:focus {
                outline: none;
            }
        }
        @include sq-transition(all .35s ease);
        background:      $corporate-services;
        border:          none;
        color:           $white;
        display:         inline-block;
        position:        relative;
        text-align:      left;
        text-decoration: none;

        &::after {
            content:    '';
            display:    inline-block;
            position:   absolute;
        }

        &:hover,
        &:focus {
            background: rgba($corporate-services, 0.9);
            outline:    none;
        }

        &__large {

            font-size:       pxToRem(20);
            font-weight:     600;
            height:          pxToRem(100);
            margin:          pxToRem(33) 0;
            padding:         pxToRem(37) pxToRem(30) pxToRem(36);
            width:           100%;

            &::after {
                background: url('{{file_dest}}/arrow--white.svg') no-repeat center center transparent;
                height:     pxToRem(16.6);
                right:      pxToRem(30);
                top:        pxToRem(45);
                width:      pxToRem(35.2);
            }
        }

        &__small {
            font-size:       pxToRem(16);
            height:          pxToRem(50);
            margin:          pxToRem(17.5) pxToRem(27) pxToRem(17.5) 0;
            padding:         pxToRem(14) pxToRem(20);
            width:           pxToRem(232);

            &::after {
                background: url('{{file_dest}}/arrow--white-sort.svg') no-repeat center center transparent;
                height:     pxToRem(16.6);
                right:      pxToRem(20);
                top:        pxToRem(16);
                width:      pxToRem(27.4);
            }
        }

        &--white {
            a {
                color: $corporate-services;
            }

            color:      $corporate-services;
            background: $white;

            &::after {
                background: url('{{file_dest}}/arrow--corporate.svg') no-repeat center center transparent;
            }

            &:hover,
            &:focus {
                background: $dark-grey
            }
        }

        &--teal {
            background: $teal;

            &:hover,
            &:focus {
                background: $teal-light;
            }
        }

        &--purple {
            background: $purple;

            &:hover,
            &:focus {
                background: $purple-light;
            }
        }

        &__link {
            @include sq-transition(all .35s ease);
            color:           $corporate-services;
            font-size:       pxToRem(16);
            font-weight:     600;
            position:        relative;
            text-decoration: none;

            &::after {
                @include sq-transition(background .35s ease);
                background:  url('{{file_dest}}/arrow--cor-sort.svg') no-repeat center center transparent;
                content:     '';
                display:     inline-block;
                height:      pxToRem(16.6);
                margin-left: pxToRem(15);
                position:    absolute;
                top:         pxToRem(3);
                width:       pxToRem(27.4);
            }

            &:hover,
            &:focus {
                color:   $teal;
                outline: none;

                &::after {
                    background:  url('{{file_dest}}/arrow--teal-sort.svg') no-repeat center center transparent;
                }
            }

            a {
                text-decoration: none;
                &:hover,
                &:focus {
                    color:   $teal;
                    outline: none;
                }
            }

            &--no_arrow {
                &:after {
                    content: none;
                }
            }
        }

        // "Load more" button for infinite scrolling
        &__load {
            margin-top: 0;
            text-align: center;
            width:      auto;

            &::after {
                background-image: none;
            }

        }

        &--print {
            font-size:    .875rem;
            height:       auto;
            margin-right: 0;
            padding:      .625rem 1rem;
            width:        auto;

            &::after {
                background-color: transparent;
                background-image: none;
                height:           auto;
                width:            auto;
            }

        }

    }
}

.forest-fire-management .main-content-wrapper,
.forest-fire-management .search__wrapper {

    .btn {
        background-color: $forest-fire-mgmt-dark;

        &:hover,
        &:focus {
            background-color: $forest-fire-mgmt;
        }
    }

    // Colour mapping:
    // Default = dark blue-green
    // White = white
    // Teal = white
    // Purple = sun yellow

    // Generic: dark foreground, light background
    .btn--teal,
    .btn--purple {

        &::after {
            background-image: url('{{file_dest}}/arrow--smoke-black-sort.svg');
        }

    }

    .btn--white,
    .btn--purple,
    .btn--teal,
    .btn__link {

        &,
        a {
            color: $forest-fire-mgmt-neutral;
        }

    }

    .btn--teal,
    .btn--teal a,
    .btn__link,
    .btn__link a {

        &:hover,
        &:focus {

            &::after {
                background-image: url('{{file_dest}}/arrow--jungle-green-sort.svg');
            }

        }

    }

    .btn--white {
        background-color: $white;

        &::after {
            background-image: url('{{file_dest}}/arrow--smoke-black.svg');
        }

        &:hover,
        &:focus {
            background-color: mix($white, $black, 92%);
        }

    }

    .btn--teal {

        &,
        &:hover,
        &:focus {
            background-color: $white;
        }

        &:hover,
        &:focus,
        a:hover,
        a:focus {
            color: $forest-fire-mgmt;
        }

    }

    .btn--purple {
        background-color: $forest-fire-mgmt-highlight;

        &:hover,
        &:focus {
            background-color: adjust-color($forest-fire-mgmt-highlight, $saturation: 6%, $lightness: 21%);
        }

    }

    .btn__link {

        &:hover,
        &:focus,
        a:hover,
        a:focus {
            color: $forest-fire-mgmt;
        }

    }

}
// Theme: Fishermans Bend
.fishermans-bend .main-content-wrapper {
    .btn {
        background-color: $fishermans-bend;
        color:            $corporate-services;

        &:hover,
        &:focus {
            background-color: $fishermans-bend-dark;
            color:            $white;

            &::after {
                background-image: url('{{file_dest}}/arrow--white.svg');
            }
        }

        &::after {
            background-image: url('{{file_dest}}/arrow--corporate.svg');
        }
    }

    .btn__small {
        &:hover,
        &:focus {  

            &::after {
                background-image: url('{{file_dest}}/arrow--white-sort.svg');
            }
        }

        &::after {
            background-image: url('{{file_dest}}/arrow--cor-sort.svg');
        }
    }
    
    .btn__small.btn--white,
    .btn__small.btn--teal {
        &:hover,
        &:focus {
            &::after {
                background-image: url('{{file_dest}}/arrow--cor-sort.svg');
            }
        }
    }
    .btn--white,
    .btn--teal {
        background-color: $white;

        &:hover,
        &:focus {
            background-color: mix($white, $black, 92%);
            color:            $corporate-services;

            &::after {
                background-image: url('{{file_dest}}/arrow--corporate.svg');
            }
        }

        a {
            color: $corporate-services;
        }
    }

    .btn--purple {
        background: $corporate-services;
        color:      $white;

        &:hover,
        &:focus {
            background: rgba($corporate-services, 0.9);
        }

         &::after {
            background-image: url('{{file_dest}}/arrow--white-sort.svg');
        }
    }
}
