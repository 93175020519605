// VIC Extra Light
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/{{file_dest}}/hinted-VIC-ExtraLight.eot');
    src: url('{{file_dest}}/{{file_dest}}/hinted-VIC-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/{{file_dest}}/hinted-VIC-ExtraLight.woff2') format('woff2'),
        url('{{file_dest}}/{{file_dest}}/hinted-VIC-ExtraLight.woff') format('woff'),
        url('{{file_dest}}/{{file_dest}}/hinted-VIC-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

// VIC Extra Light Italic
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-ExtraLightItalic.eot');
    src: url('{{file_dest}}/hinted-VIC-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-ExtraLightItalic.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-ExtraLightItalic.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

// VIC Light
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-Light.eot');
    src: url('{{file_dest}}/hinted-VIC-Light.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-Light.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-Light.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

// VIC Light Italic
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-LightItalic.eot');
    src: url('{{file_dest}}/hinted-VIC-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-LightItalic.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-LightItalic.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

// VIC Normal
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-Regular.eot');
    src: url('{{file_dest}}/hinted-VIC-Regular.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-Regular.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-Regular.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// VIC Normal Italic
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-Italic.eot');
    src: url('{{file_dest}}/hinted-VIC-Italic.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-Italic.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-Italic.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

// VIC Medium
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-Medium.eot');
    src: url('{{file_dest}}/hinted-VIC-Medium.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-Medium.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-Medium.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

// VIC Medium Italic
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-MediumItalic.eot');
    src: url('{{file_dest}}/hinted-VIC-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-MediumItalic.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-MediumItalic.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

// VIC SemiBold
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-SemiBold.eot');
    src: url('{{file_dest}}/hinted-VIC-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-SemiBold.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-SemiBold.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

// VIC SemiBold Italic
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-SemiBoldItalic.eot');
    src: url('{{file_dest}}/hinted-VIC-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-SemiBoldItalic.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-SemiBoldItalic.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

// VIC Bold
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-Bold.eot');
    src: url('{{file_dest}}/hinted-VIC-Bold.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-Bold.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-Bold.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

// VIC Bold Italic
@font-face {
    font-family: 'VIC';
    src: url('{{file_dest}}/hinted-VIC-BoldItalic.eot');
    src: url('{{file_dest}}/hinted-VIC-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('{{file_dest}}/hinted-VIC-BoldItalic.woff2') format('woff2'),
        url('{{file_dest}}/hinted-VIC-BoldItalic.woff') format('woff'),
        url('{{file_dest}}/hinted-VIC-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

// Font stacks
$sans-serif: 'VIC', Arial, sans-serif;

body {
  font-family: $sans-serif;
  font-weight: normal;
  font-style: normal;
}
