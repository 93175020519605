/* Module: S */
.main .search-header {
    z-index: 33;
}
//Breadcrumbs
.search-header .breadcrumb {
    line-height: pxToRem(19);
    margin: 0;
    padding: pxToRem(27) 0 0 0;
    width: 72%;
    margin-bottom: pxToRem(20);
}

.search-header .breadcrumb__item::after {
    font-size: pxToRem(14);
    letter-spacing: normal;
}

.search-header .breadcrumb a {
    font-size: pxToRem(14);
    letter-spacing: normal;
}

.search-header {

    .search-header__title-wrap {
        padding:   pxToRem(20) 0 pxToRem(36) 0;

        & > h1 {
            color:         $white;
            font-size:     pxToRem(50);
            font-weight:   600;
            line-height:   pxToRem(70);
            margin-top:    0;
            margin-bottom: pxToRem(7);
        }

        .search-header__desp-wrap {
            & > p,
            &-link,
            a {
                font-size:   pxToRem(20);
                line-height: pxToRem(28);
            }
        }
    }
    &__sec-desp {
        position: absolute;
        left: 0;
        bottom: -30px;
        font-size: 20px;
    }
    // amendments search-header template
    .amendments & .breadcrumb {
        margin-bottom: 20px;
    }
    .amendments &__desp-wrap {
        font-size: 20px;
    }
    .amendments &__content-wrap {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        position: relative;
        h1 {
            font-size:     pxToRem(50);
            line-height:   pxToRem(70);
            margin-bottom: pxToRem(7);
            position: relative;
            margin-right: 20px;
            max-width: pxToRem(500);
            border-right: 1px solid rgba($white, 0.3);
            padding-right: pxToRem(40);
        }
    }
    .amendments &__change-plnning-btn {
        border: none;
        color: #fff;
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        text-align: left;
        position: relative;
        left: pxToRem(20);
        padding: pxToRem(10);
        padding-left: pxToRem(30);

    }
    .amendments &__change-plnning-icon {
        &:before {
            content: "";
            display: block;
            background: url(mysource_files/chevron.svg) no-repeat center center;
            width: 20px;
            height: 13px;
            position: absolute;
            left: 0;
            background-size: cover;
            transition: .3s;
        }
    }
    .amendments &__change-plnning-icon {
        &.open {
            &:before {
                transition: .3s;
                -webkit-transform: rotate(-180deg);
                -moz-transform: rotate(-180deg);
                -ms-transform: rotate(-180deg);
                -o-transform: rotate(-180deg);
            }
        }
    }

    .amendments &__select-planning-btn {
        display: block;
        position: absolute;
        right: 0;
        font-size: 12px;
        font-weight: 600;
        color: white;
        text-decoration: none;
        &:before {
            content: "";
            display: block;
            background: url(mysource_files/map-pin.svg) no-repeat center center;
            width: 20px;
            height: 23px;
            position: absolute;
            left: -30px;
            top: -3px;
            background-size: cover;
        }
    }
}

.search__form-filbtn-wrap.search__custom {
    background-color: $corporate-services;

    .search__form-wrap > form .search__group {

        .search__input {
            background-color: transparent;
            color: $wild-sand;
            border-bottom-color: $wild-sand;
        }

        input::placeholder {
            color:       $wild-sand;
        }

        input:focus {
            border-bottom: pxToRem(2) solid $teal;
        }

        input:focus::placeholder {
            color: rgba($wild-sand, 0.5);
        }

        .search__submit {
            &.search__submit--icon {
                background: url('{{file_dest}}/search-icon--white.svg') no-repeat top left transparent;
            }
        }
    }

    .search__filter-btn-wrap {
        .search__clear-btn,
        .search__filter-btn {
            background-color: $haiti2;
            color: $wild-sand;

            & > svg {
                fill: $wild-sand;
            }
        }
    }
}

.search__form-filbtn-wrap {
    z-index:   32;

    .search__form-wrap {
        height:        pxToRem(50);
        & > form {
            width: 40%;

            .search__group {
                .search__input {
                    font-size: pxToRem(20);
                    padding:   pxToRem(5) pxToRem(25) pxToRem(10) pxToRem(5);
                }

                .search__submit {
                    top:   pxToRem(6);
                    right: pxToRem(0);

                    &.search__submit--cancel {
                        top:        pxToRem(13);
                        right:      pxToRem(5);
                    }
                }
            }
        }
    }

    .search__form-wrap--modes {
        height: pxToRem(70);
    }

    .search__filter-btn-wrap {
        right: 0;

        .search__filter-btn {
            display: inline-block;
        }

        .search__clear-btn {
            margin: 0;
            padding: pxToRem(14) pxToRem(25) pxToRem(13) pxToRem(24);
            top: 0;

            .filter-clear-btn__text {
                font-size: pxToRem(16);
                font-weight: 500;
                line-height: inherit;
                min-width: pxToRem(83);
                text-transform: none;
                vertical-align: middle;
            }
        }

        .search__filter-btn {
            width:            auto;
            height:           pxToRem(50);
            padding:          pxToRem(14) pxToRem(25) pxToRem(13) pxToRem(24);
            background-color: $wild-sand;
            color:            $haiti2;

            & > svg,
            .filter-btn__text {
                display: inline-block;
                vertical-align: middle;
            }

            & > svg {
                margin-right: pxToRem(9.2);
                position:     relative;
                top:          pxToRem(-2);
                left:         pxToRem(3);
            }

            .filter-btn__text {
                font-size:   pxToRem(16);
                font-weight: 500;
                line-height: pxToRem(22);
                min-width:   pxToRem(83);
            }

            .filter-btn__number {
                position:    relative;
                background:  none;
                font-size:   pxToRem(16);
                font-weight: 500;
                width:       auto;
                height:      auto;
                padding:     pxToRem(0) pxToRem(8);
                top:         pxToRem(1);
                right:       auto;

                &.has-data {
                    display: inline-block;

                    &:before,
                    &:after {
                        position: absolute;
                        width:    pxToRem(3);
                        height:   100%;
                    }

                    &:before {
                        content: "(";
                        left:    pxToRem(3);
                    }

                    &:after {
                        content: ")";
                        right:   pxToRem(5);
                    }
                }
            }

            &.search__filter-btn--active {
                background-color: $wild-sand;
                color:            $corporate-services;
                & > svg {
                    fill:         $corporate-services;
                }
            }

            &:hover,
            &:focus {
                background-color: rgba($corporate-services, 0.79);
                color:            $white;
                & > svg {
                    fill:         $white;
                }
            }
        }
    }
}

.search-filters {
    &.active {
        background-color:  $wild-sand;
        position:          relative;
        padding:           0;
        -webkit-animation: fadeIn 0.5s ease-out;
        animation:         fadeIn 0.5s ease-out;
        z-index: 10;

        .filter-tabs__wrap {
            position:          relative;
            z-index:           31;
            top:               0;
            bottom:            auto;
            overflow:          inherit;
            -webkit-animation: none;
            animation:         none;
            margin:            0 auto;
            max-width:         77rem;
            width:             auto;
        }

        form {
            position:       relative;
            padding-bottom: 0;
            width: 100vw;
        }
    }

    form {

        .search-filters__header-wrap,
        .search-filters__footer-wrap {
            position:          relative;
            -webkit-animation: none;
            animation:         none;
        }

        .search-filters__header-wrap {
            background-color: $wild-sand;
            padding:          0;

            button,
            h2 {
                display: none !important;
            }

            .filter-tabs__header-wrap {
                display:    block;
                text-align: center;
                font-size:  0;
                .filter-tab__header-wrap {
                    display:  inline-block;
                    padding:  pxToRem(15) pxToRem(17.5);
                    position: relative;

                    &:after {
                        content:          "";
                        display:          block;
                        width:            100%;
                        height:           pxToRem(1);
                        background-color: $silver1;
                        position:         absolute;
                        bottom:           0;
                        left:             0;
                        right:            0;
                    }

                    &.active {
                        cursor: pointer;

                        &:after {
                            height:           pxToRem(3);
                            background-color: $teal;
                            bottom:           pxToRem(-1);
                        }
                    }

                    &:hover,
                    &:focus {
                        cursor: pointer;

                        h3 {
                            color: $teal;
                        }
                    }

                    h3 {
                        margin:      0;
                        font-size:   pxToRem(18);
                        font-weight: normal;
                        line-height: pxToRem(28);
                    }
                }
            }
        }

        .search-filters__filter-tab {
            display: none;
            padding: 0 pxToRem(21) 0 pxToRem(48.5);

            &.active {
                display:           block;
                -webkit-animation: fadeIn 1s;
                animation:         fadeIn 1s;
            }
            .filter-tab__header-wrap {
                display: none;
            }

            .search__fields-wrap {
                display:       flex;
                -ms-flex-wrap: wrap;
                flex-wrap:     wrap;
                padding:       0;

                .filter-field__wrap {
                    &:nth-of-type(4n + 1) .filter-field {
                        margin-left: 0;
                    }

                    .filter-field {
                        margin-left: pxToRem(20);

                        &.filter-field__date-range-pick {
                            .filter-fields__wrap {
                                input {
                                    width: 42%;
                                }

                                span {
                                    margin-right: pxToRem(10);
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        .filter-field {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .search-filters__footer-wrap {
            background-color: $cornflower-blue;
            padding:          pxToRem(16) 0 pxToRem(14) 0;

            .search__group {
                text-align: right;
                & > button,
                & > .reset-search-facets {
                    width:            auto;
                    padding:          pxToRem(14) pxToRem(48);
                    font-size:        pxToRem(16);
                    font-weight:      500;
                    line-height:      pxToRem(22);
                    background-color: $planning;
                }

                button[type=reset],
                .reset-search-facets {
                    display:       inline-block;
                    font-size:     pxToRem(14);
                    color:         $mine-shaft;
                    background:    none;
                    padding-right: pxToRem(34);

                    &:hover,
                    &:focus {
                        color: $planning;
                    }
                }
            }
        }
    }
}

.search-filters__header-wrap,
.search-filters__footer-wrap .search__group {
    max-width: 77rem;
    margin: 0 auto;
}

.search-filters__filter-tab {
    padding: pxToRem(21) 0 pxToRem(48.5) 0;
}


.search-results {
    padding:   pxToRem(36) pxToRem(112) pxToRem(70) pxToRem(112);

    .search-results-bar {
        position: relative;
        .search-results__summary,
        .search-results__func-wrap {
            display: inline-block;
        }

        .search-results__summary {
            margin-top:   0;
            padding-left: 0;
            p {
                font-size:  pxToRem(20);
            }
        }

        .search-results__func-wrap {
            padding:  0;
            position: absolute;
            right:    0;
            top:      pxToRem(-1);

            .search-results__sort,
            .search-results__view-switch {
                display: inline-block;
            }

            .search-results__sort {
                width:        auto;
                text-align:   left;
                padding:      0;
                line-height:  normal;
                border:       none;
                position:     relative;
                right:        pxToRem(-16);
                margin-top:   0;
                margin-right: pxToRem(-10);
                &.expanded {
                    .sort-options__list {
                        width:      pxToRem(180);
                        top:        pxToRem(-4);
                        right:      pxToRem(-1);
                        left:       auto;
                    }
                }
            }

            .search-results__view-switch {
                position: relative;

                button {
                    padding: pxToRem(6) pxToRem(19) pxToRem(6) pxToRem(22);
                    & > svg {
                        margin-right: 0;
                    }

                    &.view-switch__cards {
                        padding: pxToRem(6) pxToRem(40) pxToRem(4) pxToRem(22);
                        right:   pxToRem(-32);
                        &.selected {
                            padding-right: pxToRem(19);
                            right:         pxToRem(-29);
                        }
                    }

                    &.view-switch__table {
                        padding: pxToRem(6) pxToRem(19) pxToRem(6) pxToRem(40);
                        &.selected {
                            padding-left:     pxToRem(22);
                        }
                    }
                }
            }
        }
    }
}

.table-view__header {

    .result-row__cell.sortable::after {
        right: 4px;
    }

}

// amendments search-tabs template
.amendments.search-tabs {
    .container-fluid {
        max-width: 77rem;
        margin: 0 auto;
    }
}

.amendments {
    .search-tabs {
        &__selected-tab {
            display: none;
        }
        &-wrap {
            display: flex;
            margin: 0;
            &.mobile-hidden {
                display: flex;
                margin: 0;
            }
        }
        &__item {
            margin-right: 70px;
            a {
                padding: 22px 0;
            }
            &.active {
                border-bottom: 4px solid #00b7bd;
            }
        }
    }
}

.search-filters form .search-filters__filter-tab,
.search__form-filbtn-wrap {
    padding: 20px 0 20px 0;
}
