/* Module: S */
.grant__items-list {
    position: relative;
    z-index:  10;
}

.grant__item {
    border-bottom: pxToRem(1) solid lighten($grey, 29%);
    display:       flex;
    padding:       pxToRem(40) 0;

    .grant__img-wrapper {
        flex: 1;
        min-width: pxToRem(290);

        + .grant__data-wrapper {
            padding-left: pxToRem(32);
        }
    }

    .grant__data-wrapper {
        border:    0;
        flex-grow: 1;
        padding:   0 pxToRem(32) 0 0;
    }

    .grant__terms-wrapper {
        
    }

    .grant__cta-wrapper {
        align-self: center;
        border:     0;
        min-width:  pxToRem(126);
        padding:    0;

        .grant__cta {
            padding: pxToRem(10) 0 pxToRem(11);
        }
    }
}
