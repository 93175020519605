// Use the same CSS naming conventions for your variables, this will make it easier to manage
// them in a modular way and make sure that you aren't creating something that conflicts.
// We use !default so you can override these variables by including them higher up in the flow
// of the sass pre-processor (e.g the core variables.scss file).
// Examples:
// $media-tiles__background: #000 !default;
// $media-tiles__text: #fff !default;
$section-grey: #F2F2F2;
$border-grey: #E3E3E3;
$dark-grey: #A8A4B6;
// $trending-news-panel__background: #000 !default;
// $trending-news-panel__text: #fff !default;


