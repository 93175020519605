/* Module: S */
.header-banner.campaign {
    height:   pxToRem(340);
    overflow: hidden;

    &::before {
        content: none;
    }

    .banner {

        &__image-container {
            height: 100%;
            width:  100%;
        }

        &__flood {
            flood-color: $teal;
        }

        // Theme: Planning
        &__flood--planning {
            flood-color: $planning;
        }

        &__image-overlay--mobile {
            filter: url('#banner-multiply-mobile');
        }

        &__text-wrapper {
            padding:   pxToRem(30);
            position:  absolute;
            top:       50%;
            transform: translateY(-50%);
            z-index:   1;
        }

        &__heading,
        &__subheading {
            color:    $white;
            position: relative;
            z-index:  1;
        }

        &__heading {
            font-size: pxToRem(32);
        }

    }

    .mobile-hide {
        display: none;
    }

}
