/* Module: S */
.block-tile {
    padding-bottom: pxToRem(90);
    padding-top:    pxToRem(41);

    &.padding_top--half {
        padding-top: pxToRem(20);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(45);
    }
    &--header {
        text-align: center;
        h2 {
            font-size: pxToRem(40);
            margin-bottom: pxToRem(27);
        }
    }

    &__item {
        margin-bottom: 0;

        &-content {

            &--header h3,
            &--header strong {
                font-size: pxToRem(24);
            }
        }
    }
}
.block-tile--small {
    padding-bottom: 0.625rem;
}
