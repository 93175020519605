/* Module: S */
// 1. FEATURE PANEL
.feature-panel {
    background: $white;
    color:      $corporate-services;
    overflow:   hidden;
    padding:    pxToRem(50) 0;
    position:   relative;

    &.padding_top--half {
        padding-top: pxToRem(25);
    }

    &.padding_bottom--half {
        padding-bottom: pxToRem(25);
    }
     &.padding_top--no {
        padding-top: 0;
    }
    &.padding_bottom--no {
        padding-bottom: 0;
    }

    &::after,
    &::before {
        content:  '';
        position: absolute;
    }

    &::after {
        border-color: transparent transparent $teal;
        border-style: solid;
        border-width: 0 pxToRem(34) pxToRem(73);
        bottom:       0;
        left:         pxToRem(-34);
    }

    &::before {
        border-color: rgba($corporate-services, .3) transparent transparent;
        border-style: solid;
        border-width: pxToRem(73) pxToRem(34) 0;
        right:        pxToRem(-34);
        top:          0;
    }

    .container-fluid {
        max-width: pxToRem(1232);
    }

    .feature-panel__heading {
        display:     block;
        font-size:   pxToRem(24);
        font-weight: 600;
        margin:      0;
        text-align:  center;
    }

    .feature-panel__descr {
        color:      $grey;
        font-size:  pxToRem(14);
        margin:     pxToRem(10) 0 0;
        text-align: center;
    }

    .feature-panel__block {
        @include sq-transition(background .35s ease);
        background:      $white;
        color:           inherit;
        display:         block;
        height:          100%;
        padding:         pxToRem(24);
        text-align:      center;
        text-decoration: none;

        &:hover,
        &:focus {
            outline: none;
            background: lighten($black, 95%);
        }
    }

    .feature-block__image {
        display: block;
        height:  pxToRem(40);
        margin:  0 auto;
        width:   pxToRem(40);
    }

    .feature-block__heading {
        font-size:   pxToRem(18);
        font-weight: 600;
        margin:      pxToRem(22) 0 0;
    }

    .feature-block__descr {
        color:     $grey;
        font-size: pxToRem(14);
        margin:    pxToRem(11) 0 0;

        &::after {
            background:      url('{{file_dest}}/arrow.svg') no-repeat center center transparent;
            background-size: cover;
            content:         '';
            display:         block;
            height:          pxToRem(17);
            margin:          pxToRem(20) auto 0;
            width:           pxToRem(35);
        }
    }
}
.feature-panel.reduced,
.feature-panel.minimal {
    &::before,
    &::after {
        content: none;
    }
}
