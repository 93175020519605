/* Module: S */
// 1. TABLE
table {
    margin: pxToRem(16) 0;

    th {
        background:     $corporate-services;
        border:         pxToRem(2) solid $white;
        color:          $white;
        font-size:      pxToRem(14);
        font-weight:    400;
        line-height:    2.1;
        padding:        pxToRem(13) pxToRem(20);
        text-align:     left;
        text-transform: uppercase;

        p {
            color: $white !important;
        }
    }

    td {
        background:  darken($white, 4.5%);
        border:      pxToRem(2) solid $white;
        color:       lighten($black, 43%);
        font-size:   pxToRem(14);
        padding:     pxToRem(15);
    }
}

.table-responsive {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style:         -ms-autohiding-scrollbar;
    display:                    inline-block;
    margin:                     pxToRem(16) 0;
    overflow-x:                 auto;
    overflow-y:                 hidden;
    width:                      100%;

    table {
        margin: 0
    }
}

// Theme: Forest Fire Management
.forest-fire-management table {

    th {
        background-color: $forest-fire-mgmt;
    }

}

// Theme: Fishermans Bend
.fishermans-bend {
      th {
        background-color: $fishermans-bend;
        color:            $corporate-services;

        p {
            color: $corporate-services!important;
        }
    }
}
