/* Module: S */
// 1. HEADER BANNER
.header-banner {

    .banner__text-wrapper {
        margin-left: pxToRem(120);
    }

    .banner__image-wrapper {

        &::after {
            left: pxToRem(86);
        }

        &::before {
            right: pxToRem(-619);
        }
    }

    .banner__image-triangle {
        left: pxToRem(-736);
    }
}
