/* Module: S */
// 1. TITLE BANNER
.title-banner {

    .title-banner__heading-wrapper {
        padding: pxToRem(32) 0 pxToRem(75);
    }

    .title-banner__heading {
        font-size:   pxToRem(50);
        font-weight: 500;
        line-height: pxToRem(70);
    }

    .title-banner__descr {
        font-size: pxToRem(20);
    }

    .title-banner__backlink {
        margin: pxToRem(25) 0 0;

        &::before {
            @include sq-transform(rotate(180deg) translate(0, pxToRem(-2)));
            background:      url('{{file_dest}}/arrow.svg') no-repeat center center;
            background-size: cover;
            height:          pxToRem(14);
            width:           pxToRem(27);
        }
    }
}

// 2. TITLE BANNER W/ TOPIC
.title-banner--topic {

    .title-banner__topic-wrapper {
        display: block;
    }

    .title-banner__navigation {
        display: block;
    }

    .title-banner__backlink-wrapper {
        background-color: rgba($corporate-services, .7);
        color:            $white;
        display:          block;
    }

    .title-banner__heading-wrapper {
        padding: pxToRem(78) 0 pxToRem(75);
    }

    .title-banner__backlink {
        margin: pxToRem(25) 0;

        &::before {
            background:      url('{{file_dest}}/arrow--white.svg') no-repeat center center;
            background-size: cover;
        }
    }
}
// Theme: Forest Fire Management
.forest-fire-management .title-banner {
    
    &--topic .title-banner__backlink-wrapper,
    &__topic-wrapper {
        background-color: $forest-fire-mgmt;
        color: $white;
    }
}
// Theme: Fishermans Bend
.fishermans-bend .title-banner {

    &--topic .title-banner__backlink-wrapper,
    &__topic-wrapper {
        background-color: mix($fishermans-bend, $white, 53%);
        color:            $corporate-services;
    }

    &__backlink::before {
         background:  url('{{file_dest}}/arrow.svg') no-repeat center center;
         background-size: cover;
    }
}
