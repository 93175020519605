/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
html {
  @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
  background: $content-selection-highlight;
  text-shadow: none;
}

::selection {
  background: $content-selection-highlight;
  text-shadow: none;
}

html,
body {
  height: 100%;
}
body {
  font-size: $base-font-scale + em;
  line-height: 1.4;
  margin: 0;
  -ms-overflow-style: scrollbar;
  overflow-y: scroll;
  padding: 0;
  -webkit-font-smoothing: antialiased;

  &::after {
    content: "global";
    display: none;
  }

  &.drawer-opened {
    overflow: hidden;
  }
}

img {
  height:    auto;
  max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.main-content-wrapper {
  p,
  ul,
  ol {
    color: $grey;
    font-size: pxToRem(14);
    letter-spacing: pxToRem(-0.3);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $corporate-services;
    word-wrap: break-word;
    // font-weight: 600;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.overlay {
  background: $black;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -2;

  &.overlay--active {
    opacity: 0.6;
    z-index: 10;
  }

  &.overlay--active-drawer {
    opacity: 0.6;
    z-index: 50;
  }
}

.slideout-panel {
  background: $white;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
  width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
  margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
//.content {
@import "content.scss";
//}

/*
--------------------
Modules
--------------------
*/
