/* Module: S */
.block-tile {
    padding-bottom: pxToRem(50);

    &.padding_bottom--half {
        padding-bottom: pxToRem(25);
    }
    &--header {
        text-align: center;
    }

    &__item {
        display:         flex;
        flex-direction:  column;
        justify-content: space-between;

        &--wrap {
            display:        flex;
            padding-bottom: pxToRem(8);
            padding-top:    pxToRem(8);
        }

        &-content {
            display:         flex;
            flex-direction:  column;
            flex-grow:       1;
            flex-shrink:     0;
            flex-wrap:       nowrap;
            justify-content: space-between;

            &-wrapper {
                flex-grow:   1;
                flex-shrink: 0;
            }
        }
    }
}

.search__result--tiles {

    .block-tile {

        &__item {
            max-width: 100%; // Prevent tiles from hijacking width of both tile and page
        }

    }

}
.block-tile--small {
    padding-bottom: 0.625rem;
}
// IE11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .block-tile__item--wrap {
        display: block;
    }

    .block-tile__item {
        height: 100%;
    }
}
