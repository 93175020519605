/* Module: S */
.header-banner.campaign {

    .banner {

        &__image-overlay--medium {
            filter: url('#banner-multiply-medium');
        }

        &__text-wrapper {
            width: 50%;
        }

    }

    .tablet-hide {
        display: none;
    }

    .tablet-show {
        display: block;
    }

}
