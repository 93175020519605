/* Module: S */
.block-nav {
    padding: pxToRem(50) 0 0;
     &.padding_top--half {
        padding-top: pxToRem(25);
    }
    &.padding_bottom--half {
        padding-bottom: 0;
    }
    
    .block-nav__list-wrapper {
        margin: 0;
    }

    .slick-arrow {
        width:      pxToRem(58);

        &::after {
            background:      url('{{file_dest}}/chevron--teal.svg') no-repeat center center;
            background-size: cover;
            content:         '';
            height:          pxToRem(6);
            position:        absolute;
            top:             50%;
            width:           pxToRem(12);
        }
    }

    .slick-next {

        &::after {
            @include sq-transform(rotate(270deg) translateX(25%));
            right: 0;
        }
    }

    .slick-prev {

        &::after {
            @include sq-transform(rotate(270deg) translateX(25%));
            right: 0;
        }
    }

    .block-nav__link {
        font-size: pxToRem(16);
        margin:    0 pxToRem(65) 0 0;
        padding:   pxToRem(18) 0;
    }

    .uber-accordion__target-active {
        display: flex;
        padding: pxToRem(70) 0;
    }

    .block-nav__content--left {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width:  33.33333333%;
        padding:    0;
    }

    .block-nav__content--right {
        -ms-flex-preferred-size: 50%;
        flex-basis:  50%;
        margin-left: 16.66666667%;
        max-width:   50%;
    }

    .block-nav__content-header h3 {
        font-size:  pxToRem(24);
        margin-top: 0;
    }

    .block-nav__content-description p {
        font-size:   pxToRem(16);
        line-height: pxToRem(24);
    }

    .block-nav__list-children {
        -webkit-column-count: 2;
        -moz-column-count:    2;
        -webkit-column-gap:   0;
        -moz-column-gap:      0;
        column-count: 2;
        column-gap:   0;
    }

    .block-nav__item-sub {
        &::after {
            margin-right: pxToRem(10);
        }
    }

    .block-nav__item-sub--link {
        line-height: 3;
        padding:     0;
    }
}

// Theme: Forest Fire Management
.forest-fire-management .block-nav {

    .slick-arrow::after {
        background-image: url('{{file_dest}}/chevron--jungle-green.svg');
    }

}
